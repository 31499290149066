import { PhoneNumberType } from '../../app/features/phone-number/thunks/phone-number.thunk'
import { appConstants } from '../../configs/constants.config'
import { UserResponse } from '../../types/user.type'

export function set(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value)
  } catch {
    console.warn('Error reading from local storage')
  }
}

export function get(key: string) {
  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

export function storePhoneCodes(phoneCodes: PhoneNumberType[]) {
  set(appConstants.storageKeys.phoneCodes, JSON.stringify(phoneCodes))
}

export function getStoredPhoneCodes() {
  return JSON.parse(get(appConstants.storageKeys.phoneCodes) as string)
}

export function setUserInfo(info: UserResponse) {
  set(appConstants.storageKeys.currentUser, JSON.stringify(info))
}

export function getUserInfo(): UserResponse | null {
  const info = get(appConstants.storageKeys.currentUser)
  if (info != null) {
    try {
      return JSON.parse(info)
    } catch (ignored) {
      /* empty */
    }
  }
  return null
}

export function clearUserInfo() {
  localStorage.removeItem(appConstants.storageKeys.currentUser)
}

export function storeAppLang(lang: string) {
  set(appConstants.storageKeys.appLang, lang)
}

export function getAppLang() {
  const appLang = get(appConstants.storageKeys.appLang)
  return {
    appLang: appLang,
  }
}
