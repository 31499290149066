import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './searchablefield.module.css'

import UseOnClickOutside from '../../../lib/hooks/detect-clickoutside.hook'
import InputMolecule from '../input/Input.molecule'

type SearchableFieldProps = {
  value: string
  name: string
  placeholder: string
  label: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  iconLeft?: JSX.Element
  list: string[]
  onSelect: (name: string) => void
}

const SearchableFieldMolecule: React.FC<SearchableFieldProps> = ({
  value,
  name,
  label,
  placeholder,
  onChange,
  iconLeft,
  list,
  onSelect,
}) => {
  const [inputActive, setInputActive] = useState(false)
  const [filteredList, setFilteredList] = useState(list)

  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    setInputActive(() => {
      return false
    })
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  useEffect(() => {
    const filteredItems = list.filter(
      (item) => item?.toLocaleLowerCase?.().includes(value?.toLocaleLowerCase?.()),
    )

    if (filteredItems?.length > 0) {
      setFilteredList(filteredItems)
    } else {
      setFilteredList(list)
    }
  }, [value])

  return (
    <div
      onFocus={() => {
        setInputActive(true)
      }}
      ref={ref}
      className={`${globals['full-width']} ${styles.searchable}`}
    >
      <InputMolecule
        iconLeft={iconLeft}
        label={label}
        placeholder={placeholder}
        name={name}
        type='text'
        value={value}
        onChange={onChange}
      />
      {filteredList && filteredList?.length > 0 && value?.length > 0 && inputActive ? (
        <div className={styles.drop__down}>
          {filteredList?.map?.((suggestion, index) => {
            return (
              <div
                onClick={() => {
                  onSelect(suggestion)
                  setInputActive(false)
                }}
                key={index}
              >
                <span>{suggestion}</span>
              </div>
            )
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default SearchableFieldMolecule
