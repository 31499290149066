import {
  AiOutlineBook,
  AiOutlineCodeSandbox,
  AiOutlineCustomerService,
  AiOutlineMonitor,
  AiOutlineSafetyCertificate,
  AiOutlineVideoCamera,
} from 'react-icons/ai'

import globals from '../../../../../../global.module.css'
import styles from './features.module.css'

import FeatureCardMolecule from '../../../../../../components/molecules/feature-card/FeatureCard.molecule'
import SectionHeadingMolecule from '../../../../../../components/molecules/section-heading/SectionHeading.molecule'

const CertificationFeaturesTemplate = () => {
  return (
    <section
      className={`${styles.features} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']} ${globals['p-desktop']}`}
    >
      <SectionHeadingMolecule title='Seamless and Trusted' />

      <div className={`${globals['full-width']} ${styles.features__list}`}>
        <div className={`${styles.features__list__item}`}>
          <FeatureCardMolecule
            icon={<AiOutlineCustomerService size={60} fill='var(--purple-color)' />}
            title='Comprehensive Skill Acquisition'
            description="Don't spend hours installing a language locally just to try it out. We support all 70 of our programming languages in our in-browser editor."
          />
        </div>
        <div className={`${styles.features__list__item}`}>
          <FeatureCardMolecule
            icon={<AiOutlineVideoCamera size={60} fill='var(--purple-color)' />}
            title='Interactive Class Lessons'
            description="Don't spend hours installing a language locally just to try it out. We support all 70 of our programming languages in our in-browser editor."
          />
        </div>
        <div className={`${styles.features__list__item}`}>
          <FeatureCardMolecule
            icon={<AiOutlineMonitor size={60} fill='var(--purple-color)' />}
            title='Personalized Mentoring'
            description="Don't spend hours installing a language locally just to try it out. We support all 70 of our programming languages in our in-browser editor."
          />
        </div>
        <div className={`${styles.features__list__item}`}>
          <FeatureCardMolecule
            icon={<AiOutlineCodeSandbox size={60} fill='var(--purple-color)' />}
            title='Interactive Assessments and Quizzes'
            description="Don't spend hours installing a language locally just to try it out. We support all 70 of our programming languages in our in-browser editor."
          />
        </div>
        <div className={`${styles.features__list__item}`}>
          <FeatureCardMolecule
            icon={<AiOutlineSafetyCertificate size={60} fill='var(--purple-color)' />}
            title='Industry-Recognized Certifications'
            description="Don't spend hours installing a language locally just to try it out. We support all 70 of our programming languages in our in-browser editor."
          />
        </div>
        <div className={`${styles.features__list__item}`}>
          <FeatureCardMolecule
            icon={<AiOutlineBook size={60} fill='var(--purple-color)' />}
            title='Self-paced Learning'
            description="Don't spend hours installing a language locally just to try it out. We support all 70 of our programming languages in our in-browser editor."
          />
        </div>
      </div>
    </section>
  )
}

export default CertificationFeaturesTemplate
