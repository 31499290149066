import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './profile.module.css'

import { ImageAssets } from '../../../app/assets'
import { RootState } from '../../../app/store/store'
import { appConstants } from '../../../configs/constants.config'
import { useAppSelector } from '../../../lib/hooks'
import { backend } from '../../../lib/hooks/axios-instance.hook'
import { IconRepository } from '../../../repository/icons.repository'
import { GenderTypes } from '../../../types/profile-setup.type'

import ProfileFormTemplate from '../components/templates/ProfileForm.template'

const ProfilePage = () => {
  const navigate = useNavigate()
  const authState = useAppSelector((state: RootState) => state.loginSlice)
  const userInfo = useAppSelector((state) => state.user.payload)

  const [jobs, setJobs] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (
      authState.requestResponse.data === null &&
      authState.requestResponse?.data?.user === undefined
    ) {
      navigate(appConstants.routes.auth.login)
    }
  }, [authState])

  useEffect(() => {
    if (jobs.length === 0) {
      setLoading(true)
      ;(async () => {
        await backend
          .get('/open/search/jobtitle')
          .then((response) => {
            const extract = response.data.data?.map((a: { name: string }) => a.name)
            setJobs(extract)
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
          })
      })()
    }
  }, [])

  return (
    <div
      className={`${styles.profile__management} ${globals['full-width']} ${globals['p-desktop']}`}
    >
      <div className={styles.head}>
        <Link to={appConstants.routes.home}>
          <span>
            <IconRepository.LogoIcon width='150' />
          </span>
        </Link>
        <div
          className={`${styles.profile} ${globals.flex} ${globals['a-center']} ${globals['gap-24']} ${globals['s-b']}`}
        >
          <h2>Explore and Manage Your Profile.</h2>
          <Link title='Access your dashboard' to={'/app/dashboard'}>
            <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
              {userInfo?.profile?.picture ? (
                <img
                  src={userInfo.profile.picture}
                  width={150}
                  height={150}
                  alt={userInfo.profile.firstName}
                />
              ) : (
                <img
                  src={
                    userInfo?.profile?.gender === GenderTypes.MALE
                      ? ImageAssets.MaleAvatar
                      : ImageAssets.FemaleAvatar
                  }
                  width={150}
                  height={150}
                  alt={'avatar'}
                />
              )}
            </div>
          </Link>
        </div>
        <p>
          We are here to provide you with the best user experience when using our application. We do
          care alot about our users privacy, hence we share only details about you, you grant us the
          permission to do so.
        </p>
      </div>

      <ProfileFormTemplate jobs={jobs} loading={loading} />
    </div>
  )
}

export default ProfilePage
