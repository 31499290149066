import { IconType } from '../../types/icons.type'

const SkillBadgeIcon = ({ size = '24', fillColor = 'white' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 9C19 10.45 18.57 11.78 17.83 12.89C17.2878 13.6987 16.5824 14.3852 15.7592 14.9053C14.9361 15.4254 14.0132 15.7676 13.05 15.91C12.71 15.97 12.36 16 12 16C11.64 16 11.29 15.97 10.95 15.91C9.98677 15.7676 9.06395 15.4254 8.24077 14.9053C7.41759 14.3852 6.71225 13.6987 6.17 12.89C5.40231 11.7383 4.995 10.3841 5 9C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.25 18.47L19.6 18.86C19.23 18.95 18.94 19.23 18.86 19.6L18.51 21.07C18.4671 21.2504 18.3748 21.4154 18.2435 21.5464C18.1122 21.6774 17.947 21.7693 17.7665 21.8119C17.586 21.8544 17.3972 21.8459 17.2212 21.7873C17.0452 21.7287 16.889 21.6223 16.77 21.48L12 16L7.23 21.49C7.11102 21.6323 6.95481 21.7387 6.77883 21.7973C6.60285 21.8559 6.41403 21.8644 6.23349 21.8219C6.05295 21.7793 5.8878 21.6874 5.7565 21.5564C5.62521 21.4254 5.53293 21.2604 5.49 21.08L5.14 19.61C5.09746 19.4301 5.00571 19.2657 4.87502 19.135C4.74433 19.0043 4.57986 18.9125 4.4 18.87L2.75 18.48C2.57713 18.4386 2.41837 18.3519 2.28999 18.229C2.16162 18.106 2.06823 17.9511 2.01939 17.7802C1.97055 17.6092 1.96802 17.4284 2.01206 17.2562C2.05609 17.0839 2.14511 16.9265 2.27 16.8L6.17 12.9C6.71225 13.7087 7.41759 14.3952 8.24077 14.9153C9.06395 15.4354 9.98677 15.7776 10.95 15.92C11.29 15.98 11.64 16.01 12 16.01C12.36 16.01 12.71 15.98 13.05 15.92C15.04 15.63 16.75 14.5 17.83 12.9L21.73 16.8C22.28 17.34 22.01 18.29 21.25 18.47ZM12.58 5.98L13.17 7.16C13.25 7.32 13.46 7.48 13.65 7.51L14.72 7.69C15.4 7.8 15.56 8.3 15.07 8.79L14.24 9.62C14.1 9.76 14.02 10.03 14.07 10.23L14.31 11.26C14.5 12.07 14.07 12.39 13.35 11.96L12.35 11.37C12.2484 11.3158 12.1351 11.2875 12.02 11.2875C11.9049 11.2875 11.7916 11.3158 11.69 11.37L10.69 11.96C9.97 12.38 9.54 12.07 9.73 11.26L9.97 10.23C10.01 10.04 9.94 9.76 9.8 9.62L8.97 8.79C8.48 8.3 8.64 7.81 9.32 7.69L10.39 7.51C10.57 7.48 10.78 7.32 10.86 7.16L11.45 5.98C11.74 5.34 12.26 5.34 12.58 5.98Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SkillBadgeIcon
