import AddressIcon from '../components/icons/Address.icon'
import ArrowIcon from '../components/icons/Arrow.icon'
import BadgeBagIcon from '../components/icons/BadgesBag.icon'
import BurgerIcon from '../components/icons/Burger.icon'
import CameraIcon from '../components/icons/Camera.icon'
import CaretDownIcon from '../components/icons/CaretDown.icon'
import CheckedIcon from '../components/icons/Checked.icon'
import CloseIcon from '../components/icons/Close.icon'
import CloudIcon from '../components/icons/Cloud.icon'
import CommunityIcon from '../components/icons/Community.icon'
import EyeClosedIcon from '../components/icons/EyeClosed.icon'
import EyeDefaultIcon from '../components/icons/EyeDefault.icon'
import FieldIcon from '../components/icons/Field.icon'
import GithubIcon from '../components/icons/Github.icon'
import JobtitleIcon from '../components/icons/Jobtitle.icon'
import LinkedinIcon from '../components/icons/Linkedin.icon'
import LocationIcon from '../components/icons/Location.icon'
import LockIcon from '../components/icons/Lock.icon'
import LogoIcon from '../components/icons/Logo.icon'
import MailIcon from '../components/icons/Mail.icon'
import NotificationIcon from '../components/icons/Notification.icon'
import PhoneIcon from '../components/icons/Phone.icon'
import SettingsIcon from '../components/icons/Settings.icon'
import SkillBadgeIcon from '../components/icons/SkillBadge.icon'
import UserIcon from '../components/icons/User.icon'
import VerifiedIcon from '../components/icons/Verified.icon'
import WarningIcon from '../components/icons/Warning.icon'

export const IconRepository = {
  LocationIcon,
  CameraIcon,
  BadgeBagIcon,
  FieldIcon,
  SettingsIcon,
  NotificationIcon,
  SkillBadgeIcon,
  CaretDownIcon,
  AddressIcon,
  JobtitleIcon,
  LogoIcon,
  BurgerIcon,
  CloudIcon,
  LinkedinIcon,
  GithubIcon,
  VerifiedIcon,
  WarningIcon,
  CheckedIcon,
  CloseIcon,
  LockIcon,
  MailIcon,
  ArrowIcon,
  EyeClosedIcon,
  EyeDefaultIcon,
  UserIcon,
  CommunityIcon,
  PhoneIcon,
}
