import { useEffect, useState } from 'react'

import {
  NotificationTitleType,
  showNotification,
} from '../../app/features/notification/notification.slice'
import { updateAboutInformationThunk } from '../../app/features/user/profile/profile.thunks'
import { RootState } from '../../app/store/store'
import { UpdateAboutInfoType } from '../../services/api/profile.service'
import { ApiRequestStatus, StoredErrorResponseType } from '../../types/api.type'
import { useAppDispatch, useAppSelector } from '../hooks'

const AboutInfoUpdateRepository = (data: UpdateAboutInfoType) => {
  const [updateAboutResponseData, setUpdateAboutResponseData] = useState()
  const [updateAboutError, setUpdateAboutError] = useState<StoredErrorResponseType>()

  const dispatch = useAppDispatch()
  const { requestResponse } = useAppSelector((state: RootState) => state.updateAboutSlice)

  const launchRequest = async () => {
    await dispatch(updateAboutInformationThunk(data))
  }

  useEffect(() => {
    if (requestResponse.status === ApiRequestStatus.FULFILLED) {
      setUpdateAboutResponseData(requestResponse.data)

      dispatch(
        showNotification({
          message: 'Successfully updated about information.',
          title: NotificationTitleType.SUCCESS,
        }),
      )
    }

    if (requestResponse.status === ApiRequestStatus.REJECTED) {
      setUpdateAboutError(requestResponse.error)

      dispatch(
        showNotification({
          message: requestResponse.error?.message as string,
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [requestResponse])

  return {
    launchRequest,
    updateAboutResponseData,
    updateAboutError,
    loading: requestResponse.status === ApiRequestStatus.PENDING,
  }
}

export default AboutInfoUpdateRepository
