import { IconType } from '../../types/icons.type'

const NotificationIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.02 2.91C8.71 2.91 6.02 5.6 6.02 8.91V11.8C6.02 12.41 5.76 13.34 5.45 13.86L4.3 15.77C3.59 16.95 4.08 18.26 5.38 18.7C9.69 20.14 14.34 20.14 18.65 18.7C19.86 18.3 20.39 16.87 19.73 15.77L18.58 13.86C18.28 13.34 18.02 12.41 18.02 11.8V8.91C18.02 5.61 15.32 2.91 12.02 2.91Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M13.87 3.2C12.6607 2.85559 11.3793 2.85559 10.17 3.2C10.46 2.46 11.18 1.94 12.02 1.94C12.86 1.94 13.58 2.46 13.87 3.2Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.02 19.06C15.02 20.71 13.67 22.06 12.02 22.06C11.2 22.06 10.44 21.72 9.9 21.18C9.33816 20.6173 9.0218 19.8552 9.02 19.06'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default NotificationIcon
