import { useState } from 'react'

import globals from '../../../../../global.module.css'
import styles from '../profilecrumbs.module.css'

import { ModuleSelectionType } from '../../templates/ProfileForm.template'
import ButtonAtom from '../../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../../components/atoms/button/enums'
import { IconRepository } from '../../../../../repository/icons.repository'
import InputMolecule from '../../../../../components/molecules/input/Input.molecule'
import SearchableFieldMolecule from '../../../../../components/molecules/searchable-field/SearchableField.molecule'
import UseForm from '../../../../../lib/hooks/use-form'
import { UpdateAboutInfoType } from '../../../../../services/api/profile.service'
import { Place } from '../../../../../types'
import AboutInfoUpdateRepository from '../../../../../lib/api/about-info.repository'
import { updateAboutInfoValidations } from '../../../../../lib/utils/validations/profile/update-about.validation'
import { GooglePlaceInput } from '../../../../../components/molecules/google-places/GooglePlaceInput.component'

const seededTestJobs = ['Frontend Developer', 'Backend Developer', 'DevOps', 'Full Stack Developer']

const ProfileAboutOrganism = ({ updateForm, form, jobs }: ModuleSelectionType) => {
  const [trigger, setTrigger] = useState(false)

  const { launchRequest, loading } = AboutInfoUpdateRepository({
    about: form.about as string,
    jobTitle: form.jobTitle as string,
    company: form.company as string,
    companyLocation: form.companyLocation,
  })

  const onSubmit = async () => {
    await launchRequest()
  }

  const { handleSubmit, updateForm: updateStaticValues } = UseForm<UpdateAboutInfoType>(
    {
      about: form.about as string,
      jobTitle: form.jobTitle as string,
      company: form.company as string,
      companyLocation: form.companyLocation,
    },
    trigger,
    setTrigger,
    onSubmit,
    updateAboutInfoValidations,
  )

  return (
    <div
      className={`${styles.profile__module} ${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
    >
      <h2>About & Job Info</h2>
      <p>Tell us about yourself, what you do and let us know your employment status.</p>
      <div className={styles.label__wrapper}>
        <label htmlFor='about'>Short Biography (Max: 256 characters)</label>
      </div>
      <textarea
        name='about'
        onChange={(e) => {
          if (form.about != null && form.about.length < 202) {
            updateForm('about', e.target.value)
          } else {
            updateForm('about', e.target.value.substring(0, 200))
          }
        }}
        placeholder='We are here to provide you with the best user experience when using our applications. We do
        care alot about our users privacy, hence we share only details about you, you grant us the
        permission to do so.'
        value={form?.about as string}
        cols={30}
        rows={8}
      />
      <SearchableFieldMolecule
        iconLeft={<IconRepository.JobtitleIcon size='22' />}
        label='Job Title (optional)'
        placeholder='Frontend Developer'
        name='jobTitle'
        value={form?.jobTitle as string}
        onChange={(e) => updateForm('jobTitle', e.target.value)}
        list={jobs != null ? jobs : seededTestJobs}
        onSelect={(name: string) => updateForm('jobTitle', name)}
      />
      <InputMolecule
        type='text'
        name='company'
        onChange={(e) => updateForm('company', e.target.value)}
        placeholder='Camsol'
        value={form?.company as string}
        label='Company Name (optional)'
        iconLeft={<IconRepository.AddressIcon size='22' />}
      />
      <div style={{ width: '100%' }} className={styles.endore}>
        <h4>Company&apos;s Location</h4>
        <GooglePlaceInput
          placeholder='Start typing to select location'
          onSelect={(place: Partial<Place> | undefined) => {
            updateForm('companyLocation', place)
          }}
          defaultValue={form.companyLocation?.formattedAddress}
        />
      </div>
      <ButtonAtom
        onClick={async () => {
          updateStaticValues(
            ['about', 'jobTitle', 'company', 'companyLocation'],
            [form.about, form.jobTitle, form.company, form.companyLocation],
          )

          await handleSubmit()
        }}
        loading={loading}
        buttonType={ButtonTypes.TERTIARY}
        label={'Save About Info'}
      />
    </div>
  )
}

export default ProfileAboutOrganism
