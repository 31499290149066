import { useCallback, useRef, useState } from 'react'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'

import globals from '../../../../../../global.module.css'
import styles from './hero.module.css'

import ButtonAtom from '../../../../../../components/atoms/button/Button.atom'
import {
  ButtonLooks,
  ButtonTypes,
  IconDirection,
} from '../../../../../../components/atoms/button/enums'
import InputMolecule from '../../../../../../components/molecules/input/Input.molecule'
import UseOnClickOutside from '../../../../../../lib/hooks/detect-clickoutside.hook'
import { Certification } from '../../../../../../repository/certifications.repository'
import { IconRepository } from '../../../../../../repository/icons.repository'

type Props = {
  selectedCertification: Certification
}

const TrackHeroTemplate: React.FC<Props> = ({ selectedCertification }) => {
  const [overlay, setOverlay] = useState(false)
  const [voucher, setVoucher] = useState('')

  const phoneNumber = '+237650999316'
  const defaultMessage = `Hey There! I would like to get a for this track: ${selectedCertification.name}`
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${defaultMessage}`

  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    setOverlay(false)
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  return (
    <main
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals.flex} ${globals['gap-24']} ${styles.hero}`}
    >
      <div className={`${styles.overlay} ${globals['p-desktop']} ${overlay ? styles.active : ''}`}>
        <div
          ref={ref}
          className={`${globals.flex} ${styles.box} ${globals['flex-column']} ${globals['gap-8']}`}
        >
          <div className={styles.logo}>
            <IconRepository.LogoIcon width='150' />
          </div>
          <span>Enter Voucher (Purchase Code)</span>
          <p>#1 Career Accelerator Platform</p>
          <InputMolecule
            placeholder='Enter Voucher'
            type='text'
            name='voucher'
            value={voucher}
            onChange={(e) => {
              setVoucher(e.target.value)
            }}
          />
          <ButtonAtom
            buttonLook={ButtonLooks.SOLID}
            buttonType={ButtonTypes.PRIMARY}
            label='Apply Voucher'
            className={styles.btn__right}
            fontSize='1.6rem'
            onClick={() => {
              setOverlay(true)
            }}
          />
          <div className={`${globals.flex} ${globals['s-b']}`}>
            <div></div>
            <a target='_blank' rel='noreferrer' href={whatsappUrl}>
              How do I get a voucher?
            </a>
          </div>
        </div>
      </div>

      <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-8']}`}>
        <h1>{selectedCertification?.name}.</h1>
        <p>{selectedCertification?.description}</p>
        <div
          className={`${globals.flex} ${styles.button__group} ${globals['a-center']} ${globals['gap-16']}`}
        >
          <div>
            <ButtonAtom
              buttonLook={ButtonLooks.OUTLINED}
              icon={<AiOutlineSafetyCertificate className={styles.purple} />}
              iconDir={IconDirection.RIGHT}
              buttonType={ButtonTypes.PRIMARY}
              label='Enroll Now'
              className={styles.btn__right}
              onClick={() => {
                setOverlay(true)
              }}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.illustration}`}>
        {selectedCertification.image ? (
          <div className={styles.image}>
            <selectedCertification.image width='250' height='250' />
          </div>
        ) : (
          ''
        )}
      </div>
    </main>
  )
}

export default TrackHeroTemplate
