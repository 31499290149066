import { AiOutlineBook } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import globals from '../../../../../../global.module.css'
import styles from './trophies.module.css'

import ButtonAtom from '../../../../../../components/atoms/button/Button.atom'
import {
  ButtonLooks,
  ButtonTypes,
  IconDirection,
} from '../../../../../../components/atoms/button/enums'
import SectionHeadingMolecule from '../../../../../../components/molecules/section-heading/SectionHeading.molecule'
import { appConstants } from '../../../../../../configs/constants.config'

const CertificationTrophiesTemplate = () => {
  return (
    <section
      className={`${styles.mentor} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']} ${globals['p-desktop']}`}
    >
      <div className={styles.drop}></div>
      <div
        className={`${globals.flex} ${styles.content} ${globals['s-b']} ${globals['a-center']} ${globals['gap-64']}`}
      >
        <div
          style={{
            height: 'fit-content',
            width: '100%',
          }}
          className={`${globals.flex} ${styles.texts} ${globals['flex-column']} ${globals['gap-24']}`}
        >
          <SectionHeadingMolecule
            large
            dark
            centeredElements
            title='Micro-credentials and Engaging Learning Experience'
          />
          <div className={`${globals.flex} ${globals['center-items']} ${globals['full-width']}`}>
            <p>
              Dive into a world of expertise and achievement with our micro-credentials, crafted to
              provide a nuanced understanding of your chosen subjects. Elevate your learning journey
              with an engaging and interactive experience designed to empower you with practical
              skills and real-world insights.
            </p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link
              to={appConstants.routes.auth.signup}
              style={{
                textDecoration: 'none',
              }}
            >
              <ButtonAtom
                buttonLook={ButtonLooks.OUTLINED}
                icon={<AiOutlineBook fill='var(--white-color)' className={styles.purple} />}
                iconDir={IconDirection.RIGHT}
                buttonType={ButtonTypes.SECONDARY}
                label='Get Started Today'
                className={styles.btn__right}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CertificationTrophiesTemplate
