export const apiRoutes = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    verifyEmail: '/auth/verify-email',
    logout: '/logout',
    sanctum: '/sanctum/csrf-cookie',
    googleRegister: '/auth/register/google',
    googleLogin: '/auth/login/google',
  },
  user: {
    base: '/user',
    profile: {
      picture: '/user/profile/picture',
      personal: '/user/profile/personal',
      about: '/user/profile/about',
    },
  },
}
