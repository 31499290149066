export const appConstants = {
  breakPoints: {
    XL: 1440,
    L: 1133,
    M: 834,
    S: 575,
  },
  routes: {
    home: '/',
    contribute: '/contribute',
    auth: {
      login: '/auth/login',
      signup: '/auth/signup',
      forgotPassword: '/auth/forgot-password',
      verifyEmail: '/verify-email',
      createNewPassword: '/auth/create-new-password',
    },
    dashboard: '/app/dashboard',
    dashboardModules: {
      trainings: 'trainings',
      enrolledTrainings: 'my-trainings',
      profile: '@me',
      settings: 'settings',
    },
    profile: '/app/my-profile',
  },
  storageKeys: {
    previousRoute: 'certiup-key-001',
    currentUser: 'certiup-key-002',
    phoneCodes: 'certiup-key-003',
    appLang: 'i18nextLng',
  },
}
