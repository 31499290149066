import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getExceptionPayload } from '../../../lib/utils/get-exception-payload'
import { AsyncState, retrieveErrorResponse } from '../../../lib/utils/retrieve-error-response'
import {
  loginWithGoogle,
  logoutUser,
  registerUser,
  registerWithGoogle,
} from '../../../services/api/authentication.service'
import { updateProfilePicture } from '../../../services/api/profile.service'
import {
  clearUserInfo,
  getUserInfo,
  setUserInfo,
} from '../../../services/storage/local-storage.service'
import { ApiRequestStatus, StoredErrorResponseType } from '../../../types/api.type'
import { RegisterUserDataType } from '../../../types/auth.type'
import { UserResponse } from '../../../types/user.type'
import { loginUserThunk } from '../auth/thunks/auth.thunk'

const userInfoFromStorage = getUserInfo()

const initialState: AsyncState<UserResponse, StoredErrorResponseType> = {
  loading: false,
  status: ApiRequestStatus.IDLE,
  payload: userInfoFromStorage as UserResponse,
}

const registerGoogle = createAsyncThunk<UserResponse, string>(
  'user/register/google',
  async (credential: string, thunk) => {
    try {
      const res = await registerWithGoogle(credential)
      setUserInfo(res.data.data)
      return res.data.data
    } catch (e) {
      return thunk.rejectWithValue(retrieveErrorResponse(e))
    }
  },
)

const loginGoogle = createAsyncThunk<UserResponse, string>(
  'user/login/google',
  async (credential: string, thunk) => {
    try {
      const res = await loginWithGoogle(credential)
      setUserInfo(res.data.data)
      return res.data.data
    } catch (e) {
      return thunk.rejectWithValue(retrieveErrorResponse(e))
    }
  },
)

const register = createAsyncThunk<UserResponse, RegisterUserDataType>(
  'user/register',
  async (payload: RegisterUserDataType, thunk) => {
    try {
      const res = await registerUser(payload)
      setUserInfo(res.data.data)
      return res.data.data
    } catch (e) {
      return thunk.rejectWithValue(retrieveErrorResponse(e))
    }
  },
)

export const logoutThunk = createAsyncThunk<UserResponse>('user/logout', async (_, thunk) => {
  try {
    const res = await logoutUser()
    clearUserInfo()
    window.location.reload()
    return res.data.data
  } catch (e) {
    return thunk.rejectWithValue(getExceptionPayload(e))
  }
})

const profilePicture = createAsyncThunk<UserResponse, File>(
  'user/profile/picture',
  async (payload: File, thunk) => {
    try {
      const res = await updateProfilePicture(payload)
      setUserInfo(res.data.data)
      return res.data.data
    } catch (e) {
      return thunk.rejectWithValue(getExceptionPayload(e))
    }
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetProfileManagementState(state) {
      state.loading = false
      state.status = ApiRequestStatus.IDLE
    },
    setUserInfo(state, action: PayloadAction<UserResponse>) {
      state.loading = false
      state.payload = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          registerGoogle.pending,
          loginGoogle.pending,
          register.pending,
          profilePicture.pending,
          loginUserThunk.pending,
        ),
        (state) => {
          state.loading = true
          state.status = ApiRequestStatus.PENDING
        },
      )
      .addMatcher(
        isAnyOf(
          registerGoogle.fulfilled,
          loginGoogle.fulfilled,
          register.fulfilled,
          profilePicture.fulfilled,
          loginUserThunk.fulfilled,
        ),
        (state, action) => {
          state.loading = false
          state.status = ApiRequestStatus.FULFILLED
          state.payload = action.payload
        },
      )
      .addMatcher(
        isAnyOf(
          registerGoogle.rejected,
          loginGoogle.rejected,
          register.rejected,
          profilePicture.rejected,
          loginUserThunk.rejected,
        ),
        (state, action) => {
          state.loading = false
          state.status = ApiRequestStatus.REJECTED
          state.errors = action.payload as StoredErrorResponseType
        },
      )
  },
})

export const userActions = {
  ...userSlice.actions,
  registerWithGoogle: registerGoogle,
  loginWithGoogle: loginGoogle,
  registerUser: register,
  updateProfilePicture: profilePicture,
}

export default userSlice.reducer
