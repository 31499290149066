import { useCallback, useEffect, useMemo, useState } from 'react'

import { appConstants } from '../../configs/constants.config'

type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>

export type TupleOf<T, N extends number> = N extends N
  ? number extends N
    ? T[]
    : _TupleOf<T, N, []>
  : never

type PropsDef = {
  width: number
  height: number
  iconSize?: number
}

/**
 *
 * @param iconsSizes
 * @returns
 */
export default function UseWindowSize(iconsSizes?: TupleOf<number, 4>) {
  const iconSizesMap = useMemo<
    | {
        width: number
        size: number
      }[]
    | undefined
  >(() => {
    const keys: (keyof typeof appConstants.breakPoints)[] = Object.keys(
      appConstants.breakPoints,
    ) as (keyof typeof appConstants.breakPoints)[]

    const iconsSizesTemp: {
      width: number
      size: number
    }[] = []

    if (iconsSizes && iconsSizes?.length > 0) {
      iconsSizes.map((size, index) => {
        iconsSizesTemp.push({
          width: appConstants.breakPoints[keys[index]],
          size: size,
        })
      })

      return iconsSizesTemp
    }
  }, [])
  const [windowSize, setWindowSize] = useState<PropsDef>({
    width: window.innerWidth ? window.innerWidth : 1200,
    height: window.innerHeight ? window.innerHeight : 800,
    iconSize: iconsSizes ? iconsSizes[0] : 24,
  })

  const changeWindowSize = useCallback(() => {
    if (window.innerWidth !== windowSize.width) {
      let oldVal = windowSize.iconSize

      if (iconSizesMap) {
        for (let i = 0; i < iconSizesMap.length; i++) {
          if (window.innerWidth >= iconSizesMap[i].width) {
            oldVal = iconSizesMap[i].size
            break
          }
        }
      }

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        iconSize: oldVal,
      })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}
