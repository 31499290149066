import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../../../global.module.css'
import viewsStyles from '../templates.module.css'
import styles from './profile.module.css'

import { ImageAssets } from '../../../../../app/assets'
import {
  NotificationTitleType,
  showNotification,
} from '../../../../../app/features/notification/notification.slice'
import { userActions } from '../../../../../app/features/user'
import { RootState } from '../../../../../app/store/store'
import ButtonAtom from '../../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../../components/atoms/button/enums'
import { appConstants } from '../../../../../configs/constants.config'
import { useAppDispatch, useAppSelector } from '../../../../../lib/hooks'
import { IconRepository } from '../../../../../repository/icons.repository'
import { ApiRequestStatus } from '../../../../../types/api.type'
import { GenderTypes } from '../../../../../types/profile-setup.type'

const DashboardProfileTemplate = () => {
  const [profileSelected, setProfileSelected] = useState<File | null>(null)
  const profile = useAppSelector((state: RootState) => state.user.payload?.profile)
  const { payload } = useAppSelector((state: RootState) => state.user)
  const { loading, status } = useAppSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()

  const onProfileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files !== undefined) {
      setProfileSelected(e.target.files[0])
    }
  }

  const submitProfileImage = () => {
    if (profileSelected != null) {
      dispatch(userActions.updateProfilePicture(profileSelected))
    }
  }

  useEffect(() => {
    if (status === ApiRequestStatus.FULFILLED) {
      dispatch(
        showNotification({
          message: 'Profile image successfully updated.',
          title: NotificationTitleType.SUCCESS,
        }),
      )
      dispatch(userActions.resetProfileManagementState())
    }
    if (status === ApiRequestStatus.REJECTED) {
      dispatch(
        showNotification({
          message: 'There was a problem updating your image please try again.',
          title: NotificationTitleType.ERROR,
        }),
      )
      dispatch(userActions.resetProfileManagementState())
    }
  }, [status])

  return (
    <div className={`${globals['full-width']} ${viewsStyles.container}`}>
      <div className={`${viewsStyles.head}`}>
        <h2>My Profile</h2>
      </div>

      <div
        className={`${globals.flex} ${styles.reverse} ${globals['gap-16']} ${globals['full-width']} ${globals['s-b']}`}
      >
        <div
          className={`${globals.flex} ${globals['full-width']} ${globals['flex-column']} ${styles.form} ${globals['gap-16']}`}
        >
          <h2>Personal Information</h2>

          <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-8']}`}>
            <h3 className={styles.large}>
              {profile?.firstName} {profile?.lastName}
            </h3>
            <h3>{profile?.about}</h3>

            <div className={styles.points}>
              {profile?.email ? (
                <h3 className={styles.point}>
                  <IconRepository.MailIcon size='20' /> {profile?.email}
                </h3>
              ) : (
                ''
              )}
              {profile?.phoneNumber ? (
                <h3 className={styles.point}>
                  <IconRepository.PhoneIcon size='20' /> {profile?.phoneNumber}
                </h3>
              ) : (
                ''
              )}
              {profile?.gender ? (
                <h3 className={styles.point}>
                  <IconRepository.UserIcon size='20' /> {profile?.gender}
                </h3>
              ) : (
                ''
              )}
            </div>

            <div className={styles.points}>
              {payload?.primaryPlace ? (
                <h3 className={styles.point}>
                  <IconRepository.LocationIcon size='20' /> {payload?.primaryPlace.formattedAddress}
                </h3>
              ) : (
                ''
              )}
              {payload?.secondaryPlace ? (
                <h3 className={styles.point}>
                  <IconRepository.LocationIcon size='20' />{' '}
                  {payload?.secondaryPlace.formattedAddress}
                </h3>
              ) : (
                ''
              )}
            </div>

            <div className={styles.points}>
              {profile?.jobTitle && profile?.company ? (
                <h3 className={styles.point}>
                  <IconRepository.JobtitleIcon size='22' /> {profile?.jobTitle} at{' '}
                  {profile?.company}
                </h3>
              ) : (
                ''
              )}
              {/* {profile?.jobTitle && profile?.company ? (
                <h3 className={styles.point}>
                  <IconRepository.AddressIcon size='22' /> {profile?.jobTitle} at {profile?.company}
                </h3>
              ) : (
                ''
              )} */}
            </div>

            <div className={`${globals.flex} ${globals['a-center']} ${globals['s-b']}`}>
              <div></div>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to={appConstants.routes.profile}
              >
                <ButtonAtom
                  fontSize='1.5rem'
                  padding={'10px 24px'}
                  height={'35px'}
                  buttonType={ButtonTypes.PRIMARY}
                  label='Edit Profile Information'
                />
              </Link>
            </div>
          </div>
        </div>

        <div
          className={`${styles.profile__management} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
        >
          <div className={`${styles.image__upload}`}>
            <div className={styles.image}>
              {profile?.picture ? (
                <img
                  width={200}
                  height={200}
                  src={
                    profileSelected !== null
                      ? URL.createObjectURL(profileSelected as File)
                      : profile?.picture
                  }
                  alt={profile?.firstName ?? ''}
                />
              ) : (
                <img
                  width={200}
                  height={200}
                  src={
                    profileSelected !== null
                      ? URL.createObjectURL(profileSelected as File)
                      : profile?.gender === GenderTypes.MALE
                        ? ImageAssets.MaleAvatar
                        : ImageAssets.FemaleAvatar
                  }
                  alt={profile?.firstName ?? ''}
                />
              )}
            </div>
            <div
              title='Click to change profile avatar'
              className={`${styles.upload__icon} ${globals.flex} ${globals['center-items']}`}
            >
              <div className={styles.input}>
                <input type='file' onChange={onProfileSelect} />
              </div>
              <IconRepository.CameraIcon fillColor='#fff' />
            </div>
          </div>
          <div>
            <ButtonAtom
              fontSize='1.5rem'
              padding={'10px 24px'}
              height={'35px'}
              buttonType={ButtonTypes.PRIMARY}
              loading={loading}
              label='Save Image'
              onClick={submitProfileImage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardProfileTemplate
