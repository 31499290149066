import { useEffect, useState } from 'react'

import {
  NotificationTitleType,
  showNotification,
} from '../../app/features/notification/notification.slice'
import { userActions } from '../../app/features/user'
import { RootState } from '../../app/store/store'
import { ApiRequestStatus, StoredErrorResponseType } from '../../types/api.type'
import { RegisterUserDataType } from '../../types/auth.type'
import { useAppDispatch, useAppSelector } from '../hooks'

const RegisterRepository = (data: RegisterUserDataType) => {
  const [registerError, setRegisterError] = useState<StoredErrorResponseType>()

  const dispatch = useAppDispatch()
  const userState = useAppSelector((state: RootState) => state.user)

  const launchRequest = async () => {
    await dispatch(userActions.registerUser(data))
  }

  useEffect(() => {
    if (userState.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        showNotification({
          message: 'Rgistration success, Please check your email and verify your account.',
          title: NotificationTitleType.SUCCESS,
        }),
      )
    }

    if (userState.status === ApiRequestStatus.REJECTED) {
      setRegisterError(userState.errors)

      dispatch(
        showNotification({
          message: userState.errors?.message as string,
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [userState])

  return {
    launchRequest,
    registerError,
    loading: userState.status === ApiRequestStatus.PENDING,
  }
}

export default RegisterRepository
