import loginSlice from '../features/auth/slices/login.slice'
import verifyEmailSlice from '../features/auth/slices/verify-email.slice'
import notificationSlice from '../features/notification/notification.slice'
import phoneNumberSlice from '../features/phone-number/slices/phone-number.slice'
import scripts from '../features/scripts/script.slice'
import user from '../features/user'
import logoutUserSlice from '../features/user/profile/slices/logout.slice'
import updateAboutSlice from '../features/user/profile/slices/update-about.slice'
import updatePersonalInfoSlice from '../features/user/profile/slices/update-personal-info'

const combinedRootReducers = {
  notificationSlice,
  loginSlice,
  verifyEmailSlice,
  user,
  updatePersonalInfoSlice,
  updateAboutSlice,
  logoutUserSlice,
  scripts,
  phoneNumberSlice,
}

export default combinedRootReducers
