import { RefObject } from 'react'

import globals from '../../../global.module.css'
import styles from './input.module.css'

type InputProps = {
  placeholder: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  disabled?: boolean
  type: string
  label?: string
  iconLeft?: JSX.Element
  theme?: 'dark' | 'light'
  iconRight?: JSX.Element
  switchableIcon?: JSX.Element
  switchable?: boolean
  extraAction?: () => void
  valid?: boolean
  ref?: RefObject<HTMLInputElement>
  defaultValue?: string
}

const InputMolecule: React.FC<InputProps> = ({
  label,
  placeholder,
  type,
  name,
  valid,
  onChange,
  disabled,
  iconLeft,
  iconRight,
  theme,
  value,
  switchableIcon,
  extraAction,
  switchable,
  defaultValue,
}) => {
  return (
    <div
      className={`${styles.input} ${iconLeft !== undefined ? styles.left : ''} ${
        globals['full-width']
      }`}
    >
      {label !== undefined ? (
        <div className={styles.label__wrapper}>
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
        </div>
      ) : (
        ''
      )}
      <div className={styles.field}>
        <input
          autoComplete='true'
          type={type}
          id={name}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          readOnly={disabled}
          className={`${theme === 'dark' ? 'dark' : ''} ${
            valid === true ? styles.valid : valid === undefined ? styles.valid : styles.invalid
          } ${theme === 'dark' ? styles.dark : ''}`}
          style={{
            background: theme === 'dark' ? '#1b1a1a' : '#fff',
            color: theme === 'dark' ? '#fff' : 'var(--dark-blue-color)',
          }}
          defaultValue={defaultValue}
        />
        {iconLeft !== undefined ? (
          <span className={`${styles.icon} ${styles.icon__left}`}>{iconLeft}</span>
        ) : (
          ''
        )}
        {iconRight !== undefined ? (
          <span
            role='button'
            onClick={extraAction}
            className={`${styles.icon} ${styles.icon__right}`}
          >
            {switchableIcon && switchable === true ? switchableIcon : iconRight}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default InputMolecule
