import globals from '../../../../../../global.module.css'
import styles from './features.module.css'

import FeatureCardMolecule from '../../../../../../components/molecules/feature-card/FeatureCard.molecule'
import { Certification } from '../../../../../../repository/certifications.repository'

type Props = {
  selectedCertification: Certification
}

const TrackFeaturesTemplate: React.FC<Props> = ({ selectedCertification }) => {
  return (
    <section
      className={`${styles.features} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']}`}
    >
      <div className={`${globals['full-width']} ${styles.features__list}`}>
        {selectedCertification &&
          selectedCertification.features &&
          selectedCertification.features.length > 0 &&
          selectedCertification.features.map((feature, index) => {
            return (
              <div key={index} className={`${styles.features__list__item}`}>
                <FeatureCardMolecule
                  smaller
                  title={feature.name}
                  description={feature.description}
                />
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default TrackFeaturesTemplate
