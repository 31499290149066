import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loadScriptSync } from '../../../lib/utils/dom-helpers'

const initialState: Record<string, boolean> = {}
const featureName = 'application-scripts'

export type ScriptToLoadType = {
  src: string
  id: string
}
const scriptLoader = createAsyncThunk(
  `${featureName}/loader`,
  async ({ id, src }: ScriptToLoadType, thunkAPI) => {
    try {
      const loaded = await loadScriptSync(src, id)
      if (false != loaded) {
        return { id, value: true }
      } else {
        return { id, value: false }
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({ id, value: false })
    }
  },
)
const scriptSlice = createSlice({
  name: featureName,
  initialState,
  reducers: {
    initScriptLoad: (state, action: PayloadAction<string>) => {
      state[action?.payload] = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(scriptLoader.fulfilled, (state, action) => {
        state[action.payload?.id] = action.payload.value
      })
      .addCase(scriptLoader.rejected, (state, action) => {
        const payload = action.payload as { id: string; value: boolean }
        state[payload?.id] = payload?.value
      })
  },
})

export const loadScript = createAsyncThunk(
  `${featureName}/load`,
  async ({ id, src }: ScriptToLoadType, thunkAPI) => {
    const { dispatch } = thunkAPI
    dispatch(scriptSlice.actions.initScriptLoad(id))
    dispatch(scriptLoader({ id, src }))
  },
)

export default scriptSlice.reducer
