import React from 'react'

import globals from '../../../global.module.css'
import styles from './sectionheading.module.css'

type SectionHeadingProps = {
  title: string
  centeredElements?: boolean
  large?: boolean
  dark?: boolean
}

const SectionHeadingMolecule: React.FC<SectionHeadingProps> = ({
  title,
  large,
  centeredElements,
  dark,
}) => {
  return (
    <div
      className={`${globals['full-width']} ${centeredElements ? styles.center : ''} ${
        globals.flex
      } ${globals['flex-column']} ${styles.wrapper}`}
    >
      <h2 className={`${styles.headline} ${dark ? styles.dark : ''} ${large ? styles.large : ''}`}>
        {title}
      </h2>
      <svg
        width='80'
        height='25'
        viewBox='0 0 80 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.75 12.2051H44.8772L48.2716 4L51.3265 24L53.7026 12.2051H79.5'
          stroke={dark ? 'var(--purple-color)' : '#0B0530'}
          strokeWidth='2'
        />
      </svg>
    </div>
  )
}

export default SectionHeadingMolecule
