import FooterOrganism from '../../../../components/organisms/footer/Footer.organism'
import NavbarOrganism from '../../../../components/organisms/navbar/Navbar.organism'

import ContributeHeroTemplate from '../../components/templates/contribute/ContributeHero.template'

const CertificationContributePage = () => {
  return (
    <div>
      <NavbarOrganism />
      <ContributeHeroTemplate />
      <FooterOrganism />
    </div>
  )
}

export default CertificationContributePage
