import FooterOrganism from '../../../../components/organisms/footer/Footer.organism'
import NavbarOrganism from '../../../../components/organisms/navbar/Navbar.organism'

import CertificationHeroTemplate from '../../components/templates/landing/certification/CertificationHero.template'
import CertificationFeaturesTemplate from '../../components/templates/landing/features/CertificationFeatures.template'
import CertificationLanguagesTemplate from '../../components/templates/landing/languages/CertificationLanguages.template'
import CertificationMentorTemplate from '../../components/templates/landing/mentor/CertificationMentor.template'
import CertificationQuoteTemplate from '../../components/templates/landing/quote/CertificationQuote.template'
import CertificationTrophiesTemplate from '../../components/templates/landing/trophies/CertificationTrophies.template'

const CertificationLandingPage = () => {
  return (
    <div>
      <NavbarOrganism />

      <CertificationHeroTemplate />
      <CertificationFeaturesTemplate />
      <CertificationTrophiesTemplate />
      <CertificationQuoteTemplate />
      <CertificationLanguagesTemplate />
      <CertificationMentorTemplate />

      <FooterOrganism />
    </div>
  )
}

export default CertificationLandingPage
