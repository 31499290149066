import { useEffect, useState } from 'react'

import {
  NotificationTitleType,
  showNotification,
} from '../../app/features/notification/notification.slice'
import { updatePersonalInformationThunk } from '../../app/features/user/profile/profile.thunks'
import { RootState } from '../../app/store/store'
import { UpdatePersonalInfoType } from '../../services/api/profile.service'
import { ApiRequestStatus, StoredErrorResponseType } from '../../types/api.type'
import { useAppDispatch, useAppSelector } from '../hooks'

const PersonalInfoUpdateRepository = (data: UpdatePersonalInfoType) => {
  const [updatePersonalResponseData, setUpdatePersonalResponseData] = useState()
  const [updatePersonalError, setUpdatePersonalError] = useState<StoredErrorResponseType>()

  const dispatch = useAppDispatch()
  const { requestResponse } = useAppSelector((state: RootState) => state.updatePersonalInfoSlice)

  const launchRequest = async () => {
    await dispatch(updatePersonalInformationThunk(data))
  }

  useEffect(() => {
    if (requestResponse.status === ApiRequestStatus.FULFILLED) {
      setUpdatePersonalResponseData(requestResponse.data)

      dispatch(
        showNotification({
          message: 'Successfully updated personal information.',
          title: NotificationTitleType.SUCCESS,
        }),
      )
    }

    if (requestResponse.status === ApiRequestStatus.REJECTED) {
      setUpdatePersonalError(requestResponse.error)

      dispatch(
        showNotification({
          message: requestResponse.error?.message as string,
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [requestResponse])

  return {
    launchRequest,
    updatePersonalResponseData,
    updatePersonalError,
    loading: requestResponse.status === ApiRequestStatus.PENDING,
  }
}

export default PersonalInfoUpdateRepository
