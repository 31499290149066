import { LoginDataType } from '../../../types/auth.type'
import { Validations } from '../../hooks/use-form'
import { emailValidation } from './regex.validation'

export const forgotPasswordValidations: Validations<Pick<LoginDataType, 'email'>> = {
  email: {
    pattern: {
      value: emailValidation,
      message: 'Invalid email address.',
    },
  },
}
