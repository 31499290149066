import globals from '../../../global.module.css'
import styles from './quotecard.module.css'

const QuoteCardMolecule = () => {
  return (
    <div
      className={`${styles.parent} ${globals['flex-column']} ${globals.flex} ${globals['gap-24']}`}
    >
      <div
        className={`${globals.flex} ${styles.profile} ${globals['gap-24']} ${globals['center-items']}`}
      >
        <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
          <div>
            <img src='/images/team/emma.webp' width={300} height={300} alt='Nchia Emmanuela' />
          </div>
        </div>
        <div className={`${styles.text}`}>
          <h2>Nchia Emmanuela Ekei</h2>
          <h3>Full Stack Engineer</h3>
        </div>
      </div>
      <p>
        Certification is a great way to demonstrate your skills and knowledge to potential
        employers. When you get certified, you are not just getting a piece of paper. You are
        proving to employers that you have the skills and knowledge to do the job.
      </p>
    </div>
  )
}

export default QuoteCardMolecule
