import {
  AiOutlineCompass,
  AiOutlineCopy,
  AiOutlineDatabase,
  AiOutlineFieldTime,
} from 'react-icons/ai'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import globals from '../../../../../global.module.css'
import styles from './eventhero.module.css'

import SectionHeadingMolecule from '../../../../../components/molecules/section-heading/SectionHeading.molecule'

const EventHeroTemplate = () => {
  return (
    <main
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals['flex-column']} ${globals.flex} ${globals['gap-24']} ${styles.hero}`}
    >
      <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
        <h1>CertiUp Talk Session and Networking.</h1>
        <p>
          Dive into the CertiUp Talk Session and Networking—an enriching experience that not only
          certifies your skills but also connects you with mentors, fostering meaningful connections
          and empowering your professional journey.
        </p>

        <div className={`${styles.share} ${globals['p-desktop']}`}>
          <button aria-label='share' title='Copy Event Link' className={`${styles.content}`}>
            <AiOutlineCopy fill='var(--purple-color)' size={30} />
          </button>
        </div>

        <div
          className={`${styles.more} ${globals.flex} ${globals['flex-column']} ${globals['gap-8']}`}
        >
          <div className={`${globals.flex} ${globals['a-center']} ${globals['gap-8']}`}>
            <AiOutlineDatabase fill='var(--white-color)' size={20} />
            <span>Saturday 9th December, 2023</span>
          </div>
          <div className={`${globals.flex} ${globals['a-center']} ${globals['gap-8']}`}>
            <AiOutlineFieldTime fill='var(--white-color)' size={20} />
            <span>1:00pm - 3pm WAT (GMT+1)</span>
          </div>
          <div className={`${globals.flex} ${globals['a-center']} ${globals['gap-8']}`}>
            <AiOutlineCompass fill='var(--white-color)' size={20} />
            <span>University of Buea, G-Block 112</span>
          </div>
        </div>
      </div>

      <div
        className={`${styles.speakers} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
      >
        <SectionHeadingMolecule title='Our Vibrant Speakers' />

        <div className={`${styles.display} ${globals.flex} ${globals['gap-16']}`}>
          <div className={`${styles.image}`}>
            <LazyLoadImage effect='blur' src='/images/nouks.jpg' alt="Engr. N'nouka Stephen" />
          </div>
          <div className={`${styles.image}`}>
            <LazyLoadImage effect='blur' src='/images/prince.jpg' alt="Engr. N'nouka Stephen" />
          </div>
          <div className={`${styles.image}`}>
            <LazyLoadImage effect='blur' src='/images/fongoh.jpg' alt="Engr. N'nouka Stephen" />
          </div>
        </div>
      </div>
    </main>
  )
}

export default EventHeroTemplate
