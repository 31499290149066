import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import FooterOrganism from '../../../../components/organisms/footer/Footer.organism'
import NavbarOrganism from '../../../../components/organisms/navbar/Navbar.organism'

import { Certification, certifications } from '../../../../repository/certifications.repository'
import TrackDetailsTemplate from '../../components/templates/track/details/TrackDetails.template'
import TrackHeroTemplate from '../../components/templates/track/hero/TrackHero.template'

const CertificationTrackPage = () => {
  const location = useLocation()
  const { slug } = useParams()
  const navigate = useNavigate()
  const [selectedCertification, setSelectedCertification] = useState<Certification>(
    {} as Certification,
  )

  useEffect(() => {
    if (slug) {
      const certification = certifications.find((certification) => certification.slug === slug)

      if (certification) {
        setSelectedCertification(certification)
      } else {
        navigate('/')
      }
    } else {
      navigate('/')
    }
  }, [slug])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div>
      <NavbarOrganism />

      <TrackHeroTemplate selectedCertification={selectedCertification} />
      <TrackDetailsTemplate selectedCertification={selectedCertification} />

      <FooterOrganism />
    </div>
  )
}

export default CertificationTrackPage
