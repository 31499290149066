/* eslint-disable */

import _ from 'lodash'
import { FormEvent, useEffect, useState } from 'react'

import { useAppDispatch } from '.'
import {
  NotificationTitleType,
  showNotification,
} from '../../app/features/notification/notification.slice'
import { Place } from '../../types'

interface Validation {
  required?: {
    value: boolean
    message: string
  }
  pattern?: {
    value: string | RegExp
    message: string
  }
  custom?: {
    isValid: ((value: string) => boolean) | ((value?: Partial<Place>) => boolean)
    message: string
  }
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>

export type Validations<T extends object> = Partial<Record<keyof T, Validation>>

/**
 *
 * @param data
 * @param trigger
 * @param setTrigger
 * @returns
 */
const UseForm = <T extends Record<keyof T, any>>(
  data: T,
  trigger: boolean,
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>,
  onSubmit?: () => void,
  validations?: Validations<T>,
) => {
  const [form] = useState<T>(data)
  const [errors, setErrors] = useState<ErrorRecord<T>>({})
  const dispatch = useAppDispatch()

  const updateForm = (
    name: keyof typeof data | (keyof typeof data)[],
    value: string | string[] | boolean | number | object | [] | Partial<Place> | undefined,
  ) => {
    if (Array.isArray(name) && Array.isArray(value)) {
      for (let i = 0; i < name.length; i++) {
        _.set(form, name[i], value[i])
      }
    } else {
      _.set(form, name, value)
    }

    setErrors({} as ErrorRecord<T>)
    setTrigger(!trigger)
  }

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (e !== undefined) {
      ;(e as FormEvent<HTMLFormElement>).preventDefault()
    }

    if (validations) {
      let valid = true
      const newErrors: ErrorRecord<T> = {}

      for (const key in validations) {
        const value = form[key]
        const validation = validations[key]

        if (validation?.required?.value && !value) {
          valid = false
          newErrors[key] = validation?.required?.message
        }

        const pattern = validation?.pattern
        const regTest =
          typeof pattern?.value === 'string'
            ? RegExp(pattern.value).test(value)
            : pattern?.value?.test(value)

        if (pattern?.value && !regTest) {
          valid = false
          newErrors[key] = pattern.message
        }

        const custom = validation?.custom

        if (custom?.isValid && !custom.isValid(value)) {
          valid = false
          newErrors[key] = custom.message
        }
      }

      if (!valid) {
        setErrors(newErrors)
        return
      }
    }

    setErrors({} as ErrorRecord<T>)

    if (onSubmit) {
      onSubmit()
    }
  }

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      dispatch(
        showNotification({
          message: Object.values(errors)[0] as string,
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [errors])

  useEffect(() => {
    setTrigger(!trigger)
  }, [window.location])

  return { form, errors, handleSubmit, updateForm }
}

export default UseForm
