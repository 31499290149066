import ReactHtmlParser from 'react-html-parser'

import globals from '../../../global.module.css'
import styles from './teamcard.module.css'

import { IconRepository } from '../../../repository/icons.repository'

type TeamProps = {
  name: string
  skill: string
  image: string
  linkedin: string
  github: string
  onClick: () => void
  overlay?: boolean
  fullWidth?: boolean
  more?: string[]
  isAdmin?: boolean
}

const TeamCardMolecule: React.FC<TeamProps> = ({
  name,
  image,
  github,
  skill,
  linkedin,
  onClick,
  overlay,
  fullWidth,
  more,
  isAdmin,
}) => {
  return (
    <div
      title={overlay ? '' : `${'Know more about'} ${name}`}
      className={`${styles.card} ${overlay ? styles.overlayed : ''} ${globals['center-items']} ${
        globals.flex
      } ${globals['flex-column']} ${globals['gap-24']}`}
      onClick={onClick}
      style={{
        maxWidth: fullWidth ? '700px' : '100%',
        cursor: overlay ? 'default' : 'pointer',
      }}
    >
      <div>
        <div className={`${styles.image} ${globals.flex} ${globals['center-items']}`}>
          <img src={image} width={150} height={150} alt={name} />
        </div>
      </div>

      <div
        className={`${styles.info} ${globals.flex} ${globals['flex-column']} ${globals['gap-8']}`}
      >
        <h2>{name}</h2>
        <h3
          style={{
            fontWeight: overlay ? '600' : '500',
            color: overlay ? 'var(--green-color)' : 'var(--dark-blue-color)',
          }}
        >
          {skill}
        </h3>

        {overlay ? (
          <div
            className={`${styles.more} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
          >
            {more &&
              more.length > 0 &&
              more.map((text, index) => {
                return <p key={index}>{ReactHtmlParser(text)}</p>
              })}
          </div>
        ) : (
          ''
        )}

        <div className={`${styles.social__handles} ${globals['gap-16']} ${globals.flex}`}>
          <a href={linkedin} target='_blank' rel='noreferrer'>
            <IconRepository.LinkedinIcon size='24' />
          </a>
          <a href={github} target='_blank' rel='noreferrer'>
            <IconRepository.GithubIcon size='28' />
          </a>
        </div>
      </div>

      {overlay ? (
        ''
      ) : (
        <svg
          style={{
            width: '100%',
            position: 'absolute',
            bottom: '0',
            left: 0,
          }}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1440 320'
        >
          <path
            fill='#980ecb'
            fillOpacity='1'
            d='M0,256L48,234.7C96,213,192,171,288,165.3C384,160,480,192,576,208C672,224,768,224,864,240C960,256,1056,288,1152,304C1248,320,1344,320,1392,320L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
          ></path>
        </svg>
      )}

      {overlay && isAdmin ? (
        <div className={styles.admin}>
          <span>Admin</span>
        </div>
      ) : (
        ''
      )}

      <div className={`${styles.badge} ${globals.flex} ${globals['center-items']}`}>
        <IconRepository.VerifiedIcon fillColor='var(--yellow-color)' />
      </div>
    </div>
  )
}

export default TeamCardMolecule
