import globals from '../../../global.module.css'
import styles from './featurecard.module.css'

type FeatureCardProps = {
  title: string
  description: string
  icon?: JSX.Element
  smaller?: boolean
}

// generate random colors to pass as containers backgroud
const generateRandomColor = (): string => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const FeatureCardMolecule: React.FC<FeatureCardProps> = ({ title, smaller, description, icon }) => {
  return (
    <article
      className={`${globals['full-width']} ${smaller ? styles.small : ''} ${globals.flex} ${
        globals['flex-column']
      } ${globals['gap-16']} ${styles.feature__card}`}
    >
      {icon ? (
        <span>{icon}</span>
      ) : (
        <div className={`${globals.flex} ${globals['a-center']} ${globals['gap-4']}`}>
          <div className={styles.box} style={{ backgroundColor: generateRandomColor() }}></div>
          <div className={styles.box} style={{ backgroundColor: generateRandomColor() }}></div>
          <div className={styles.box} style={{ backgroundColor: generateRandomColor() }}></div>
        </div>
      )}
      <h3>{title}</h3>
      <p>{description}</p>
    </article>
  )
}

export default FeatureCardMolecule
