import { Link, useLocation } from 'react-router-dom'

import globals from '../../../../../global.module.css'
import styles from './sidebar.module.css'

import { appConstants } from '../../../../../configs/constants.config'
import UseWindowSize from '../../../../../lib/hooks/get-window-size.hook'
import { IconRepository } from '../../../../../repository/icons.repository'

const SidebarOrganism = ({
  setSideBarActive,
}: {
  setSideBarActive: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const location = useLocation()
  const { width } = UseWindowSize()

  return (
    <aside className={`${styles.sidebar} ${globals['full-width']}`}>
      <div className={`${styles.logo} ${globals.flex} ${globals['gap-16']} ${globals['a-center']}`}>
        <Link to='/'>
          <IconRepository.LogoIcon width='120' height='80' />
        </Link>
      </div>
      <ul className={`${styles.list} ${globals.flex} ${globals['flex-column']}`}>
        <li>
          <Link
            className={location.pathname === appConstants.routes.dashboard ? styles.active : ''}
            onClick={() => {
              if (width < 1200) {
                setSideBarActive(false)
              }
            }}
            to={appConstants.routes.dashboard}
          >
            <IconRepository.AddressIcon
              size='18'
              fillColor={
                location.pathname === appConstants.routes.dashboard
                  ? 'var(--purple-color)'
                  : 'var(--dark-color)'
              }
            />
            Dashboard
          </Link>
        </li>
        <li>
          <Link
            className={
              location.pathname ===
              `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.trainings}`
                ? styles.active
                : ''
            }
            onClick={() => {
              if (width < 1200) {
                setSideBarActive(false)
              }
            }}
            to={`${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.trainings}`}
          >
            <IconRepository.SkillBadgeIcon
              size='18'
              fillColor={
                location.pathname ===
                `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.trainings}`
                  ? 'var(--purple-color)'
                  : 'var(--dark-color)'
              }
            />
            Trainings
          </Link>
        </li>

        <li>
          <Link
            className={
              location.pathname ===
              `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.enrolledTrainings}`
                ? styles.active
                : ''
            }
            onClick={() => {
              if (width < 1200) {
                setSideBarActive(false)
              }
            }}
            to={`${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.enrolledTrainings}`}
          >
            <IconRepository.FieldIcon
              size='18'
              fillColor={
                location.pathname ===
                `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.enrolledTrainings}`
                  ? 'var(--purple-color)'
                  : 'var(--dark-color)'
              }
            />
            Enrolled Trainings
          </Link>
        </li>
        <li>
          <Link
            className={
              location.pathname ===
              `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.profile}`
                ? styles.active
                : ''
            }
            onClick={() => {
              if (width < 1200) {
                setSideBarActive(false)
              }
            }}
            to={`${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.profile}`}
          >
            <IconRepository.UserIcon
              size='18'
              fillColor={
                location.pathname ===
                `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.profile}`
                  ? 'var(--purple-color)'
                  : 'var(--dark-color)'
              }
            />
            My Profile
          </Link>
        </li>
        <li className={styles.overlayed}>
          <Link
            onClick={() => {
              if (width < 1200) {
                setSideBarActive(false)
              }
            }}
            className={
              location.pathname ===
              `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.settings}`
                ? styles.active
                : ''
            }
            to={`${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.settings}`}
          >
            <IconRepository.SettingsIcon
              size='18'
              fillColor={
                location.pathname ===
                `${appConstants.routes.dashboard}/${appConstants.routes.dashboardModules.settings}`
                  ? 'var(--purple-color)'
                  : 'var(--dark-color)'
              }
            />
            Settings
          </Link>
        </li>
      </ul>
    </aside>
  )
}

export default SidebarOrganism
