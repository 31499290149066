import { Link } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './footer.module.css'

import { IconRepository } from '../../../repository/icons.repository'

const FooterOrganism = () => {
  return (
    <footer
      className={`${styles.footer} ${globals['p-desktop']} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']}`}
    >
      <div className={styles.logo}>
        <Link to={'/'}>
          <IconRepository.LogoIcon width='180' />
        </Link>
        <p>
          Our academy provides talents with opportunities to participate in research and internships
          that give them real-world experience. We are and have a strong alumni network that can
          provide talents with support and connections after being certified.
        </p>
      </div>

      <div className={`${styles.footer_component} ${globals.flex}`}>
        <div className={styles.sub}>
          <h3>Site Links</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={'/auth/signup'}>Sign Up</Link>
            </li>
            <li>
              <Link to={'/auth/login'}>Login</Link>
            </li>
          </ul>
        </div>
        <div className={styles.sub}>
          <h3>Teams</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={'/the-team'}>The Core Team</Link>
            </li>
          </ul>
        </div>
        <div className={styles.sub}>
          <h3>Companies</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={'/'}>CertiUp</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={`${styles.mark} ${globals.flex} ${globals['gap-32']} ${globals['s-b']}`}>
        <h4>CertiUp - Certifying Your Work Experience | &copy; {new Date().getFullYear()}</h4>
        <div className={`${styles.links} ${globals.flex} ${globals['gap-24']}`}>
          <Link to={'/'}>Privacy</Link>
          <Link to={'/'}>Terms of Use</Link>
        </div>
      </div>
    </footer>
  )
}

export default FooterOrganism
