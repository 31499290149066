import { UpdateAboutInfoType } from '../../../../services/api/profile.service'
import { Place } from '../../../../types'
import { Validations } from '../../../hooks/use-form'

export const updateAboutInfoValidations: Validations<UpdateAboutInfoType> = {
  about: {
    custom: {
      isValid: (value: string) => value?.length >= 128 && value?.length <= 256,
      message: 'Your bio should be atleast 128 characters and atmost 256 characters.',
    },
  },
  companyLocation: {
    custom: {
      isValid: (value?: Partial<Place>) => {
        return (value?.country as string)?.length > 0
      },
      message: 'Company location is needed.',
    },
  },
  jobTitle: {
    custom: {
      isValid: (value: string) => value?.length > 0,
      message: 'Your job title is needed.',
    },
  },
  company: {
    custom: {
      isValid: (value: string) => value?.length > 0,
      message: 'Company name is needed.',
    },
  },
}
