import { useEffect, useRef } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import {
  NotificationState,
  resetNotificationState,
} from './app/features/notification/notification.slice'
import { getPhoneNumbersThunk } from './app/features/phone-number/thunks/phone-number.thunk'
import { loadScript } from './app/features/scripts/script.slice'
import { userActions } from './app/features/user'
import { RootState } from './app/store/store'
import AppLoader from './components/atoms/app-loader/AppLoader.atom'
import NotificationMolecule from './components/molecules/notification/Notification.molecule'
import { appConstants } from './configs/constants.config'
import { useAppDispatch, useAppSelector } from './lib/hooks'
import {
  googleAuthScriptId,
  googleAuthScriptSrc,
  palcesScriptId,
  placesScriptSrc,
} from './lib/utils/google'
import { getUserInfo } from './services/storage/local-storage.service'
import { ApiRequestStatus } from './types/api.type'

import NotFoundPage from './404/NotFound.page'

import AuthCreateNewPasswordPage from './apps/authentication/pages/create-new-password/Auth.create-new-password.page'
import AuthEmailVerificationPage from './apps/authentication/pages/email-verification/Auth.email-verification.page'
import AuthForgotPasswordPage from './apps/authentication/pages/forgot-password/Auth.forgot-password.page'
import AuthLoginPage from './apps/authentication/pages/login/Auth.login.page'
import AuthRegisterPage from './apps/authentication/pages/register/Auth.register.page'

import CertificationContributePage from './apps/certification/pages/contribute/Certification.contribute.page'
import CertificationEventPage from './apps/certification/pages/event/Certification.event.page'
import CertificationLandingPage from './apps/certification/pages/landing/Certification.landing.page'
import CertificationTeamPage from './apps/certification/pages/team/Certification.team.page'
import CertificationTrackPage from './apps/certification/pages/track/Certification.track.page'

import DashboardIndexTemplate from './apps/dashboard/components/templates/dashboard-index/DashboardIndex.template'
import DashboardEnrolledTrainingsTemplate from './apps/dashboard/components/templates/enrolled-trainings/DashboardEnrolledTrainings.template'
import DashboardProfileTemplate from './apps/dashboard/components/templates/my-profile/DashboardProfile.template'
import DashboardSettingsTemplate from './apps/dashboard/components/templates/settings/DashboardSettings.template'
import DashboardTrainingsTemplate from './apps/dashboard/components/templates/trainings/DashboardTrainings.template'
import DashboardPage from './apps/dashboard/pages/Dashboard.page'

import ProfilePage from './apps/profile/pages/Profile.page'

const App = () => {
  const dispatch = useAppDispatch()
  const scriptLoadRef = useRef(false)

  const scripts = useAppSelector((state) => state.scripts)
  const notificationState = useAppSelector((state: RootState) => state.notificationSlice)
  const { requestResponse } = useAppSelector((state: RootState) => state.phoneNumberSlice)

  useEffect(() => {
    const userInfo = getUserInfo()
    if (userInfo != null) {
      dispatch(userActions.setUserInfo(userInfo))
    }

    // eslint-disable-next-line
  }, [window.location.pathname])

  useEffect(() => {
    if (notificationState.state === NotificationState.ACTIVE) {
      setTimeout(() => {
        dispatch(resetNotificationState())
      }, 5000)
    }
  }, [notificationState])

  useEffect(() => {
    if (scripts[palcesScriptId] == null && !scriptLoadRef.current) {
      dispatch(loadScript({ id: palcesScriptId, src: placesScriptSrc }))
    }
    if (scripts[googleAuthScriptId] == null && !scriptLoadRef.current) {
      dispatch(loadScript({ id: googleAuthScriptId, src: googleAuthScriptSrc }))
    }
    scriptLoadRef.current = true
  }, [])

  useEffect(() => {
    if (requestResponse.data !== undefined && requestResponse.data !== null) {
      return
    } else {
      dispatch(getPhoneNumbersThunk())
    }
  }, [])

  return (
    <div>
      <BrowserRouter>
        <NotificationMolecule />
        {requestResponse.status === ApiRequestStatus.PENDING ? <AppLoader /> : ''}
        <Routes>
          <Route path='/' element={<CertificationLandingPage />} />
          <Route path='/track/:slug' element={<CertificationTrackPage />} />
          <Route path='/trending-event' element={<CertificationEventPage />} />
          <Route path='/the-team' element={<CertificationTeamPage />} />
          <Route path={appConstants.routes.contribute} element={<CertificationContributePage />} />

          <Route path={appConstants.routes.auth.login} element={<AuthLoginPage />} />
          <Route path={appConstants.routes.auth.signup} element={<AuthRegisterPage />} />
          <Route
            path={appConstants.routes.auth.forgotPassword}
            element={<AuthForgotPasswordPage />}
          />
          <Route
            path={appConstants.routes.auth.createNewPassword}
            element={<AuthCreateNewPasswordPage />}
          />
          <Route
            path={appConstants.routes.auth.verifyEmail}
            element={<AuthEmailVerificationPage />}
          />

          <Route path={appConstants.routes.profile} element={<ProfilePage />} />

          <Route path={appConstants.routes.dashboard} element={<DashboardPage />}>
            <Route index element={<DashboardIndexTemplate />} />

            <Route
              path={appConstants.routes.dashboardModules.trainings}
              element={<DashboardTrainingsTemplate />}
            />
            <Route
              path={appConstants.routes.dashboardModules.enrolledTrainings}
              element={<DashboardEnrolledTrainingsTemplate />}
            />
            <Route
              path={appConstants.routes.dashboardModules.profile}
              element={<DashboardProfileTemplate />}
            />
            <Route
              path={appConstants.routes.dashboardModules.settings}
              element={<DashboardSettingsTemplate />}
            />
          </Route>

          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
