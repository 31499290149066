import { AsYouType, CountryCode } from 'libphonenumber-js'
import { useEffect, useState } from 'react'

import globals from '../../../../../global.module.css'
import styles from '../profilecrumbs.module.css'

import {
  NotificationTitleType,
  showNotification,
} from '../../../../../app/features/notification/notification.slice'
import { ExtractedPhoneNumberType } from '../../../../../app/features/phone-number/thunks/phone-number.thunk'
import ButtonAtom from '../../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../../components/atoms/button/enums'
import { GooglePlaceInput } from '../../../../../components/molecules/google-places/GooglePlaceInput.component'
import InputMolecule from '../../../../../components/molecules/input/Input.molecule'
import SelectMolecule from '../../../../../components/molecules/select/Select.molecule'
import PersonalInfoUpdateRepository from '../../../../../lib/api/personal-info.repository'
import { useAppDispatch } from '../../../../../lib/hooks'
import UseForm from '../../../../../lib/hooks/use-form'
import { validatePhoneNumber } from '../../../../../lib/utils/validate-phonenumber'
import { updatePersonalInfoValidations } from '../../../../../lib/utils/validations/profile/update-profile.validation'
import { IconRepository } from '../../../../../repository/icons.repository'
import { UpdatePersonalInfoType } from '../../../../../services/api/profile.service'
import { Place } from '../../../../../types/user.type'
import { ModuleSelectionType } from '../../templates/ProfileForm.template'

const ProfilePersonalInfoOrganism = ({
  updateForm,
  form,
  extractedData,
  codes,
}: ModuleSelectionType) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [trigger, setTrigger] = useState(false)
  const dispatch = useAppDispatch()

  const { launchRequest, loading } = PersonalInfoUpdateRepository({
    phoneNumber: form.phoneNumber,
    address: form.address,
    address2: form.address2,
    gender: form.gender,
  })

  const onSubmit = async () => {
    await launchRequest()
  }

  useEffect(() => {
    if (extractedData !== undefined && extractedData.length > 0) {
      extractedData.map((data, index) => {
        if (data.callingCode === '237') {
          setSelectedCountryCode('+237')
          setSelectedIndex(index)

          updateForm(
            'phoneNumber',
            (
              (extractedData as ExtractedPhoneNumberType[])[
                index as number
              ] as ExtractedPhoneNumberType
            )?.callingCode,
          )
          return
        }
      })
    }
  }, [extractedData])

  useEffect(() => {
    if (
      form !== undefined &&
      selectedCountryCode.length > 0 &&
      form?.phoneNumber.length < `+${selectedCountryCode}`.length
    ) {
      updateForm(
        'phoneNumber',
        ((extractedData as ExtractedPhoneNumberType[])[selectedIndex] as ExtractedPhoneNumberType)
          ?.callingCode,
      )
    }
  }, [form.phoneNumber])

  const { handleSubmit, updateForm: updateStaticValues } = UseForm<UpdatePersonalInfoType>(
    {
      phoneNumber: form.phoneNumber,
      address: form.address,
      address2: form.address2,
      gender: form.gender,
    },
    trigger,
    setTrigger,
    onSubmit,
    updatePersonalInfoValidations,
  )

  return (
    <div
      className={`${styles.profile__module} ${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
    >
      <h2>Personal Information & Location</h2>
      <p
        style={{
          marginBottom: '-10px',
        }}
      >
        Your location will be used to improve our product and the way recommendations are done. Help
        us make your experience smooth and enjoyable.
      </p>
      <p>
        Your information will help us provide you with the best possible service. We&apos;re
        committed to protecting your privacy, but we need your help to do it.
      </p>
      <InputMolecule
        type='text'
        name='firstName'
        onChange={(e) => updateForm('firstName', e.target.value)}
        placeholder='John Doe'
        value={form?.firstName as string}
        label='First Name'
        iconLeft={<IconRepository.UserIcon size='22' />}
        disabled={true}
      />
      <InputMolecule
        type='text'
        name='lastName'
        onChange={(e) => updateForm('lastName', e.target.value)}
        placeholder='Emmanuel'
        value={form?.lastName as string}
        label='Last Name'
        disabled={true}
        iconLeft={<IconRepository.UserIcon size='22' />}
      />
      <InputMolecule
        type='text'
        name='email'
        onChange={(e) => updateForm('email', e.target.value)}
        placeholder='chillytechhub@gmail.com'
        value={form?.email as string}
        label='Email Address'
        disabled={true}
        iconLeft={<IconRepository.MailIcon size='22' />}
      />
      <div className={`${globals['full-width']} ${globals.flex}`}>
        <div
          style={{
            width: '100%',
          }}
          className={`${styles.endore}`}
        >
          <h4>Gender</h4>
          <SelectMolecule
            height='fit-content'
            onSelect={(_data: string) => {
              updateForm('gender', _data)
            }}
            selected={form.gender}
            list={['Male', 'Female']}
          />
        </div>
      </div>
      <div className={`${globals['full-width']} ${globals.flex} ${globals['gap-16']}`}>
        <div className={styles.endore}>
          <h4>Country</h4>
          <SelectMolecule
            onSelect={(_data: string, index?: number) => {
              setSelectedCountryCode(
                (
                  (extractedData as ExtractedPhoneNumberType[])[
                    index as number
                  ] as ExtractedPhoneNumberType
                )?.callingCode,
              )
              setSelectedIndex(index as number)

              updateForm(
                'phoneNumber',
                (
                  (extractedData as ExtractedPhoneNumberType[])[
                    index as number
                  ] as ExtractedPhoneNumberType
                )?.callingCode,
              )
            }}
            selected={
              (
                (extractedData as ExtractedPhoneNumberType[])[
                  selectedIndex
                ] as ExtractedPhoneNumberType
              )?.name
            }
            list={codes as string[]}
          />
        </div>
        <InputMolecule
          type='text'
          name='phoneNumber'
          onChange={(e) =>
            updateForm(
              'phoneNumber',
              extractedData !== undefined && extractedData.length > 0
                ? new AsYouType(extractedData[selectedIndex].alpha3Code as CountryCode).input(
                    e.target.value,
                  )
                : '',
            )
          }
          placeholder='+237'
          value={form.phoneNumber.includes('+') ? form.phoneNumber : `+${form.phoneNumber}`}
          label='Phone Number'
          iconLeft={<IconRepository.PhoneIcon size='22' />}
        />
      </div>
      <div style={{ width: '100%' }} className={styles.endore}>
        <h4>Primary Address</h4>
        <GooglePlaceInput
          placeholder='Start typing to select your primary address'
          onSelect={(place: Partial<Place> | undefined) => {
            updateForm('address', place)
          }}
          defaultValue={form.address?.formattedAddress}
        />
      </div>
      <div style={{ width: '100%' }} className={styles.endore}>
        <h4>Secondary Address</h4>
        <GooglePlaceInput
          placeholder='Start typing to select your secondary address'
          onSelect={(place: Partial<Place> | undefined) => {
            updateForm('address2', place)
          }}
          defaultValue={form.address2?.formattedAddress}
        />
      </div>
      <ButtonAtom
        onClick={async () => {
          updateStaticValues(
            ['address', 'address2', 'gender', 'phoneNumber'],
            [form.address, form.address2, form.gender, form.phoneNumber],
          )

          if (
            !validatePhoneNumber(
              `${(form?.phoneNumber as string).trim()}`,
              extractedData !== undefined && extractedData.length > 0
                ? (extractedData[selectedIndex].alpha3Code as CountryCode)
                : 'AC',
            )
          ) {
            dispatch(
              showNotification({
                message: 'Please provide a valid phone number.',
                title: NotificationTitleType.ERROR,
              }),
            )
          } else await handleSubmit()
        }}
        loading={loading}
        buttonType={ButtonTypes.TERTIARY}
        label={'Save Personal Info'}
      />
    </div>
  )
}

export default ProfilePersonalInfoOrganism
