import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { resetLoginUserState } from '../../app/features/auth/slices/login.slice'
import { loginUserThunk } from '../../app/features/auth/thunks/auth.thunk'
import {
  NotificationTitleType,
  showNotification,
} from '../../app/features/notification/notification.slice'
import { RootState } from '../../app/store/store'
import { appConstants } from '../../configs/constants.config'
import { ApiRequestStatus, StoredErrorResponseType } from '../../types/api.type'
import { LoginDataType } from '../../types/auth.type'
import { useAppDispatch, useAppSelector } from '../hooks'

const LoginRepository = (data: LoginDataType) => {
  const [loginResponseData, setLoginResponseData] = useState()
  const [loginError, setLoginError] = useState<StoredErrorResponseType>()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { requestResponse } = useAppSelector((state: RootState) => state.loginSlice)

  const launchRequest = async () => {
    await dispatch(loginUserThunk(data))
  }

  useEffect(() => {
    if (requestResponse.status === ApiRequestStatus.FULFILLED) {
      setLoginResponseData(requestResponse.data)

      dispatch(
        showNotification({
          message: 'Login success, Welcome back!',
          title: NotificationTitleType.SUCCESS,
        }),
      )

      navigate(appConstants.routes.dashboard)
    }

    if (requestResponse.status === ApiRequestStatus.REJECTED) {
      setLoginError(requestResponse.error)

      dispatch(
        showNotification({
          message: requestResponse.error?.message as string,
          title: NotificationTitleType.ERROR,
        }),
      )

      setTimeout(() => {
        dispatch(resetLoginUserState())
      }, 2000)
    }
  }, [requestResponse])

  return {
    launchRequest,
    loginResponseData,
    loginError,
    loading: requestResponse.status === ApiRequestStatus.PENDING,
  }
}

export default LoginRepository
