import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import FooterOrganism from '../../../../components/organisms/footer/Footer.organism'
import NavbarOrganism from '../../../../components/organisms/navbar/Navbar.organism'

import TeamHeroTemplate from '../../components/templates/team/hero/TeamHero.template'
import TeamMembersTemplate from '../../components/templates/team/members/TeamMembers.template'

const CertificationTeamPage = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div>
      <NavbarOrganism />

      <TeamHeroTemplate />
      <TeamMembersTemplate />

      <FooterOrganism />
    </div>
  )
}

export default CertificationTeamPage
