import { IconType } from '../../types/icons.type'

const CloudIcon = ({ size = '80', fillColor = '#980ecb' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.1'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7838 49.3606C9.6701 49.3606 7.97413 51.0566 7.97413 53.1704C7.97413 55.2783 9.69749 56.9801 11.7838 56.9801H62.3664C62.3668 56.9801 62.367 56.9801 62.367 58.3951C62.367 59.8101 62.3668 59.8101 62.3664 59.8101H11.7838C8.14629 59.8101 5.14417 56.8529 5.14417 53.1704C5.14417 49.4937 8.10713 46.5307 11.7838 46.5307C15.4605 46.5307 18.4235 49.4937 18.4235 53.1704H15.5935C15.5935 51.0566 13.8976 49.3606 11.7838 49.3606Z'
        fill={fillColor}
      />
      <path
        opacity='0.1'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.3337 40.9745C57.9205 40.9745 54.3309 44.5373 54.3309 48.9773H51.5009C51.5009 42.9679 56.3641 38.1445 62.3337 38.1445C68.3066 38.1445 73.1664 43.0044 73.1664 48.9773C73.1664 54.9867 68.3033 59.81 62.3337 59.81V56.9801C66.7469 56.9801 70.3365 53.4173 70.3365 48.9773C70.3365 44.5673 66.7436 40.9745 62.3337 40.9745Z'
        fill={fillColor}
      />
      <path
        opacity='0.1'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.9439 35.2839C33.5973 35.2839 31.7016 37.1795 31.7016 39.5262C31.7016 41.8729 33.5973 43.7686 35.9439 43.7686C38.2906 43.7686 40.1863 41.8729 40.1863 39.5262H43.0163C43.0163 43.4358 39.8536 46.5985 35.9439 46.5985C32.0343 46.5985 28.8716 43.4358 28.8716 39.5262C28.8716 35.6166 32.0343 32.4539 35.9439 32.4539V35.2839Z'
        fill={fillColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.3337 34.3405C54.2746 34.3405 47.697 40.9182 47.697 48.9773C47.697 49.2378 47.4858 49.4489 47.2253 49.4489C46.9648 49.4489 46.7536 49.2378 46.7536 48.9773C46.7536 40.3972 53.7536 33.3972 62.3337 33.3972C70.9138 33.3972 77.9137 40.3972 77.9137 48.9773C77.9137 57.558 70.9464 64.524 62.3337 64.524H36.3774C36.3772 64.524 36.3766 64.524 36.3766 64.0524C36.3766 63.5807 36.3766 63.5807 36.3768 63.5807H62.3337C70.4268 63.5807 76.9704 57.0357 76.9704 48.9773C76.9704 40.9182 70.3928 34.3405 62.3337 34.3405ZM36.3766 64.0524C36.3766 63.5807 36.3766 63.5807 36.3768 63.5807H12.7822C7.1761 63.5807 2.47163 59.0236 2.47163 53.4365C2.47163 47.84 6.78634 43.5254 12.3829 43.5254C16.4169 43.5254 18.8766 45.6931 20.3507 48.1818C21.8384 50.6936 22.294 53.4959 22.294 54.6013C22.294 54.8618 22.5052 55.0729 22.7657 55.0729C23.0262 55.0729 23.2373 54.8618 23.2373 54.6013C23.2373 53.3439 22.7446 50.3725 21.1623 47.7011C19.5665 45.0067 16.8348 42.5821 12.3829 42.5821C6.26537 42.5821 1.52832 47.3191 1.52832 53.4365C1.52832 59.5635 6.67427 64.524 12.7822 64.524H36.3757C36.3759 64.524 36.3766 64.524 36.3766 64.0524Z'
        fill={fillColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.0422 15.4717C24.2057 15.4717 13.7863 26.2274 13.7863 39.5262C13.7863 39.7867 13.5752 39.9979 13.3147 39.9979C13.0542 39.9979 12.843 39.7867 12.843 39.5262C12.843 25.7364 23.6553 14.5284 37.0422 14.5284C46.7931 14.5284 55.207 20.508 59.0375 29.118C59.1433 29.356 59.0362 29.6348 58.7983 29.7407C58.5603 29.8465 58.2815 29.7394 58.1756 29.5014C54.4851 21.2061 46.393 15.4717 37.0422 15.4717Z'
        fill={fillColor}
      />
      <g opacity='0.4'>
        <g opacity='0.4'>
          <path
            opacity='0.4'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M37.6411 26.8095C29.68 26.8095 23.1938 33.3199 23.1938 41.4231H18.4772C18.4772 30.7574 27.0329 22.0929 37.6411 22.0929C45.4009 22.0929 52.0351 26.7358 55.0607 33.3544L50.7711 35.3154C48.4722 30.2865 43.4589 26.8095 37.6411 26.8095Z'
            fill={fillColor}
          />
        </g>
      </g>
      <path
        opacity='0.4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.3669 55.0961H26.4928V50.3795H62.3669V55.0961Z'
        fill={fillColor}
      />
    </svg>
  )
}

export default CloudIcon
