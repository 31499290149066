import { CSSProperties, useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import classes from './places.module.css'

import { useAppSelector } from '../../../lib/hooks'
import { palcesScriptId } from '../../../lib/utils/google'
import InputMolecule from '../input/Input.molecule'
import { GeoAddress } from './types'
import { parseGeoResult } from './utils'

export interface PlaceProp {
  onSelect?: (geo?: GeoAddress) => void
  label?: string
  placeholder?: string
  defaultValue?: string
}

export const GooglePlaceInput = ({ onSelect, label, placeholder, defaultValue }: PlaceProp) => {
  const [address, setAddress] = useState('')
  const [ready, setReady] = useState(false)
  const scripts = useAppSelector((state) => state.scripts)
  useEffect(() => {
    if (scripts[palcesScriptId]) {
      setReady(true)
    }
    setAddress(defaultValue != null ? defaultValue : '')
  }, [defaultValue, scripts])

  const handleChange = (addr: string) => {
    setAddress(addr)
  }

  const handleSelect = (addr: string) => {
    setAddress(addr)
    geocodeByAddress(addr)
      .then(async (results) => {
        if (onSelect) {
          onSelect(parseGeoResult(results[0]))
        }
      })
      .catch((error) => console.error('Error', error))
  }

  return ready ? (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={classes.PlaceContainer}>
          {
            // eslint-disable-next-line
            // @ts-ignore
            <InputMolecule
              label={label}
              {...getInputProps({
                placeholder: placeholder ?? '',
                className: 'location-search-input',
                name: 'place',
              })}
            />
          }
          <div className={classes.loader}>{loading && <h4>Loading ...</h4>}</div>
          {!loading && suggestions.length > 0 ? (
            <div className={classes.PlaceDropDownList}>
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                const style: CSSProperties = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#fff', cursor: 'pointer' }

                return (
                  // eslint-disable-next-line
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: `${className} ${classes.PlaceListItem}`,
                      style,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  ) : null
}
