import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { RootState } from '../app/store/store'
import { appConstants } from '../configs/constants.config'
import { useAppSelector } from '../lib/hooks'

type AuthLayoutProps = {
  children: React.ReactNode
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const authState = useAppSelector((state: RootState) => state.loginSlice)
  const navigate = useNavigate()

  useEffect(() => {
    if (authState.requestResponse.data !== null && authState.requestResponse.data?.user) {
      navigate(appConstants.routes.dashboard)
    }
  }, [authState])

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo(0, 0)
    }
  }, [])

  return <Fragment>{children}</Fragment>
}

export default AuthLayout
