import globals from '../../../global.module.css'
import styles from './apploader.module.css'

const AppLoader = () => {
  return (
    <div
      className={`${styles.app__loader} ${globals['gap-24']} ${globals['flex-column']} ${globals['full-width']} ${globals.flex} ${globals['center-items']}`}
    >
      <h1>Certifying Work Experiences...</h1>
      <div className={styles.roller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default AppLoader
