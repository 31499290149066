import React from 'react'

import globals from '../../../../../../global.module.css'
import styles from './roadmap.module.css'

import Step from '../../../../../../components/molecules/step/Step.molecule'
import { Certification } from '../../../../../../repository/certifications.repository'

type Props = {
  selectedCertification: Certification
}

const TrackRoadmap: React.FC<Props> = ({ selectedCertification }) => {
  return (
    <>
      <section className={styles.roadmap}>
        <div className={`${globals['full-width']} ${styles.container}`}>
          {selectedCertification &&
            selectedCertification.roadMap &&
            selectedCertification.roadMap.length > 0 &&
            selectedCertification.roadMap.map((step, index) => {
              return <Step key={index} title={step} />
            })}
        </div>
      </section>
    </>
  )
}

export default TrackRoadmap
