import { IconType } from '../../types/icons.type'

const GithubIcon = ({ size = '48', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3925 1.5C9.50598 1.47067 6.72589 2.58861 4.66325 4.60812C2.6006 6.62764 1.42418 9.38349 1.39252 12.27C1.40364 14.5512 2.13746 16.7701 3.48858 18.6081C4.8397 20.4462 6.73859 21.8087 8.91252 22.5C9.46252 22.6 9.66252 22.27 9.66252 21.98C9.66252 21.69 9.66252 21.05 9.66252 20.15C6.60252 20.8 5.95252 18.71 5.95252 18.71C5.74881 18.0539 5.31575 17.493 4.73252 17.13C3.73252 16.47 4.81252 16.48 4.81252 16.48C5.15817 16.5262 5.48887 16.6501 5.77983 16.8423C6.07078 17.0346 6.31444 17.2902 6.49252 17.59C6.80285 18.1281 7.31205 18.5229 7.91053 18.6893C8.50901 18.8558 9.14891 18.7806 9.69252 18.48C9.74842 17.9324 9.99641 17.4222 10.3925 17.04C7.95252 16.77 5.39252 15.85 5.39252 11.72C5.3707 10.6427 5.76876 9.59911 6.50252 8.81C6.16834 7.88507 6.2078 6.86631 6.61252 5.97C6.61252 5.97 7.54252 5.68 9.61252 7.07C11.4144 6.58984 13.3106 6.58984 15.1125 7.07C17.2125 5.68 18.1125 5.97 18.1125 5.97C18.5172 6.86631 18.5567 7.88507 18.2225 8.81C18.9733 9.58465 19.393 10.6212 19.3925 11.7C19.3925 15.84 16.8125 16.75 14.3925 17.02C14.6575 17.2773 14.8622 17.5901 14.9919 17.9359C15.1215 18.2817 15.173 18.6519 15.1425 19.02V21.97C15.1425 21.97 15.3425 22.6 15.8925 22.49C18.0603 21.7944 19.9528 20.4316 21.2997 18.5961C22.6466 16.7607 23.3791 14.5466 23.3925 12.27C23.3609 9.38349 22.1844 6.62764 20.1218 4.60812C18.0591 2.58861 15.2791 1.47067 12.3925 1.5Z'
        fill={fillColor}
      />
    </svg>
  )
}

export default GithubIcon
