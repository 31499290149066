import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import globals from '../../../../../../global.module.css'
import styles from './details.module.css'

import SectionHeadingMolecule from '../../../../../../components/molecules/section-heading/SectionHeading.molecule'
import { Certification } from '../../../../../../repository/certifications.repository'
import TrackFeaturesTemplate from '../features/TrackFeatures.template'
import TrackRoadmap from '../roadmap/TrackRoadmap.template'

type Props = {
  selectedCertification: Certification
}

const TrackDetailsTemplate: React.FC<Props> = ({ selectedCertification }) => {
  const navigate = useNavigate()
  const { slug } = useParams()

  const [tab, setTab] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tabParam = searchParams.get('tab')

    if (tab == null) {
      navigate(`/track/${slug}?tab=features-and-concepts`)
    }

    setTab(tabParam)
  }, [location.search])

  return (
    <section
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals['flex-column']} ${globals.flex} ${globals['gap-24']} ${styles.track__details}`}
    >
      <SectionHeadingMolecule title='Take a Deep Dive' />

      <div
        className={`${globals['full-width']} ${styles.tabs__control} ${globals.flex} ${globals['a-center']}`}
      >
        <button
          onClick={() => {
            navigate(`/track/${slug}?tab=features-and-concepts`)
          }}
          className={`${tab === 'features-and-concepts' ? styles.active : ''}`}
        >
          Key Features and Concepts
        </button>
        <button
          onClick={() => {
            navigate(`/track/${slug}?tab=curriculum-and-roadmap`)
          }}
          className={`${tab === 'curriculum-and-roadmap' ? styles.active : ''}`}
        >
          Curriculum & RoadMap
        </button>
        {/* <button
          onClick={() => {
            navigate('/track/css?tab=certifications')
          }}
          className={`${tab === 'certifications' ? styles.active : ''}`}
        >
          React Certifications
        </button> */}
      </div>

      <div className={`${styles.tabs__content}`}>
        {tab === 'features-and-concepts' && (
          <TrackFeaturesTemplate selectedCertification={selectedCertification} />
        )}
        {tab === 'curriculum-and-roadmap' && (
          <TrackRoadmap selectedCertification={selectedCertification} />
        )}
        {/* {tab === 'certifications' && <div>Certifications</div>} */}
      </div>
    </section>
  )
}

export default TrackDetailsTemplate
