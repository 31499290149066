import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from '../auth.module.css'

import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../components/atoms/button/enums'
import InputMolecule from '../../../../components/molecules/input/Input.molecule'
import AuthLayout from '../../../../layout/Auth.layout'
import UseForm from '../../../../lib/hooks/use-form'
import { changePasswordValidations } from '../../../../lib/utils/validations/change-password.validation'
import {
  min8DigitRegex,
  oneNumericCharacterRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../../../../lib/utils/validations/regex.validation'
import { IconRepository } from '../../../../repository/icons.repository'
import { ForgotPasswordDataType } from '../../../../types/auth.type'

const AuthCreateNewPasswordPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [trigger, setTrigger] = useState(false)

  const [params] = useSearchParams()

  const email = params.get('email')
  const token = params.get('token')

  const onSubmit = () => {
    console.log({ form })
  }

  const { form, updateForm, handleSubmit } = UseForm<ForgotPasswordDataType>(
    {
      token: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    trigger,
    setTrigger,
    onSubmit,
    changePasswordValidations,
  )

  const { password, confirmPassword } = form

  useEffect(() => {
    const safeEmailParamValue = email === null || email === undefined ? '' : email
    const safeTokenParamValue = token === null || token === undefined ? '' : token

    updateForm(['email', 'token'], [safeEmailParamValue, safeTokenParamValue])
  }, [params])

  return (
    <AuthLayout>
      <div
        className={`${styles.auth} ${globals['flex-column']} ${globals['p-desktop']} ${globals['center-items']} ${globals.flex} ${globals['full-width']}`}
      >
        <Link className={styles.logo} to='/'>
          <span>
            <IconRepository.LogoIcon width='180' />
          </span>
        </Link>
        <div className={`${styles.auth__container} ${globals.flex}`}>
          <div
            className={`${styles.banner}  ${globals.flex} ${globals['center-items']} ${globals['flex-column']} ${globals['gap-24']}`}
          >
            <span
              style={{
                fontSize: '10rem',
                marginBottom: '-20px',
                marginTop: '-40px',
              }}
            >
              🪄
            </span>
            <h2>You can still log into your account.</h2>
            <Link
              className={`${globals.flex} ${globals['gap-12']} ${globals['a-center']}`}
              to='/auth/login'
            >
              Login <IconRepository.ArrowIcon fillColor='#fff' />
            </Link>
          </div>
          <div
            className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
          >
            <h2>Create New Password</h2>

            <div
              className={`${styles.form__wrapper} ${globals['a-center']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              <InputMolecule
                label='Password'
                type={showPassword === true ? 'text' : 'password'}
                name='password'
                onChange={(e) => updateForm('password', e.target.value)}
                placeholder='********'
                value={password}
                iconLeft={<IconRepository.LockIcon size='22' />}
                iconRight={<IconRepository.EyeClosedIcon size='22' />}
                switchableIcon={<IconRepository.EyeDefaultIcon size='22' />}
                switchable={showPassword === true}
                extraAction={() => {
                  setShowPassword(!showPassword)
                }}
              />
              <ul className={`${styles.validation__errors} ${globals['full-width']}`}>
                {!specialCharacterRegex.test(password) ? <li>One special character</li> : ''}
                {!min8DigitRegex.test(password) ? <li>Min 8 characters</li> : ''}
                {!oneNumericCharacterRegex.test(password) ? (
                  <li>One number (2 are recommended)</li>
                ) : (
                  ''
                )}
                {!upperCaseRegex.test(password) ? <li>One capital letter</li> : ''}
              </ul>
              <InputMolecule
                label='Confirm Password'
                type={showConfirmPassword === true ? 'text' : 'password'}
                name='confirmPassword'
                onChange={(e) => updateForm('confirmPassword', e.target.value)}
                placeholder='********'
                value={confirmPassword}
                iconLeft={<IconRepository.LockIcon size='22' />}
                iconRight={<IconRepository.EyeClosedIcon size='22' />}
                switchableIcon={<IconRepository.EyeDefaultIcon size='22' />}
                switchable={showConfirmPassword === true}
                extraAction={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}
              />

              <ButtonAtom
                onClick={() => {
                  handleSubmit()
                }}
                buttonType={ButtonTypes.PRIMARY}
                label='Create New Password'
                loading={false}
              />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default AuthCreateNewPasswordPage
