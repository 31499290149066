import { IconType } from '../../types/icons.type'

const CameraIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5 8H13.5M6.76 22H17.24C20 22 21.1 20.31 21.23 18.25L21.75 9.99C21.7824 9.47783 21.7094 8.96446 21.5356 8.48161C21.3617 7.99876 21.0907 7.5567 20.7392 7.18275C20.3878 6.80879 19.9634 6.51089 19.4922 6.30746C19.0211 6.10403 18.5132 5.99939 18 6C17.39 6 16.83 5.65 16.55 5.11L15.83 3.66C15.37 2.75 14.17 2 13.15 2H10.86C9.83 2 8.63 2.75 8.17 3.66L7.45 5.11C7.17 5.65 6.61 6 6 6C3.83 6 2.11 7.83 2.25 9.99L2.77 18.25C2.89 20.31 4 22 6.76 22Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 18C13.79 18 15.25 16.54 15.25 14.75C15.25 12.96 13.79 11.5 12 11.5C10.21 11.5 8.75 12.96 8.75 14.75C8.75 16.54 10.21 18 12 18Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CameraIcon
