import { createAsyncThunk } from '@reduxjs/toolkit'

import { userActions } from '..'
import { retrieveErrorResponse } from '../../../../lib/utils/retrieve-error-response'
import {
  UpdateAboutInfoType,
  UpdatePersonalInfoType,
  updateAboutInformation,
  updatePersonalInformation,
} from '../../../../services/api/profile.service'
import { setUserInfo } from '../../../../services/storage/local-storage.service'

export const updatePersonalInformationThunk = createAsyncThunk(
  '/user/update-personal-info',
  async (data: UpdatePersonalInfoType, { rejectWithValue, dispatch }) => {
    try {
      const response = await updatePersonalInformation(data)
      setUserInfo(response.data?.data)
      dispatch(userActions.setUserInfo(response.data?.data))
      return response.data
    } catch (ex) {
      return rejectWithValue(retrieveErrorResponse(ex))
    }
  },
)

export const updateAboutInformationThunk = createAsyncThunk(
  '/user/update-about-info',
  async (data: UpdateAboutInfoType, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateAboutInformation(data)
      setUserInfo(response.data?.data)
      dispatch(userActions.setUserInfo(response.data?.data))
      return response.data
    } catch (ex) {
      return rejectWithValue(retrieveErrorResponse(ex))
    }
  },
)
