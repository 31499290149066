import { createSlice } from '@reduxjs/toolkit'

import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../../types/api.type'
import { updatePersonalInformationThunk } from '../profile.thunks'

export type UpdateDataState = {
  requestResponse: ApiRequestDataType
}

export const initialState: UpdateDataState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: '',
  },
}

const updatePersonalInfoSlice = createSlice({
  name: 'updatePersonalInfoSlice',
  initialState: initialState,
  reducers: {
    resetPersonalInfoState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePersonalInformationThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(updatePersonalInformationThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(updatePersonalInformationThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetPersonalInfoState } = updatePersonalInfoSlice.actions
export default updatePersonalInfoSlice.reducer
