import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from './profileform.module.css'

import { ImageAssets } from '../../../../app/assets'
import {
  ExtractedPhoneNumberType,
  PhoneNumberType,
} from '../../../../app/features/phone-number/thunks/phone-number.thunk'
import { RootState } from '../../../../app/store/store'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../components/atoms/button/enums'
import { appConstants } from '../../../../configs/constants.config'
import { useAppSelector } from '../../../../lib/hooks'
import UseForm from '../../../../lib/hooks/use-form'
import { extractPhoneFields } from '../../../../lib/utils/extract-phone-fields'
import { Place } from '../../../../types'
import { ApiRequestStatus } from '../../../../types/api.type'
import {
  EducationalBackgroundType,
  GenderTypes,
  ProfileSetupTypes,
  WorkExperienceType,
} from '../../../../types/profile-setup.type'

import ProfileAboutOrganism from '../organisms/about/ProfileAbout.organism'
import ProfilePersonalInfoOrganism from '../organisms/personal-info/ProfilePersonalInfo.organism'

export type ModuleSelectionType = {
  form: ProfileSetupTypes
  codes?: string[]
  updateForm: (
    name: keyof ProfileSetupTypes | (keyof ProfileSetupTypes)[],
    value: string | number | object | [] | string[] | Partial<Place> | undefined,
  ) => void
  extractedData?: ExtractedPhoneNumberType[]
  jobs?: string[]
}

export type RequiredTypeRef = Required<ProfileSetupTypes>

const ProfileFormTemplate = ({ loading, jobs }: { loading: boolean; jobs: string[] }) => {
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const [triggerChange, setTriggerChange] = useState(false)
  const navigate = useNavigate()

  const profile = useAppSelector((state: RootState) => state.user.payload?.profile)
  const { payload } = useAppSelector((state: RootState) => state.user)

  const { form, updateForm } = UseForm<RequiredTypeRef>(
    {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      country: '',
      gender: GenderTypes.MALE,
      address: {} as Partial<Place> | undefined,
      address2: {} as Partial<Place> | undefined,
      about: '',
      jobTitle: '',
      companyLocation: {} as Partial<Place> | undefined,
      company: '',
      workExperience: [] as WorkExperienceType[],
      educationalBackground: [] as EducationalBackgroundType[],
    },
    triggerChange,
    setTriggerChange,
  )

  const { requestResponse } = useAppSelector((state: RootState) => state.phoneNumberSlice)
  const [extractedAttributes, setExtractedAttributes] = useState<ExtractedPhoneNumberType[]>([])
  const [callingCodes, setCallingCodes] = useState<string[]>([])

  const loadPhoneSchemaFromSource = useCallback(() => {
    if (requestResponse.status === ApiRequestStatus.FULFILLED) {
      setExtractedAttributes(extractPhoneFields(requestResponse.data as PhoneNumberType[]))
    }

    if (requestResponse.status === ApiRequestStatus.REJECTED) {
      console.log({ phoneNumberAPIError: ApiRequestStatus.REJECTED })
    }
  }, [requestResponse])

  useEffect(() => {
    loadPhoneSchemaFromSource()
  }, [requestResponse, loadPhoneSchemaFromSource])

  useEffect(() => {
    const temp: string[] = []

    if (extractedAttributes.length > 0) {
      extractedAttributes.map((data) => {
        temp.push(data.name)
      })

      setCallingCodes(temp)
    }
  }, [extractedAttributes])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentFormStep])

  useEffect(() => {
    updateForm(
      [
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'country',
        'address',
        'address2',
        'about',
        'jobTitle',
        'company',
      ],
      [
        profile?.firstName as string,
        profile?.lastName as string,
        profile?.email as string,
        profile?.phoneNumber !== undefined &&
        profile.phoneNumber !== null &&
        (profile?.phoneNumber as string).length > 0
          ? (profile?.phoneNumber as string)
          : '',
        profile?.country as string,
        payload?.primaryPlace as Partial<Place> | undefined,
        payload?.secondaryPlace as Partial<Place> | undefined,
        profile?.about,
        profile?.jobTitle,
        profile?.company,
      ],
    )
  }, [profile, payload])

  return (
    <section>
      <div
        className={`${globals['gap-24']} ${styles.profile__form} ${globals.flex} ${globals['full-width']}`}
      >
        <div className={`${styles.form__wrapper} ${globals['p-y-24']}`}>
          {loading ? (
            <div
              className={`${styles.loader} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']}`}
            >
              <div className={styles.one}></div>
              <div className={styles.two}></div>
              <div className={styles.two}></div>
              <div className={styles.three}></div>
              <div
                style={{
                  marginTop: '32px',
                }}
                className={styles.two}
              ></div>
            </div>
          ) : (
            <div className={`${styles.form}`}>
              {currentFormStep === 0 ? (
                <ProfilePersonalInfoOrganism
                  extractedData={extractedAttributes}
                  codes={callingCodes}
                  updateForm={updateForm}
                  form={form}
                />
              ) : (
                ''
              )}

              {currentFormStep === 1 ? (
                <ProfileAboutOrganism jobs={jobs} updateForm={updateForm} form={form} />
              ) : (
                ''
              )}

              {/* {currentFormStep === 2 ? (
              <WorkExperienceMolecule updateForm={updateForm} form={form} />
            ) : (
              ''
            )} */}

              {/* {currentFormStep === 3 ? <EducationMolecule updateForm={updateForm} form={form} /> : ''} */}

              <div
                className={`${styles.controllers} ${globals.flex} ${globals['gap-16']} ${globals['s-b']} ${globals['a-center']}`}
              >
                {currentFormStep > 0 ? (
                  <ButtonAtom
                    onClick={() => {
                      setCurrentFormStep((prev) => prev - 1)
                    }}
                    label='Previous'
                    buttonType={ButtonTypes.PRIMARY}
                  />
                ) : (
                  ''
                )}
                <ButtonAtom
                  onClick={() => {
                    if (currentFormStep < 1) {
                      setCurrentFormStep((prev) => prev + 1)
                    } else {
                      navigate(appConstants.routes.dashboard)
                    }
                  }}
                  buttonType={ButtonTypes.PRIMARY}
                  label={currentFormStep === 1 ? 'Continue' : 'Next'}
                />
              </div>
            </div>
          )}
        </div>
        <div className={`${styles.svg}`}>
          <img width='100%' height='100%' src={ImageAssets.ProfileSetup} alt='academy' />
        </div>
      </div>
    </section>
  )
}

export default ProfileFormTemplate
