export enum ApiRequestStatus {
  IDLE,
  PENDING,
  REJECTED,
  FULFILLED,
}

export type ApiRequestErrorType = {
  response: {
    data: {
      message: string
    }
    status: number
  }
}

export type ApiVaidationRequestErrorType = {
  response: {
    data: {
      errors: object
    }
    status: number
  }
}

export type StoredErrorResponseType = {
  message: string
  code: number
}

// eslint-disable-next-line
export type ApiRequestDataType<DataType = any> = {
  status: ApiRequestStatus
  error?: StoredErrorResponseType
  data?: DataType
}

export type ApiResponse<T = unknown, E = unknown> = {
  data: T
  errors: E[]
  status: string
  message: string
}

export interface ApiException {
  code: string
  message: string
  status: string
}

export interface ApiError {
  code: string
  message: string
}

export interface ApiValidationException extends ApiError {
  fields: unknown
}

export type ApiErrors = ApiError | ApiException | ApiValidationException
