export const loadScriptSync = (src: string, id: string): Promise<boolean | string> =>
  new Promise((resolve) => {
    createScript(id, src, () => resolve('script loaded'))
  })
export const createScript = (id: string, src: string, onLoad: () => void) => {
  const script = document.createElement('script')
  script.onload = onLoad
  script.src = src
  if (id) {
    script.id = id
  }
  script.type = 'text/javascript'
  script.async = false
  document.getElementsByTagName('body')[0].appendChild(script)
  return script
}

export const removeElementById = (id: string) =>
  new Promise((resolve, reject) => {
    const el = document.getElementById(id)
    if (el) {
      const parentEl = el.parentNode
      if (parentEl) {
        parentEl.removeChild(el)
        resolve(`removed ${id}`)
      } else {
        reject(new Error(`cannot remove ${id}: its the root`))
      }
    } else {
      reject(new Error(`cannot remove ${id}: not found in the DOM`))
    }
  })

export const getBlobUrl = (code: string, type: string): string => {
  const blob = new Blob([code], { type })
  return URL.createObjectURL(blob)
}

export const getHtmlDocumentUrl = ({
  html,
  css,
  js,
}: {
  html: string
  css: string
  js: string
}): string => {
  const _css = css || ''
  const _js = js || ''
  const cssUrl = getBlobUrl(_css, 'text/css')
  const jsUrl = getBlobUrl(_js, 'text/javascript')
  const src = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        ${_css && `<link rel="stylesheet" type="text/css" href="${cssUrl}" />`}
      </head>
      <body>
        ${html || ''}
        ${_js && `<script src="${jsUrl}"></script>`}
      </body>
    </html>
  `
  return getBlobUrl(src, 'text/html')
}

export const isInViewPort = (el: HTMLElement) => {
  if (el && el.getBoundingClientRect) {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  return false
}

export const scrollToId = (sectionId: string) => {
  try {
    const element = document.querySelector(sectionId)
    if (element !== null) {
      const y = element.getBoundingClientRect().top + window.scrollY
      window.scroll({
        top: y - 150,
        behavior: 'smooth',
      })
    }
  } catch (e) {
    console.log('error scrolling document: ', e)
  }
}

export const getCurrentUserPosition = () =>
  new Promise((resolve, reject) => {
    const {
      navigator: { geolocation },
    } = window
    if (geolocation) {
      geolocation.getCurrentPosition(
        (position) => resolve(position),
        (err) => reject(err),
      )
    } else {
      reject(Error('Geolocation is not supported by this browser.'))
    }
  })
