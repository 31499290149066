import { IconType } from '../../types/icons.type'

const LinkedinIcon = ({ size = '48', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.98214 23.9995H1.00643V7.97625H5.98214V23.9995ZM3.49161 5.79054C1.90054 5.79054 0.61 4.47268 0.61 2.88161C0.61 2.11736 0.913597 1.38441 1.454 0.844003C1.99441 0.303597 2.72736 0 3.49161 0C4.25586 0 4.9888 0.303597 5.52921 0.844003C6.06962 1.38441 6.37321 2.11736 6.37321 2.88161C6.37321 4.47268 5.08214 5.79054 3.49161 5.79054ZM24.6046 23.9995H19.6396V16.1995C19.6396 14.3405 19.6021 11.9566 17.0527 11.9566C14.4657 11.9566 14.0693 13.9762 14.0693 16.0655V23.9995H9.09893V7.97625H13.8711V10.162H13.9407C14.605 8.90304 16.2277 7.57446 18.6486 7.57446C23.6843 7.57446 24.61 10.8905 24.61 15.1977V23.9995H24.6046Z'
        fill={fillColor}
      />
    </svg>
  )
}

export default LinkedinIcon
