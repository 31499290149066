import { ForgotPasswordDataType } from '../../../types/auth.type'
import { Validations } from '../../hooks/use-form'
import {
  emailValidation,
  min8DigitRegex,
  oneNumericCharacterRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from './regex.validation'

export const changePasswordValidations: Validations<ForgotPasswordDataType> = {
  token: {
    custom: {
      isValid: (value: string) => value.length > 0,
      message:
        'ER::INVALID_RESET_PAYLOAD: Please request a new reset link, or check your email again.',
    },
  },
  email: {
    pattern: {
      value: emailValidation,
      message:
        'ER::INVALID_RESET_PAYLOAD: Please request a new reset link, or check your email again.',
    },
  },
  password: {
    custom: {
      isValid: (value: string) => {
        if (
          !specialCharacterRegex.test(value) ||
          !upperCaseRegex.test(value) ||
          !min8DigitRegex.test(value) ||
          !oneNumericCharacterRegex.test(value)
        ) {
          return false
        }

        return true
      },
      message: 'Please valid all password requirements listed above.',
    },
  },
  confirmPassword: {
    custom: {
      isValid: (value: string) => {
        if (
          !specialCharacterRegex.test(value) ||
          !upperCaseRegex.test(value) ||
          !min8DigitRegex.test(value) ||
          !oneNumericCharacterRegex.test(value)
        ) {
          return false
        }

        return true
      },
      message: 'Passwords must match.',
    },
  },
}
