import FooterOrganism from '../../../../components/organisms/footer/Footer.organism'
import NavbarOrganism from '../../../../components/organisms/navbar/Navbar.organism'

import EventHeroTemplate from '../../components/templates/event/EventHero.template'

const CertificationEventPage = () => {
  return (
    <div>
      <NavbarOrganism />

      <EventHeroTemplate />

      <FooterOrganism />
    </div>
  )
}

export default CertificationEventPage
