import { useCallback, useEffect, useRef, useState } from 'react'
import { AiFillCloseCircle, AiOutlineFileSearch, AiOutlineUser } from 'react-icons/ai'

import globals from '../../../../../../global.module.css'
import styles from './mentor.module.css'

import ButtonAtom from '../../../../../../components/atoms/button/Button.atom'
import {
  ButtonLooks,
  ButtonTypes,
  IconDirection,
} from '../../../../../../components/atoms/button/enums'
import InputMolecule from '../../../../../../components/molecules/input/Input.molecule'
import SearchableFieldMolecule from '../../../../../../components/molecules/searchable-field/SearchableField.molecule'
import SectionHeadingMolecule from '../../../../../../components/molecules/section-heading/SectionHeading.molecule'
import UseOnClickOutside from '../../../../../../lib/hooks/detect-clickoutside.hook'
import { IconRepository } from '../../../../../../repository/icons.repository'

const CertificationMentorTemplate = () => {
  const [overlay, setOverlay] = useState(false)
  const [mentorForm, setMentorForm] = useState(false)

  const [email, setEmail] = useState('')
  const [extra, setExtra] = useState('')
  const [instantValue, setInstantValue] = useState('')
  const [list, setList] = useState<string[]>([])

  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    setOverlay(false)
    setMentorForm(false)
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  useEffect(() => {
    if (mentorForm === true) {
      setOverlay(true)
    }
  }, [mentorForm])

  return (
    <section
      className={`${styles.mentor} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']} ${globals['p-desktop']}`}
    >
      <div
        className={`${globals.flex} ${styles.content} ${globals['s-b']} ${globals['a-center']} ${globals['gap-64']}`}
      >
        <div className={styles.svg__illus}>
          <svg
            width='241'
            height='252'
            viewBox='0 0 241 252'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={styles.users}
          >
            <path
              d='M141.524 68.715C141.872 69.2505 142.069 69.8703 142.094 70.5087C142.119 71.1472 141.97 71.7804 141.664 72.3412C141.358 72.902 140.905 73.3694 140.355 73.6939C139.804 74.0185 139.176 74.188 138.537 74.1845H105.41C104.771 74.188 104.142 74.0185 103.592 73.6939C103.041 73.3694 102.589 72.902 102.283 72.3412C101.977 71.7804 101.828 71.1472 101.853 70.5087C101.877 69.8703 102.074 69.2505 102.423 68.715L113.845 50.9778C114.166 50.4781 114.607 50.067 115.129 49.7825C115.65 49.498 116.235 49.3492 116.829 49.3499H127.15C127.744 49.3492 128.329 49.498 128.85 49.7825C129.372 50.067 129.813 50.4781 130.134 50.9778L141.524 68.715Z'
              fill='#F0BB0A'
            />
            <path
              d='M121.975 59.9928C127.542 59.9152 133.055 61.0861 138.109 63.4192L130.118 50.9906C129.797 50.4909 129.356 50.0798 128.835 49.7953C128.313 49.5108 127.729 49.362 127.135 49.3627H116.813C116.219 49.362 115.635 49.5108 115.113 49.7953C114.592 50.0798 114.15 50.4909 113.83 50.9906L105.838 63.4192C110.894 61.0858 116.408 59.915 121.975 59.9928Z'
              fill='#F0BB0A'
            />
            <path
              d='M225.887 240.331C226.193 241.405 226.905 242.319 227.871 242.879C228.838 243.439 229.985 243.602 231.07 243.334C232.154 243.066 233.093 242.387 233.687 241.441C234.281 240.495 234.484 239.355 234.255 238.262L227.673 204.158L215.791 207.099L225.887 240.331Z'
              fill='#C0DCEB'
            />
            <path
              d='M230.903 220.943L227.666 204.165L215.783 207.105L219.99 220.943H230.903Z'
              fill='#ADC4D9'
            />
            <path
              d='M225.887 240.331C226.193 241.405 226.905 242.319 227.871 242.879C228.838 243.439 229.985 243.602 231.07 243.334C232.154 243.066 233.093 242.387 233.687 241.441C234.281 240.495 234.484 239.355 234.255 238.262L227.673 204.158L215.791 207.099L225.887 240.331Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M189.713 240.331C189.407 241.405 188.696 242.319 187.729 242.879C186.762 243.439 185.615 243.602 184.531 243.334C183.446 243.066 182.508 242.387 181.914 241.441C181.319 240.495 181.116 239.355 181.346 238.262L187.924 204.158L199.806 207.099L189.713 240.331Z'
              fill='#C0DCEB'
            />
            <path
              d='M195.606 220.943L199.812 207.105L187.93 204.165L184.692 220.943H195.606Z'
              fill='#ADC4D9'
            />
            <path
              d='M189.713 240.331C189.407 241.405 188.696 242.319 187.729 242.879C186.762 243.439 185.615 243.602 184.531 243.334C183.446 243.066 182.508 242.387 181.914 241.441C181.319 240.495 181.116 239.355 181.346 238.262L187.924 204.158L199.806 207.099L189.713 240.331Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M183.315 208.705H231.318C233.196 208.705 234.997 207.959 236.325 206.632C237.653 205.304 238.399 203.504 238.399 201.627V135.192C238.399 133.328 238.032 131.483 237.319 129.762C236.606 128.04 235.561 126.476 234.242 125.158C232.924 123.841 231.36 122.795 229.637 122.083C227.915 121.37 226.069 121.003 224.205 121.003H197.525C193.76 121.003 190.15 122.498 187.488 125.159C184.827 127.82 183.331 131.429 183.331 135.192L183.315 208.705Z'
              fill='#980ECB'
            />
            <path
              d='M224.21 121.009H197.526C193.761 121.009 190.151 122.504 187.49 125.165C184.828 127.826 183.332 131.435 183.332 135.198V147.861C183.332 145.998 183.699 144.152 184.412 142.43C185.125 140.708 186.17 139.144 187.488 137.825C188.806 136.507 190.371 135.462 192.093 134.748C193.815 134.034 195.661 133.667 197.526 133.666H224.239C228.003 133.666 231.613 135.161 234.275 137.822C236.937 140.483 238.432 144.092 238.432 147.855V135.192C238.432 133.326 238.063 131.479 237.348 129.757C236.633 128.034 235.584 126.469 234.263 125.152C232.942 123.834 231.374 122.791 229.649 122.08C227.924 121.369 226.076 121.005 224.21 121.009Z'
              fill='#980ECB'
            />
            <path
              d='M183.315 208.705H231.318C233.196 208.705 234.997 207.959 236.325 206.632C237.653 205.304 238.399 203.504 238.399 201.627V135.192C238.399 133.328 238.032 131.483 237.319 129.762C236.606 128.04 235.561 126.476 234.242 125.158C232.924 123.841 231.36 122.795 229.637 122.083C227.915 121.37 226.069 121.003 224.205 121.003H197.525C193.76 121.003 190.15 122.498 187.488 125.159C184.827 127.82 183.331 131.429 183.331 135.192L183.315 208.705Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M160.876 208.704H231.321C233.199 208.704 235 207.959 236.328 206.631C237.655 205.304 238.401 203.503 238.401 201.626V198.521H160.876V208.704Z'
              fill='#980ECB'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M175.158 148.537C175.153 153.133 173.323 157.54 170.072 160.79C166.82 164.04 162.412 165.868 157.813 165.872C153.311 165.881 148.944 164.334 145.451 161.494C141.958 158.654 139.553 154.695 138.645 150.287L132.587 125.452C132.257 123.613 132.662 121.717 133.714 120.173C134.767 118.628 136.383 117.557 138.217 117.191C140.05 116.825 141.954 117.191 143.52 118.213C145.085 119.234 146.188 120.828 146.591 122.653L152.648 147.491C152.882 148.646 153.507 149.684 154.418 150.431C155.33 151.178 156.471 151.588 157.649 151.59C158.063 151.604 158.475 151.536 158.861 151.389C159.248 151.242 159.601 151.02 159.901 150.735C160.2 150.45 160.44 150.109 160.606 149.73C160.772 149.352 160.861 148.944 160.868 148.53C160.868 146.637 161.62 144.821 162.96 143.482C164.299 142.143 166.115 141.391 168.009 141.391C169.904 141.391 171.72 142.143 173.059 143.482C174.399 144.821 175.151 146.637 175.151 148.53L175.158 148.537Z'
              fill='#FFCEBF'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M156.794 231.139L161.573 192.91C161.941 189.951 163.377 187.229 165.612 185.254C167.848 183.28 170.727 182.19 173.71 182.19H201.669V198.502H179.236L171.077 231.142L156.794 231.139Z'
              fill='#656769'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M215.963 157.845C223.043 155.869 228.836 149.859 228.836 136.289C228.836 121.656 211.154 110.794 195.554 110.794C190.999 110.795 186.489 111.693 182.281 113.437C178.073 115.18 174.249 117.735 171.029 120.956C167.809 124.176 165.255 128 163.513 132.207C161.771 136.414 160.875 140.924 160.876 145.477V149.557L171.075 159.756V182.19H215.963V157.845Z'
              fill='#4ACFFF'
            />
            <path
              d='M195.561 121.01C209.664 121.01 225.421 129.899 228.324 142.414C228.665 140.394 228.838 138.35 228.843 136.302C228.843 121.669 211.161 110.807 195.561 110.807C186.362 110.807 177.541 114.46 171.036 120.962C164.531 127.464 160.877 136.282 160.876 145.477V149.557L161.372 150.052C162.702 141.946 166.869 134.575 173.132 129.255C179.394 123.936 187.343 121.013 195.561 121.01Z'
              fill='#80DDFF'
            />
            <path
              d='M215.963 157C223.043 155.024 228.836 149.859 228.836 136.289C228.836 121.656 211.154 110.794 195.554 110.794C190.999 110.795 186.489 111.693 182.281 113.437C178.073 115.18 174.249 117.735 171.029 120.956C167.809 124.176 165.255 128 163.513 132.207C161.771 136.414 160.875 140.924 160.876 145.477V149.557L171.075 159.756V182.19H215.963V157Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M215.924 137.318C215.924 140.173 212.114 142.446 209.809 143.016V159.755C211.913 159.775 214.009 159.455 216.037 158.804'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              opacity='0.15'
              d='M134.352 245.418C134.352 246.221 135.724 247.017 138.39 247.759C141.056 248.502 144.963 249.176 149.889 249.745C154.815 250.313 160.663 250.764 167.099 251.071C173.534 251.379 180.432 251.537 187.398 251.537C194.365 251.537 201.262 251.379 207.698 251.071C214.134 250.764 219.982 250.313 224.908 249.745C229.834 249.176 233.741 248.502 236.407 247.759C239.072 247.017 240.445 246.221 240.445 245.418C240.445 244.614 239.072 243.818 236.407 243.076C233.741 242.333 229.834 241.659 224.908 241.091C219.982 240.522 214.134 240.072 207.698 239.764C201.262 239.457 194.365 239.298 187.398 239.298C180.432 239.298 173.534 239.457 167.099 239.764C160.663 240.072 154.815 240.522 149.889 241.091C144.963 241.659 141.056 242.333 138.39 243.076C135.724 243.818 134.352 244.614 134.352 245.418Z'
              fill='#45413C'
            />
            <path
              d='M142.511 243.378C142.511 240.135 143.8 237.025 146.093 234.733C148.387 232.44 151.498 231.152 154.741 231.152H171.062V243.378H142.511Z'
              fill='#00B8F0'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M156.794 243.378C156.794 240.135 158.083 237.025 160.376 234.733C162.67 232.44 165.78 231.152 169.024 231.152H185.345V239.311C185.345 239.847 185.239 240.377 185.034 240.872C184.829 241.367 184.528 241.817 184.149 242.196C183.77 242.574 183.321 242.875 182.825 243.08C182.33 243.285 181.8 243.391 181.264 243.391L156.794 243.378Z'
              fill='#00B8F0'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M203.742 111.779C202.966 111.594 202.275 111.153 201.782 110.526C201.288 109.899 201.021 109.124 201.023 108.327V92.3592H186.076V109.569C186.077 110.299 185.852 111.012 185.431 111.609C185.01 112.207 184.415 112.659 183.727 112.905C181.896 113.56 180.124 114.372 178.433 115.331L189.456 125.083L205.87 112.358C205.164 112.144 204.455 111.951 203.742 111.779Z'
              fill='#FFCEBF'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M217.518 117.692L195.558 131.199L189.437 125.083L205.848 112.358'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M178.442 115.315L189.455 125.073L185.822 128.699C185.126 129.393 184.204 129.813 183.224 129.883C182.244 129.953 181.272 129.667 180.486 129.079L170.454 121.554C172.797 119.095 175.489 116.994 178.442 115.315Z'
              fill='#80DDFF'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M171.072 159.756V139.358'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M189.437 125.083V182.19'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M191.566 48.3783C198.053 48.3783 204.275 50.9545 208.862 55.54C213.449 60.1256 216.026 66.345 216.026 72.83V77.7204C223.01 77.7204 222.531 89.9463 214.803 89.9463H214.784C213.045 95.5179 209.364 100.281 204.411 103.371C199.458 106.461 193.56 107.672 187.789 106.784C182.019 105.897 176.758 102.97 172.963 98.5345C169.167 94.0994 167.089 88.4505 167.106 82.614V72.8333C167.106 69.6219 167.738 66.442 168.967 63.475C170.196 60.508 171.998 57.8121 174.269 55.5412C176.54 53.2703 179.237 51.4689 182.205 50.2399C185.173 49.0109 188.354 48.3783 191.566 48.3783Z'
              fill='#FFCEBF'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M192.79 78.3315C192.79 79.1421 193.112 79.9196 193.685 80.4928C194.258 81.066 195.036 81.388 195.847 81.388C196.658 81.388 197.436 81.066 198.009 80.4928C198.582 79.9196 198.905 79.1421 198.905 78.3315C198.905 77.5209 198.582 76.7434 198.009 76.1702C197.436 75.597 196.658 75.275 195.847 75.275C195.036 75.275 194.258 75.597 193.685 76.1702C193.112 76.7434 192.79 77.5209 192.79 78.3315Z'
              fill='#45413C'
            />
            <path
              d='M174.444 78.3315C174.444 78.936 174.624 79.527 174.96 80.0296C175.296 80.5322 175.773 80.924 176.332 81.1553C176.891 81.3866 177.505 81.4472 178.098 81.3292C178.692 81.2113 179.236 80.9202 179.664 80.4927C180.092 80.0653 180.383 79.5207 180.501 78.9278C180.619 78.3349 180.558 77.7203 180.327 77.1618C180.095 76.6033 179.703 76.126 179.201 75.7901C178.698 75.4543 178.107 75.275 177.502 75.275C176.691 75.275 175.913 75.597 175.34 76.1702C174.767 76.7434 174.444 77.5209 174.444 78.3315Z'
              fill='#45413C'
            />
            <path
              d='M198.904 88.112C198.904 88.5956 199.047 89.0684 199.316 89.4705C199.585 89.8726 199.967 90.186 200.414 90.3711C200.861 90.5561 201.352 90.6046 201.827 90.5102C202.301 90.4159 202.737 90.183 203.079 89.841C203.421 89.4991 203.654 89.0634 203.749 88.5891C203.843 88.1147 203.795 87.6231 203.61 87.1763C203.424 86.7295 203.111 86.3476 202.709 86.079C202.306 85.8103 201.834 85.6669 201.35 85.6669C200.701 85.6669 200.079 85.9245 199.62 86.383C199.161 86.8416 198.904 87.4635 198.904 88.112Z'
              fill='#FFB59E'
            />
            <path
              d='M192.789 92.16C191.096 93.6409 188.923 94.4572 186.674 94.4572C184.424 94.4572 182.251 93.6409 180.559 92.16'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M208.718 80.1656C208.718 78.8811 208.465 77.6093 207.973 76.4227C207.482 75.236 206.761 74.1578 205.852 73.2496C204.944 72.3414 203.865 71.6209 202.678 71.1294C201.491 70.6379 200.219 70.3849 198.934 70.3849H167.072C166.468 70.3896 165.872 70.2597 165.325 70.0048C164.779 69.7498 164.296 69.3762 163.912 68.911C163.528 68.4459 163.253 67.9011 163.106 67.3162C162.96 66.7312 162.945 66.121 163.065 65.5299C164.546 58.1069 168.739 51.5014 174.827 47.001C180.916 42.5005 188.462 40.4276 195.996 41.1864C203.529 41.9452 210.51 45.4812 215.578 51.1052C220.646 56.7293 223.437 64.0383 223.407 71.6075V107.452C223.402 108.073 223.256 108.685 222.98 109.242C222.705 109.798 222.307 110.285 221.816 110.666C221.325 111.047 220.754 111.312 220.146 111.44C219.538 111.569 218.909 111.558 218.306 111.409C215.629 110.819 213.23 109.346 211.493 107.227C209.756 105.108 208.783 102.466 208.731 99.727L208.718 80.1656Z'
              fill='#980ECB'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M194.045 50.8234C188.182 50.8424 182.392 52.1205 177.065 54.5712C171.739 57.0219 167.002 60.5879 163.174 65.0279C164.582 58.5105 168.092 52.6359 173.166 48.3075C178.239 43.979 184.594 41.4365 191.254 41.071C197.914 40.7054 204.509 42.5371 210.026 46.2842C215.543 50.0313 219.675 55.4865 221.788 61.8106C214.264 54.7713 204.351 50.8451 194.045 50.8234Z'
              fill='#980ECB'
            />
            <path
              d='M208.718 80.1656C208.718 78.8811 208.465 77.6093 207.973 76.4227C207.482 75.236 206.761 74.1578 205.852 73.2496C204.944 72.3414 203.865 71.6209 202.678 71.1294C201.491 70.6379 200.219 70.3849 198.934 70.3849H167.072C166.468 70.3896 165.872 70.2597 165.325 70.0048C164.779 69.7498 164.296 69.3762 163.912 68.911C163.528 68.4459 163.253 67.9011 163.106 67.3162C162.96 66.7312 162.945 66.121 163.065 65.5299C164.546 58.1069 168.739 51.5014 174.827 47.001C180.916 42.5005 188.462 40.4276 195.996 41.1864C203.529 41.9452 210.51 45.4812 215.578 51.1052C220.646 56.7293 223.437 64.0383 223.407 71.6075V107.452C223.402 108.073 223.256 108.685 222.98 109.242C222.705 109.798 222.307 110.285 221.816 110.666C221.325 111.047 220.754 111.312 220.146 111.44C219.538 111.569 218.909 111.558 218.306 111.409C215.629 110.819 213.23 109.346 211.493 107.227C209.756 105.108 208.783 102.466 208.731 99.727L208.718 80.1656Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinejoin='round'
            />
            <path
              d='M191.597 70.3846V60.6039'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M201.404 70.7224L201.382 60.6039'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M181.81 70.3846V60.6039'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M172.028 70.3846V60.6039'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M171.072 231.139L175.855 192.91C176.222 189.95 177.66 187.226 179.897 185.251C182.134 183.277 185.017 182.188 188.001 182.19H215.959V190.346C215.959 191.418 215.748 192.478 215.338 193.468C214.928 194.458 214.327 195.357 213.569 196.114C212.811 196.872 211.911 197.473 210.921 197.882C209.93 198.292 208.869 198.503 207.798 198.502H199.89C198.07 198.502 196.302 199.11 194.867 200.23C193.432 201.35 192.413 202.917 191.973 204.683L185.355 231.139H171.072Z'
              fill='#656769'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M14.2491 240.331C13.9427 241.405 13.2312 242.319 12.2644 242.879C11.2976 243.439 10.1511 243.602 9.0663 243.334C7.9816 243.066 7.0433 242.387 6.4492 241.441C5.855 240.495 5.6514 239.355 5.8812 238.262L12.4629 204.158L24.3453 207.099L14.2491 240.331Z'
              fill='#C0DCEB'
            />
            <path
              d='M20.144 220.943L24.3505 207.105L12.4681 204.165L9.2303 220.943H20.144Z'
              fill='#ADC4D9'
            />
            <path
              d='M14.2491 240.331C13.9427 241.405 13.2312 242.319 12.2644 242.879C11.2976 243.439 10.1511 243.602 9.0663 243.334C7.9816 243.066 7.0433 242.387 6.4492 241.441C5.855 240.495 5.6514 239.355 5.8812 238.262L12.4629 204.158L24.3453 207.099L14.2491 240.331Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M50.4191 240.331C50.7255 241.405 51.437 242.319 52.4038 242.879C53.3706 243.439 54.5171 243.602 55.6019 243.334C56.6866 243.066 57.6249 242.387 58.2191 241.441C58.8132 240.495 59.0168 239.355 58.787 238.262L52.2086 204.158L40.3261 207.099L50.4191 240.331Z'
              fill='#C0DCEB'
            />
            <path
              d='M55.4413 220.943L52.2036 204.165L40.3212 207.105L44.5276 220.943H55.4413Z'
              fill='#ADC4D9'
            />
            <path
              d='M50.4191 240.331C50.7255 241.405 51.437 242.319 52.4038 242.879C53.3706 243.439 54.5171 243.602 55.6019 243.334C56.6866 243.066 57.6249 242.387 58.2191 241.441C58.8132 240.495 59.0168 239.355 58.787 238.262L52.2086 204.158L40.3261 207.099L50.4191 240.331Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M56.8195 208.705H8.82959C6.95169 208.705 5.15069 207.959 3.82289 206.632C2.49499 205.304 1.74899 203.504 1.74899 201.627V135.192C1.74859 133.328 2.11539 131.483 2.82859 129.762C3.54169 128.04 4.58709 126.476 5.90519 125.158C7.22319 123.841 8.78809 122.795 10.5103 122.083C12.2325 121.37 14.0783 121.003 15.9423 121.003H42.6552C44.5192 121.003 46.3651 121.37 48.0873 122.083C49.8095 122.795 51.3743 123.841 52.6923 125.158C54.0104 126.476 55.0558 128.04 55.769 129.762C56.4821 131.483 56.8489 133.328 56.8485 135.192L56.8195 208.705Z'
              fill='#980ECB'
            />
            <path
              d='M42.6256 121.01H15.9127C14.0487 121.009 12.2029 121.376 10.4807 122.089C8.75851 122.802 7.19361 123.847 5.87561 125.164C4.55751 126.482 3.51211 128.046 2.79901 129.768C2.08581 131.489 1.71901 133.335 1.71941 135.198V147.861C1.71941 144.098 3.21481 140.49 5.87651 137.829C8.53831 135.168 12.1484 133.673 15.9127 133.673H42.6256C46.3899 133.673 50 135.168 52.6618 137.829C55.3236 140.49 56.8189 144.098 56.8189 147.861V135.192C56.8185 133.329 56.451 131.484 55.7375 129.763C55.024 128.043 53.9784 126.479 52.6605 125.162C51.3425 123.845 49.778 122.801 48.0562 122.088C46.3344 121.376 44.4891 121.009 42.6256 121.01Z'
              fill='#980ECB'
            />
            <path
              d='M56.8195 208.705H8.82959C6.95169 208.705 5.15069 207.959 3.82289 206.632C2.49499 205.304 1.74899 203.504 1.74899 201.627V135.192C1.74859 133.328 2.11539 131.483 2.82859 129.762C3.54169 128.04 4.58709 126.476 5.90519 125.158C7.22319 123.841 8.78809 122.795 10.5103 122.083C12.2325 121.37 14.0783 121.003 15.9423 121.003H42.6552C44.5192 121.003 46.3651 121.37 48.0873 122.083C49.8095 122.795 51.3743 123.841 52.6923 125.158C54.0104 126.476 55.0558 128.04 55.769 129.762C56.4821 131.483 56.8489 133.328 56.8485 135.192L56.8195 208.705Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M79.2617 208.704H8.82959C6.95169 208.704 5.15069 207.959 3.82289 206.631C2.49499 205.304 1.74899 203.503 1.74899 201.626V198.521H79.2617V208.704Z'
              fill='#980ECB'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M107.824 159.756H90.483C89.672 159.754 88.894 159.431 88.321 158.858C87.747 158.284 87.424 157.507 87.422 156.696C87.413 156.354 87.378 156.013 87.319 155.676H73.245C73.1834 156.013 73.1479 156.354 73.1388 156.696C73.1439 161.293 74.973 165.699 78.2246 168.949C81.4763 172.199 85.885 174.027 90.483 174.031H107.824C108.774 174.051 109.719 173.882 110.603 173.532C111.487 173.182 112.292 172.66 112.971 171.995C113.651 171.33 114.19 170.537 114.559 169.661C114.927 168.784 115.117 167.844 115.117 166.893C115.117 165.943 114.927 165.002 114.559 164.126C114.19 163.25 113.651 162.457 112.971 161.792C112.292 161.127 111.487 160.605 110.603 160.255C109.719 159.905 108.774 159.736 107.824 159.756Z'
              fill='#D18660'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M146.593 165.872H91.506L97.621 129.159C97.621 128.623 97.727 128.093 97.932 127.598C98.137 127.104 98.438 126.654 98.817 126.275C99.196 125.897 99.646 125.597 100.141 125.392C100.636 125.187 101.166 125.082 101.702 125.083H152.714C153.796 125.083 154.833 125.512 155.599 126.276C156.364 127.041 156.794 128.078 156.795 129.159L150.68 161.793C150.68 162.329 150.574 162.86 150.369 163.355C150.163 163.851 149.862 164.301 149.483 164.679C149.103 165.058 148.652 165.359 148.156 165.563C147.661 165.768 147.129 165.873 146.593 165.872Z'
              fill='#C0DCEB'
            />
            <path
              d='M154.114 131.199C154.876 131.211 155.625 131.393 156.309 131.73L156.792 129.156C156.791 128.074 156.36 127.038 155.595 126.273C154.83 125.509 153.792 125.079 152.711 125.079H101.705C101.169 125.079 100.639 125.184 100.143 125.389C99.648 125.593 99.198 125.894 98.819 126.272C98.44 126.651 98.14 127.1 97.935 127.595C97.73 128.09 97.624 128.62 97.624 129.156L97.283 131.196L154.114 131.199Z'
              fill='#DAEDF7'
            />
            <path
              d='M146.593 165.872H91.506L97.621 129.159C97.621 128.623 97.727 128.093 97.932 127.598C98.137 127.104 98.438 126.654 98.817 126.275C99.196 125.897 99.646 125.597 100.141 125.392C100.636 125.187 101.166 125.082 101.702 125.083H152.714C153.796 125.083 154.833 125.512 155.599 126.276C156.364 127.041 156.794 128.078 156.795 129.159L150.68 161.793C150.68 162.329 150.574 162.86 150.369 163.355C150.163 163.851 149.862 164.301 149.483 164.679C149.103 165.058 148.652 165.359 148.156 165.563C147.661 165.768 147.129 165.873 146.593 165.872Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M97.624 231.139H79.2592L74.3865 205.162C74.036 203.292 73.0425 201.603 71.5779 200.387C70.1133 199.171 68.2698 198.506 66.3662 198.505H48.6648V182.19H79.0854C81.9838 182.188 84.789 183.214 87 185.087C89.212 186.96 90.687 189.557 91.161 192.415L97.624 231.139Z'
              fill='#00B8F0'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.8882 149.557C9.8882 144.468 10.8909 139.429 12.839 134.727C14.7872 130.025 17.6427 125.753 21.2424 122.155C24.8422 118.556 29.1157 115.702 33.819 113.754C38.5223 111.807 43.5633 110.804 48.6541 110.804C53.7449 110.804 58.7859 111.807 63.4892 113.754C68.1925 115.702 72.466 118.556 76.0658 122.155C79.6655 125.753 82.521 130.025 84.469 134.727C86.417 139.429 87.42 144.468 87.42 149.557V155.67H77.2177V182.184H24.1715L24.1554 155.67H9.8882V149.557Z'
              fill='#E8F4FA'
            />
            <path
              d='M48.6541 110.804C38.3727 110.804 28.5125 114.887 21.2424 122.155C13.9724 129.422 9.8882 139.279 9.8882 149.557V155.67H10.107C11.1154 146.153 15.6113 137.346 22.7284 130.945C29.8456 124.544 39.0805 121.002 48.6541 121.002C58.2277 121.002 67.4627 124.544 74.5798 130.945C81.697 137.346 86.193 146.153 87.201 155.67H87.42V149.557C87.42 139.279 83.336 129.422 76.0658 122.155C68.7958 114.887 58.9355 110.804 48.6541 110.804Z'
              fill='white'
            />
            <path
              d='M9.8882 149.557C9.8882 144.468 10.8909 139.429 12.839 134.727C14.7872 130.025 17.6427 125.753 21.2424 122.155C24.8422 118.556 29.1157 115.702 33.819 113.754C38.5223 111.807 43.5633 110.804 48.6541 110.804C53.7449 110.804 58.7859 111.807 63.4892 113.754C68.1925 115.702 72.466 118.556 76.0658 122.155C79.6655 125.753 82.521 130.025 84.469 134.727C86.417 139.429 87.42 144.468 87.42 149.557V155.67H77.2177V182.184H24.1715L24.1554 155.67H9.8882V149.557Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              opacity='0.15'
              d='M3.77139 245.418C3.77139 247.041 9.36019 248.597 19.3083 249.745C29.2564 250.892 42.7488 251.537 56.8176 251.537C70.8863 251.537 84.379 250.892 94.327 249.745C104.275 248.597 109.864 247.041 109.864 245.418C109.864 243.795 104.275 242.238 94.327 241.091C84.379 239.943 70.8863 239.298 56.8176 239.298C42.7488 239.298 29.2564 239.943 19.3083 241.091C9.36019 242.238 3.77139 243.795 3.77139 245.418Z'
              fill='#45413C'
            />
            <path
              d='M24.176 155.676V143.438'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M77.2207 155.676V143.438'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M107.824 243.378C107.824 240.135 106.535 237.025 104.242 234.733C101.948 232.44 98.838 231.152 95.594 231.152H79.2604V243.378H107.824Z'
              fill='#1E1E1E'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M83.343 243.378C83.343 240.135 82.054 237.025 79.7604 234.733C77.4669 232.44 74.3561 231.152 71.1125 231.152H54.779V239.311C54.779 240.393 55.209 241.431 55.9743 242.196C56.7396 242.961 57.7776 243.391 58.86 243.391L83.343 243.378Z'
              fill='#1E1E1E'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M146.593 174.031H95.587C94.505 174.031 93.467 173.601 92.701 172.836C91.936 172.071 91.506 171.034 91.506 169.952V165.872H150.674V169.952C150.674 171.034 150.244 172.071 149.478 172.836C148.713 173.601 147.675 174.031 146.593 174.031Z'
              fill='#C0DCEB'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M118.025 147.517C118.025 149.14 118.67 150.697 119.818 151.844C120.966 152.992 122.523 153.636 124.146 153.636C125.77 153.636 127.327 152.992 128.475 151.844C129.623 150.697 130.268 149.14 130.268 147.517C130.268 145.894 129.623 144.338 128.475 143.19C127.327 142.042 125.77 141.398 124.146 141.398C122.523 141.398 120.966 142.042 119.818 143.19C118.67 144.338 118.025 145.894 118.025 147.517Z'
              fill='#980ECB'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M124.149 137.318L128.23 133.239'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M73.1411 231.139H54.7961L49.9234 205.162C49.5728 203.292 48.5793 201.603 47.1148 200.387C45.6502 199.171 43.8066 198.506 41.9031 198.505H32.3379C31.2662 198.506 30.2049 198.295 29.2147 197.885C28.2245 197.476 27.3247 196.875 26.5667 196.118C25.8088 195.36 25.2075 194.461 24.7973 193.471C24.3871 192.482 24.176 191.421 24.176 190.349V182.19H54.6287C57.5265 182.189 60.3308 183.215 62.5418 185.088C64.7527 186.961 66.2267 189.557 66.7011 192.415L73.1411 231.139Z'
              fill='#00B8F0'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M187.395 179.131C187.395 180.483 186.857 181.78 185.901 182.737C184.944 183.693 183.646 184.23 182.293 184.23H41.5129C40.16 184.23 38.8625 183.693 37.9059 182.737C36.9492 181.78 36.4117 180.483 36.4117 179.131C36.4117 177.778 36.9492 176.481 37.9059 175.525C38.8625 174.569 40.16 174.031 41.5129 174.031H182.293C183.646 174.031 184.944 174.569 185.901 175.525C186.857 176.481 187.395 177.778 187.395 179.131Z'
              fill='#B89558'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M62.9403 165.872C62.9399 166.944 63.1507 168.005 63.5607 168.995C63.9708 169.985 64.5719 170.885 65.3299 171.642C66.0879 172.4 66.9879 173.001 67.9783 173.411C68.9687 173.821 70.0303 174.032 71.1022 174.031H91.504V165.872H62.9403Z'
              fill='#C0DCEB'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M59.8768 159.756H27.2323C26.421 159.754 25.6435 159.431 25.0699 158.858C24.4963 158.284 24.1732 157.507 24.1715 156.696V155.676H9.8882V156.696C9.8933 161.293 11.7223 165.699 14.974 168.949C18.2256 172.199 22.6342 174.027 27.2323 174.031H59.8768C60.8272 174.051 61.7721 173.882 62.656 173.532C63.5399 173.182 64.3451 172.66 65.0244 171.995C65.7037 171.33 66.2434 170.537 66.6118 169.661C66.9803 168.784 67.1701 167.844 67.1701 166.893C67.1701 165.943 66.9803 165.002 66.6118 164.126C66.2434 163.25 65.7037 162.457 65.0244 161.792C64.3451 161.127 63.5399 160.605 62.656 160.255C61.7721 159.905 60.8272 159.736 59.8768 159.756Z'
              fill='#D18660'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M121.976 1.46301V40.4828'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M141.524 68.715C141.872 69.2505 142.069 69.8703 142.094 70.5087C142.119 71.1472 141.97 71.7804 141.664 72.3412C141.358 72.902 140.905 73.3694 140.355 73.6939C139.804 74.0185 139.176 74.188 138.537 74.1845H105.41C104.771 74.188 104.142 74.0185 103.592 73.6939C103.041 73.3694 102.589 72.902 102.283 72.3412C101.977 71.7804 101.828 71.1472 101.853 70.5087C101.877 69.8703 102.074 69.2505 102.423 68.715L113.845 50.9778C114.166 50.4781 114.607 50.067 115.129 49.7825C115.65 49.498 116.235 49.3492 116.829 49.3499H127.15C127.744 49.3492 128.329 49.498 128.85 49.7825C129.372 50.067 129.813 50.4781 130.134 50.9778L141.524 68.715Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M127.298 49.35H116.654V45.8109C116.637 45.1013 116.762 44.3953 117.021 43.7346C117.281 43.0739 117.67 42.4718 118.166 41.9638C118.662 41.4557 119.254 41.0519 119.909 40.7763C120.563 40.5006 121.266 40.3586 121.976 40.3586C122.686 40.3586 123.389 40.5006 124.043 40.7763C124.698 41.0519 125.29 41.4557 125.786 41.9638C126.282 42.4718 126.671 43.0739 126.931 43.7346C127.19 44.3953 127.315 45.1013 127.298 45.8109V49.35Z'
              fill='#1E1E1E'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M105.782 184.23H118.024V243.377H105.782V184.23Z' fill='#87898C' />
            <path d='M105.782 184.23H118.024V192.389H105.782V184.23Z' fill='#656769' />
            <path
              d='M105.782 184.23H118.024V243.377H105.782V184.23Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M61.8286 113.159C61.1324 112.917 60.5291 112.464 60.1027 111.863C59.6763 111.262 59.4482 110.543 59.4502 109.807V97.4907H41.8389V108.571C41.84 109.362 41.5758 110.131 41.0887 110.754C40.6016 111.378 39.9196 111.82 39.1516 112.011C38.2955 112.223 37.449 112.464 36.6187 112.738C37.0562 116.024 38.6288 119.053 41.0645 121.303C43.5001 123.552 46.6456 124.88 49.9569 125.056C53.2682 125.232 56.537 124.246 59.1979 122.268C61.8588 120.29 63.7445 117.445 64.5289 114.224C63.6374 113.828 62.7426 113.478 61.8286 113.159Z'
              fill='#D18660'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M50.697 49.8423C44.1688 49.8423 37.908 52.4347 33.2919 57.0493C28.6758 61.6638 26.0825 67.9225 26.0825 74.4485V77.7301C24.8842 77.9521 23.8075 78.6018 23.0528 79.5584C22.2982 80.5149 21.9171 81.713 21.9805 82.9296C22.0439 84.1462 22.5474 85.2983 23.3974 86.1713C24.2474 87.0443 25.3858 87.5787 26.6006 87.6749C27.8439 93.6359 31.254 98.9244 36.1715 102.518C41.0891 106.111 47.1648 107.754 53.2239 107.129C59.2829 106.504 64.8949 103.655 68.9747 99.1334C73.0545 94.6118 75.3123 88.7386 75.3115 82.6495V74.4485C75.3115 67.9225 72.7182 61.6638 68.1021 57.0493C63.486 52.4347 57.2252 49.8423 50.697 49.8423Z'
              fill='#D18660'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M44.5435 77.5238C44.5435 78.3396 44.8677 79.1219 45.4447 79.6987C46.0217 80.2756 46.8043 80.5996 47.6203 80.5996C48.4364 80.5996 49.219 80.2756 49.796 79.6987C50.373 79.1219 50.6972 78.3396 50.6972 77.5238C50.6972 76.7081 50.373 75.9258 49.796 75.3489C49.219 74.7721 48.4364 74.4481 47.6203 74.4481C46.8043 74.4481 46.0217 74.7721 45.4447 75.3489C44.8677 75.9258 44.5435 76.7081 44.5435 77.5238Z'
              fill='#45413C'
            />
            <path
              d='M60.9548 77.5238C60.9548 78.3396 61.2789 79.1219 61.856 79.6987C62.433 80.2756 63.2156 80.5996 64.0316 80.5996C64.8476 80.5996 65.6302 80.2756 66.2072 79.6987C66.7843 79.1219 67.1084 78.3396 67.1084 77.5238C67.1084 76.7081 66.7843 75.9258 66.2072 75.3489C65.6302 74.7721 64.8476 74.4481 64.0316 74.4481C63.2156 74.4481 62.433 74.7721 61.856 75.3489C61.2789 75.9258 60.9548 76.7081 60.9548 77.5238Z'
              fill='#45413C'
            />
            <path
              d='M36.3428 85.7248C36.3428 86.5406 36.667 87.3229 37.244 87.8997C37.821 88.4765 38.6036 88.8006 39.4196 88.8006C40.2357 88.8006 41.0183 88.4765 41.5953 87.8997C42.1723 87.3229 42.4965 86.5406 42.4965 85.7248C42.4965 84.9091 42.1723 84.1267 41.5953 83.5499C41.0183 82.9731 40.2357 82.649 39.4196 82.649C38.6036 82.649 37.821 82.9731 37.244 83.5499C36.667 84.1267 36.3428 84.9091 36.3428 85.7248Z'
              fill='#BD704A'
            />
            <path
              d='M48.6475 93.0897C50.4697 94.3045 52.6109 94.9528 54.8012 94.9528C56.9914 94.9528 59.1326 94.3045 60.9548 93.0897'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M38.3894 45.7399H56.8503C57.938 45.7399 58.9813 45.3082 59.7507 44.5396C60.5202 43.771 60.9529 42.7284 60.9538 41.641H67.1074C70.2588 41.634 73.2927 42.836 75.5837 44.9992C77.8747 47.1624 79.248 50.1219 79.4205 53.2675C79.593 56.4131 78.5516 59.5049 76.5109 61.9056C74.4703 64.3062 71.586 65.8326 68.4527 66.17C67.8718 68.3463 66.6514 70.2984 64.9491 71.7741C63.2468 73.2498 61.141 74.1813 58.9036 74.4482H50.6966C52.7564 74.194 54.6666 73.241 56.1082 71.7484C57.5497 70.2558 58.4354 68.314 58.6172 66.2472H38.3894L26.0821 78.5406H21.9786V62.1322C21.9829 57.7838 23.7136 53.6149 26.7907 50.5413C29.8678 47.4677 34.0395 45.7408 38.3894 45.7399Z'
              fill='#724F3D'
            />
            <path
              d='M38.3894 45.7399H56.8503C57.938 45.7399 58.9813 45.3082 59.7507 44.5396C60.5202 43.771 60.9529 42.7284 60.9538 41.641H67.1074C70.2588 41.634 73.2927 42.836 75.5837 44.9992C77.8747 47.1624 79.248 50.1219 79.4205 53.2675C79.593 56.4131 78.5516 59.5049 76.5109 61.9056C74.4703 64.3062 71.586 65.8326 68.4527 66.17C67.8718 68.3463 66.6514 70.2984 64.9491 71.7741C63.2468 73.2498 61.141 74.1813 58.9036 74.4482H50.6966C52.7564 74.194 54.6666 73.241 56.1082 71.7484C57.5497 70.2558 58.4354 68.314 58.6172 66.2472H38.3894L26.0821 78.5406H21.9786V62.1322C21.9829 57.7838 23.7136 53.6149 26.7907 50.5413C29.8678 47.4677 34.0395 45.7408 38.3894 45.7399Z'
              stroke='#45413C'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M211 159.5V143.926C211 143.926 197.689 149.921 189.846 150.926C177.724 152.479 170.5 143.926 159.346 143.926C148.192 143.926 150.406 146.278 149.346 150.926C148.393 155.101 154 156.926 154 156.926L189.846 166.5L211 159.5Z'
              fill='#FFB59E'
              stroke='#45413C'
              strokeWidth='2'
            />
          </svg>
        </div>
        <div
          style={{
            height: 'fit-content',
          }}
          className={`${globals.flex} ${styles.texts} ${globals['flex-column']} ${globals['gap-24']}`}
        >
          <SectionHeadingMolecule
            large
            title='Expand Your Knowledge with our Mentorship Programs'
          />
          <p>
            Immerse yourself in a transformative learning experience as you expand your knowledge
            with our mentorship programs—unlocking a world of insights, personalized guidance, and
            the expertise of seasoned mentors dedicated to propelling your success journey.
          </p>
          <div
            style={{
              width: 'fit-content',
            }}
            className={`${globals.flex} ${globals['a-center']} ${globals['gap-8']}`}
          >
            <ButtonAtom
              buttonLook={ButtonLooks.OUTLINED}
              icon={<AiOutlineFileSearch fill='var(--purple-color)' className={styles.purple} />}
              iconDir={IconDirection.RIGHT}
              buttonType={ButtonTypes.PRIMARY}
              label='Find Mentor'
              onClick={() => {
                setOverlay(true)
              }}
              className={styles.btn__right}
            />
            <ButtonAtom
              buttonLook={ButtonLooks.SOLID}
              icon={<AiOutlineUser fill='var(--white-color)' className={styles.purple} />}
              iconDir={IconDirection.RIGHT}
              buttonType={ButtonTypes.PRIMARY}
              label='Become a Mentor'
              onClick={() => {
                setMentorForm(true)
              }}
              className={styles.btn__right}
            />
          </div>

          <div
            className={`${styles.overlay} ${globals['p-desktop']} ${overlay ? styles.active : ''}`}
          >
            <div
              ref={ref}
              className={`${globals.flex} ${styles.box} ${globals['flex-column']} ${globals['gap-8']}`}
            >
              <div className={styles.logo}>
                <IconRepository.LogoIcon width='150' />
              </div>
              <span>Please fill in the details below.</span>
              <p>#1 Career Accelerator Platform</p>
              <InputMolecule
                placeholder='Enter your Email Address'
                label='Email Address'
                type='text'
                name='email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              {mentorForm === true ? (
                <InputMolecule
                  placeholder='I am a Tech Ninja :-)'
                  label='Describe yourself in a very simple way possible'
                  type='text'
                  name='extra'
                  value={extra}
                  onChange={(e) => {
                    setExtra(e.target.value)
                  }}
                />
              ) : (
                ''
              )}
              <div
                className={`${styles.wrapper__suggests} ${globals.flex} ${globals['flex-column']} ${globals['gap-8']} ${globals['full-width']}`}
              >
                <SearchableFieldMolecule
                  placeholder='Search fields or cetification programs...'
                  value={instantValue}
                  name='instantValue'
                  label={
                    mentorForm === true ? 'Add areas of Expertise' : 'Mentor Domain (interests)'
                  }
                  onSelect={(name) => {
                    if (list.includes(name)) {
                      return
                    }

                    const newList = [...list]
                    newList.push(name)
                    setList(newList)
                    setInstantValue('')
                  }}
                  onChange={(e) => {
                    setInstantValue(e.target.value)
                  }}
                  list={[
                    'HTML5',
                    'CSS3',
                    'React.js',
                    'JavaScript',
                    'Next.js',
                    'Java',
                    'Flutter',
                    'AWS',
                    'Node.js',
                    'Nest.js',
                    'TypeScript',
                    'Springboot',
                  ]}
                />
                <div className={`${globals.flex} ${globals['gap-8']} ${styles.lists}`}>
                  {list.length > 0 &&
                    list.map((item, index) => {
                      return (
                        <div key={index} className={styles.item}>
                          <span>{item}</span>
                          <div
                            className={`${globals.flex} ${globals['center-items']}`}
                            role='button'
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const newList = [...list]
                              const filter = newList.filter((path) => path !== item)
                              setList(filter)
                            }}
                          >
                            <AiFillCloseCircle fill='var(--white-color)' size={24} />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
              <ButtonAtom
                buttonLook={ButtonLooks.SOLID}
                buttonType={ButtonTypes.PRIMARY}
                label='Submit Request'
                className={styles.btn__right}
                fontSize='1.6rem'
                onClick={() => {
                  setOverlay(true)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CertificationMentorTemplate
