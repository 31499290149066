import globals from '../../../../../../global.module.css'
import styles from './teamhero.module.css'

const TeamHeroTemplate = () => {
  return (
    <main
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals['flex-column']} ${globals.flex} ${globals['gap-24']} ${styles.hero}`}
    >
      <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
        <h1>Meet The Core Team</h1>
        <p>
          Our team: Extraordinary talents, expertise in their respective domain of excellence, and
          innovation converge to create a tapestry of excellence and shape the Boundless horizons of
          tomorrow.
        </p>
      </div>
    </main>
  )
}

export default TeamHeroTemplate
