import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from '../auth.module.css'

import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../components/atoms/button/enums'
import InputMolecule from '../../../../components/molecules/input/Input.molecule'
import AuthLayout from '../../../../layout/Auth.layout'
import UseForm from '../../../../lib/hooks/use-form'
import { forgotPasswordValidations } from '../../../../lib/utils/validations/forgot-password.validation'
import { emailValidation } from '../../../../lib/utils/validations/regex.validation'
import { IconRepository } from '../../../../repository/icons.repository'
import { LoginDataType } from '../../../../types/auth.type'

const AuthForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [trigger, setTrigger] = useState(false)

  const onSubmit = () => {
    console.log({ form })
  }

  const { form, updateForm, handleSubmit } = UseForm<Pick<LoginDataType, 'email'>>(
    {
      email: '',
    },
    trigger,
    setTrigger,
    onSubmit,
    forgotPasswordValidations,
  )

  const { email } = form

  return (
    <AuthLayout>
      <div
        className={`${styles.auth} ${globals['flex-column']} ${globals['p-desktop']} ${globals['center-items']} ${globals.flex} ${globals['full-width']}`}
      >
        <Link className={styles.logo} to='/'>
          <span>
            <IconRepository.LogoIcon width='180' />
          </span>
        </Link>
        <div className={`${styles.auth__container} ${globals.flex}`}>
          <div
            className={`${styles.banner}  ${globals.flex} ${globals['center-items']} ${globals['flex-column']} ${globals['gap-24']}`}
          >
            <span
              style={{
                fontSize: '10rem',
                marginBottom: '-20px',
                marginTop: '-40px',
              }}
            >
              🔐
            </span>
            <h2>Perhaps, you can also create an account.</h2>
            <Link
              className={`${globals.flex} ${globals['gap-12']} ${globals['a-center']}`}
              to='/auth/signup'
            >
              Sign Up <IconRepository.ArrowIcon fillColor='#fff' />
            </Link>
          </div>
          <div
            className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
          >
            <h2>Request Reset Password Link</h2>

            <div
              className={`${styles.form__wrapper} ${globals['a-center']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              <InputMolecule
                type='text'
                name='email'
                onChange={(e) => updateForm('email', e.target.value)}
                placeholder='chillytechhub@gmail.com'
                value={email}
                label='Enter your email address'
                iconLeft={<IconRepository.MailIcon size='22' />}
              />

              <div
                className={`${styles.link} ${globals.flex} ${globals['a-center']} ${globals['s-b']}`}
              >
                <div></div>
                <Link to='/auth/login'>Remember Password?</Link>
              </div>

              <ButtonAtom
                onClick={() => {
                  handleSubmit()

                  if (emailValidation.test(email)) navigate('/auth/create-new-password')
                }}
                buttonType={ButtonTypes.PRIMARY}
                label='Send Request'
                loading={false}
              />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default AuthForgotPasswordPage
