import { AxiosError } from 'axios'

import {
  ApiErrors,
  ApiRequestStatus,
  ApiResponse,
  ApiValidationException,
} from '../../types/api.type'
import { InternalServerError } from '../hooks/axios-instance.hook'

interface HasMessage {
  message: string
  [key: string]: unknown
}

export const hasMessageProp = (e: unknown): e is HasMessage =>
  typeof e === 'object' && e != null && 'message' in e

export const isAxiosError = <D, T = unknown>(e: unknown): e is AxiosError<D, T> =>
  typeof e === 'object' &&
  e != null &&
  'response' in e &&
  ['response', 'code', 'request', 'status'].some((t) => t in e)

export interface AsyncState<T, E = unknown> {
  payload?: T
  loading: boolean
  status?: ApiRequestStatus
  errors?: E
}

export const retrieveErrorResponse = (
  e: unknown,
):
  | {
      message: string
      code: number
    }
  | ApiErrors
  | undefined => {
  console.log({ e })

  if ((e as { code: string }).code === 'ERR_NETWORK') {
    return {
      message: 'Network Error. Please check your internet connection and try again.',
      code: -501,
    }
  }

  if (typeof e !== 'object' || !e) {
    return InternalServerError
  }

  if (
    isAxiosError<ApiResponse<unknown, ApiErrors>>(e) &&
    e?.response?.data?.errors !== undefined &&
    hasMessageProp(e?.response?.data.errors[0])
  ) {
    return {
      message: e?.response?.data.errors[0].message,
      code: 404,
    }
  }

  if (isAxiosError<ApiResponse<unknown, ApiErrors>>(e) && e?.response?.data?.errors !== undefined) {
    const typedEx = e?.response?.data.errors[0] as ApiValidationException
    const fields = typedEx.fields as { [key: string]: string[] }

    const messageExtract = fields[Object.keys(fields)[0] as keyof typeof fields]

    return {
      message: messageExtract[0],
      code: 404,
    }
  }

  if (isAxiosError(e) && hasMessageProp(e?.response?.data)) {
    return {
      message: e?.response?.data?.message as string,
      code: e?.response?.status as number,
    }
  }

  return InternalServerError
}
