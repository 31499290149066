import { useCallback, useRef, useState } from 'react'

import globals from '../../../../../../global.module.css'
import styles from './members.module.css'

import TeamCardMolecule from '../../../../../../components/molecules/team-card/TeamCard.molecule'
import UseOnClickOutside from '../../../../../../lib/hooks/detect-clickoutside.hook'
import { teamData } from '../../../../../../repository/team-data.repository'

export type TeamDataType = {
  image: string
  name: string
  skill: string
  linkedin: string
  github: string
  more: string[]
}

const TeamMembersTemplate = () => {
  const [selectedMember, setSelectedMember] = useState<TeamDataType>({} as TeamDataType)
  const [overlay, setOverlay] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    setSelectedMember({} as TeamDataType)
    setOverlay(false)
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  return (
    <section className={`${styles.members} ${globals['p-desktop']}`}>
      {teamData &&
        teamData.length > 0 &&
        teamData.map((data, index) => (
          <TeamCardMolecule
            key={index}
            image={data.image}
            name={data.name}
            skill={data.skill}
            linkedin={data.linkedin}
            github={data.github}
            onClick={() => {
              setSelectedMember(data)
              setOverlay(true)
            }}
          />
        ))}

      <div className={`${styles.overlay} ${globals['p-desktop']} ${overlay ? styles.active : ''}`}>
        <div ref={ref} className={`${globals.flex} ${globals['center-items']}`}>
          <TeamCardMolecule
            image={selectedMember?.image}
            name={selectedMember?.name}
            skill={selectedMember?.skill}
            linkedin={selectedMember?.linkedin}
            github={selectedMember?.github}
            more={selectedMember?.more}
            onClick={() => {
              return null
            }}
            overlay
            isAdmin
            fullWidth
          />
        </div>
      </div>
    </section>
  )
}

export default TeamMembersTemplate
