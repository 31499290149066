import { IconType } from '../../types/icons.type'

const LocationIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 13.43C12.4097 13.43 12.8154 13.3493 13.194 13.1925C13.5725 13.0357 13.9165 12.8059 14.2062 12.5162C14.4959 12.2265 14.7257 11.8825 14.8825 11.504C15.0393 11.1254 15.12 10.7197 15.12 10.31C15.12 9.90028 15.0393 9.49457 14.8825 9.11603C14.7257 8.73749 14.4959 8.39355 14.2062 8.10383C13.9165 7.81411 13.5725 7.58429 13.194 7.4275C12.8154 7.2707 12.4097 7.19 12 7.19C11.1725 7.19 10.3789 7.51872 9.79383 8.10383C9.20872 8.68894 8.88 9.48253 8.88 10.31C8.88 11.1375 9.20872 11.9311 9.79383 12.5162C10.3789 13.1013 11.1725 13.43 12 13.43Z'
        stroke={fillColor}
        strokeWidth='1.5'
      />
      <path
        d='M3.62001 8.49C5.59001 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C14.6321 21.4735 13.3398 21.9952 11.995 21.9952C10.6503 21.9952 9.35794 21.4735 8.39001 20.54C5.63001 17.88 2.47001 13.57 3.62001 8.49Z'
        stroke={fillColor}
        strokeWidth='1.5'
      />
    </svg>
  )
}

export default LocationIcon
