import { UpdatePersonalInfoType } from '../../../../services/api/profile.service'
import { Place } from '../../../../types'
import { Validations } from '../../../hooks/use-form'

export const updatePersonalInfoValidations: Validations<UpdatePersonalInfoType> = {
  gender: {
    custom: {
      isValid: (value: string) => value.length >= 4,
      message: 'Please select your gender.',
    },
  },
  address: {
    custom: {
      isValid: (value?: Partial<Place>) => {
        return (value?.country as string)?.length > 0
      },
      message: 'Your primary address is needed.',
    },
  },
  address2: {
    custom: {
      isValid: (value?: Partial<Place>) => (value?.country as string)?.length > 0,
      message: 'Your secondary address is needed.',
    },
  },
}
