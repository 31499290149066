import globals from '../../../../../global.module.css'
import viewsStyles from '../templates.module.css'
import styles from './index.module.css'

import BadgesStatusCardAtom from '../../../../../components/atoms/badges-status/BadgesStatusCard.atom'
import StatsCardAtom from '../../../../../components/atoms/stats-card/StatsCard.atom'
import TimerAndDateAtom from '../../../../../components/atoms/timer-and-date/TimerAndDate.atom'
import { IconRepository } from '../../../../../repository/icons.repository'

const DashboardIndexTemplate = () => {
  return (
    <div className={`${globals['full-width']} ${viewsStyles.container} ${styles.wrapper}`}>
      <div className={`${viewsStyles.head}`}>
        <h2>Your Dashboard Stats</h2>
      </div>

      <div
        className={`${globals['full-width']} ${globals.flex} ${globals['gap-24']} ${globals['s-b']} ${styles.badge__stat}`}
      >
        <div>
          <h2>
            There is more to the world than meets the eyes. We can achieve and do better together as
            a people.
          </h2>
        </div>
        <TimerAndDateAtom />
      </div>

      <div className={`${globals['full-width']} ${styles.badge__stat}`}>
        <BadgesStatusCardAtom />
      </div>

      <div
        className={`${globals['full-width']} ${globals.grid} ${styles.stats} ${globals['p-y-24']} ${globals['gap-24']}`}
      >
        <StatsCardAtom
          icon={<IconRepository.AddressIcon fillColor='var(--purple-color)' size='38' />}
          title={'Enrolled Certifications'}
          data={'0 Programs'}
        />
        <StatsCardAtom
          icon={<IconRepository.BadgeBagIcon fillColor='var(--purple-color)' size='38' />}
          title={'Badges Stats'}
          data={'0 Badges Earned'}
        />
        <StatsCardAtom
          icon={<IconRepository.NotificationIcon fillColor='var(--purple-color)' size='38' />}
          title={'Notifications'}
          data={'0 Notifications'}
        />
      </div>
    </div>
  )
}

export default DashboardIndexTemplate
