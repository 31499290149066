import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export enum NotificationTitleType {
  ERROR,
  SUCCESS,
  NONE,
}

export enum NotificationState {
  ACTIVE,
  INACTIVE,
}

type InitialStateType = {
  message: string
  title: NotificationTitleType
  state: NotificationState
}

export const initialState: InitialStateType = {
  title: NotificationTitleType.NONE,
  message: '',
  state: NotificationState.INACTIVE,
}

const phoneNumberSlice = createSlice({
  name: 'phoneNumberSlice',
  initialState: initialState,
  reducers: {
    resetNotificationState: (state) => {
      ;(state.message = ''), (state.title = NotificationTitleType.NONE)
      state.state = NotificationState.INACTIVE
    },
    showNotification: (
      state,
      action: PayloadAction<{ message: string; title: NotificationTitleType }>,
    ) => {
      ;(state.state = NotificationState.ACTIVE),
        (state.message = action.payload.message),
        (state.title = action.payload.title)
    },
  },
  extraReducers: (builder) => {
    builder
  },
})

export const { resetNotificationState, showNotification } = phoneNumberSlice.actions
export default phoneNumberSlice.reducer
