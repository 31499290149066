import { IconType } from '../../types/icons.type'

const NodeSvg = ({ width = '81', height = '80' }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 92 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41.7341 1.36398C44.2136 -0.0552934 47.4432 -0.0617042 49.9206 1.36398C62.3842 8.40656 74.8517 15.4381 87.3132 22.485C89.657 23.8052 91.2248 26.4137 91.2009 29.1144V71.4853C91.2184 74.2978 89.4957 76.9686 87.0229 78.2589C74.6003 85.2627 62.184 92.275 49.7635 99.2788C47.2327 100.726 43.9383 100.614 41.4865 99.0487C37.7622 96.8897 34.0315 94.7414 30.3069 92.5845C29.5458 92.1308 28.6878 91.7696 28.1504 91.0317C28.6255 90.3913 29.4749 90.3115 30.1651 90.032C31.7197 89.5376 33.1476 88.7441 34.5757 87.9741C34.9369 87.7269 35.3778 87.8217 35.724 88.0428C38.9087 89.8688 42.0653 91.7479 45.2607 93.5564C45.9424 93.95 46.6326 93.4275 47.2153 93.1027C59.4057 86.2129 71.6111 79.3491 83.7994 72.4572C84.251 72.24 84.5006 71.7606 84.4639 71.2659C84.4725 57.2886 84.4661 43.3089 84.4682 29.3316C84.5199 28.7703 84.195 28.2543 83.6875 28.0246C71.308 21.0529 58.9349 14.0705 46.5575 7.09698C46.343 6.94955 46.089 6.87045 45.8287 6.87007C45.5685 6.86969 45.3142 6.94804 45.0994 7.09484C32.722 14.0705 20.351 21.0593 7.97356 28.0306C7.46782 28.2607 7.12841 28.7682 7.1886 29.3316C7.19074 43.3089 7.1886 57.2886 7.1886 71.268C7.16714 71.5094 7.22015 71.7515 7.3405 71.9619C7.46085 72.1722 7.64276 72.3406 7.86173 72.4444C11.1647 74.3174 14.4719 76.1772 17.777 78.0438C19.639 79.046 21.9252 79.6415 23.9766 78.8737C25.7869 78.2244 27.0559 76.377 27.0214 74.4549C27.0385 60.5592 27.0128 46.6614 27.0342 32.7678C26.989 32.151 27.5741 31.6413 28.1739 31.6994C29.7609 31.6887 31.3501 31.678 32.9371 31.7036C33.5995 31.6887 34.0554 32.3529 33.9735 32.9744C33.9671 46.958 33.9906 60.942 33.9628 74.9257C33.9671 78.6525 32.436 82.7077 28.9888 84.5312C24.742 86.7311 19.493 86.2646 15.2975 84.1551C11.6654 82.3422 8.19936 80.2028 4.63177 78.2607C2.15259 76.9775 0.438421 74.296 0.455873 71.4856V29.1144C0.429874 26.3578 2.05999 23.7019 4.47685 22.4009C16.8973 15.3914 29.3157 8.37664 41.7341 1.36398Z'
        fill='#8CC84B'
      />
      <path
        d='M52.5697 30.7145C57.9868 30.3659 63.786 30.508 68.6607 33.1766C72.4348 35.2216 74.5272 39.5137 74.5938 43.7067C74.4884 44.2722 73.8972 44.5842 73.3573 44.5454C71.7856 44.5433 70.2135 44.5668 68.6418 44.5347C67.9751 44.5603 67.5876 43.9456 67.5039 43.3562C67.0523 41.35 65.9582 39.363 64.0699 38.3953C61.1711 36.944 57.8101 37.017 54.6492 37.0473C52.3417 37.1698 49.8604 37.3696 47.9055 38.7266C46.4046 39.7544 45.9488 41.8294 46.4844 43.5004C46.9895 44.7003 48.3745 45.0875 49.5078 45.4443C56.0361 47.1517 62.954 46.9818 69.3577 49.2288C72.0089 50.1448 74.6024 51.9256 75.5099 54.7015C76.6969 58.4218 76.1766 62.8688 73.5297 65.8555C71.3831 68.3133 68.2568 69.651 65.1387 70.3776C60.9906 71.3025 56.6857 71.326 52.4731 70.9154C48.512 70.4638 44.3899 69.4231 41.332 66.7241C38.7171 64.4537 37.4399 60.916 37.5667 57.5015C37.597 56.9249 38.1711 56.5228 38.7235 56.5702C40.3062 56.5574 41.889 56.5531 43.4717 56.5723C44.1039 56.5271 44.5726 57.0734 44.605 57.6693C44.8971 59.5808 45.6154 61.587 47.2822 62.7203C50.499 64.7956 54.5353 64.6535 58.2186 64.7115C61.2701 64.5762 64.6956 64.5352 67.1858 62.5183C68.4997 61.3679 68.889 59.4433 68.5339 57.7875C68.1492 56.3896 66.6869 55.7382 65.4311 55.3123C58.9865 53.2736 51.9913 54.0134 45.609 51.708C43.018 50.7923 40.5124 49.0611 39.517 46.3992C38.128 42.6318 38.7644 37.9719 41.6888 35.086C44.5402 32.2154 48.6562 31.1099 52.5697 30.7142V30.7145Z'
        fill='#8CC84B'
      />
    </svg>
  )
}

export default NodeSvg
