import globals from '../../../global.module.css'
import styles from './notification.module.css'

import {
  NotificationState,
  NotificationTitleType,
  resetNotificationState,
} from '../../../app/features/notification/notification.slice'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { IconRepository } from '../../../repository/icons.repository'

const NotificationMolecule = () => {
  const notificationState = useAppSelector((state) => state.notificationSlice)
  const dispatch = useAppDispatch()

  const clearNotification = () => {
    dispatch(resetNotificationState())
  }

  return (
    <div
      className={`${styles.notification} ${globals.flex} ${globals['gap-16']} ${
        notificationState.state === NotificationState.ACTIVE ? styles.active : ''
      } ${globals['full-width']}`}
      style={{
        borderLeft:
          notificationState.title === NotificationTitleType.ERROR
            ? '5px solid var(--red-color)'
            : '5px solid var(--green-color)',
      }}
    >
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          clearNotification()
        }}
        className={styles.close}
      >
        <IconRepository.CloseIcon
          fillColor={
            notificationState.title === NotificationTitleType.ERROR
              ? 'var(--red-color)'
              : 'var(--green-color)'
          }
        />
      </div>
      <div className={styles.icon}>
        {notificationState.title === NotificationTitleType.ERROR ? (
          <IconRepository.WarningIcon size='22' fillColor='var(--red-color)' />
        ) : (
          <IconRepository.CheckedIcon size='22' fillColor='var(--green-color)' />
        )}
      </div>
      <div>
        <h2
          style={{
            color:
              notificationState.title === NotificationTitleType.ERROR
                ? 'var(--red-color)'
                : 'var(--green-color)',
          }}
        >
          {notificationState.title === NotificationTitleType.ERROR ? 'Error' : 'Success'}
        </h2>
        <h3>{notificationState.message}</h3>
      </div>
    </div>
  )
}

export default NotificationMolecule
