import randomColor from 'randomcolor'
import { useMemo } from 'react'

import globals from '../../../global.module.css'
import styles from './statscard.module.css'

type StatsProps = {
  title: string
  data: string
  icon: JSX.Element
}

const StatsCardAtom: React.FC<StatsProps> = ({ title, data, icon }) => {
  const cardBgColor = useMemo(
    () =>
      randomColor({
        luminosity: 'light',
        format: 'rgba',
        alpha: 0.2,
      }),
    [],
  )

  return (
    <article
      style={{ background: cardBgColor }}
      className={`${styles.stats__card} ${globals.flex} ${globals['center-items']} ${globals['full-width']}`}
    >
      <div
        className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['center-items']}`}
      >
        <span>{icon}</span>
        <h2>{title}</h2>
        <h3>{data}</h3>
      </div>
    </article>
  )
}

export default StatsCardAtom
