import { IconType } from '../../types/icons.type'

const ReactSvg = ({ width = '69' }: IconType) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox='0 0 100 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M50.1045 59.4105C54.9736 59.4105 58.9208 55.4634 58.9208 50.5943C58.9208 45.7252 54.9736 41.7781 50.1045 41.7781C45.2355 41.7781 41.2883 45.7252 41.2883 50.5943C41.2883 55.4634 45.2355 59.4105 50.1045 59.4105Z'
        fill='#61DAFB'
      />
      <path
        d='M50.1045 68.6568C76.2312 68.6568 97.4111 60.5699 97.4111 50.5943C97.4111 40.6186 76.2312 32.5317 50.1045 32.5317C23.9778 32.5317 2.79788 40.6186 2.79788 50.5943C2.79788 60.5699 23.9778 68.6568 50.1045 68.6568Z'
        stroke='#61DAFB'
        strokeWidth='5'
      />
      <path
        d='M34.4618 59.6255C47.5252 82.2519 65.1186 96.5508 73.7578 91.563C82.3969 86.5751 78.8104 64.1894 65.7471 41.563C52.6837 18.9366 35.0903 4.63767 26.4511 9.6255C17.812 14.6133 21.3985 36.9991 34.4618 59.6255Z'
        stroke='#61DAFB'
        strokeWidth='5'
      />
      <path
        d='M34.4619 41.5629C21.3985 64.1894 17.812 86.5751 26.4512 91.5629C35.0903 96.5508 52.6837 82.2519 65.7471 59.6255C78.8104 36.9991 82.397 14.6133 73.7578 9.62547C65.1186 4.63764 47.5252 18.9365 34.4619 41.5629Z'
        stroke='#61DAFB'
        strokeWidth='5'
      />
    </svg>
  )
}

export default ReactSvg
