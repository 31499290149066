import { IconType } from '../../types/icons.type'

const JavaSvg = ({ width = '69', height = '80' }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 75 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.4661 77.8209C24.4661 77.8209 20.6448 80.043 27.1857 80.795C35.1101 81.6989 39.1598 81.5695 47.8925 79.9165C47.8925 79.9165 50.1885 81.3561 53.3945 82.6031C33.8187 90.9929 9.09005 82.117 24.4661 77.8209ZM22.074 66.8724C22.074 66.8724 17.788 70.0451 24.3338 70.7221C32.7987 71.5953 39.4835 71.6668 51.051 69.4394C51.051 69.4394 52.651 71.0614 55.1668 71.9485C31.4981 78.8696 5.13557 72.4943 22.074 66.8724Z'
        fill='#5382A1'
      />
      <path
        d='M42.2403 48.3005C47.0639 53.8538 40.973 58.8512 40.973 58.8512C40.973 58.8512 53.2206 52.5286 47.5958 44.611C42.3425 37.2278 38.3136 33.5588 60.1234 20.9102C60.1234 20.9102 25.8894 29.4601 42.2403 48.3002'
        fill='#E76F00'
      />
      <path
        d='M68.131 85.919C68.131 85.919 70.9589 88.2489 65.0165 90.0516C53.7171 93.4748 17.9867 94.5084 8.06081 90.188C4.49287 88.6361 11.184 86.4819 13.2887 86.0297C15.4836 85.5537 16.7382 85.6425 16.7382 85.6425C12.7701 82.8471 -8.90973 91.1313 5.726 93.5035C45.6393 99.9763 78.4839 90.589 68.131 85.919ZM26.3036 55.5287C26.3036 55.5287 8.12914 59.8457 19.8678 61.413C24.824 62.0766 34.7044 61.9266 43.9079 61.1553C51.4294 60.5212 58.982 59.172 58.982 59.172C58.982 59.172 56.3298 60.3078 54.4111 61.618C35.9549 66.472 0.300972 64.2139 10.5653 59.2487C19.2459 55.0527 26.3039 55.5287 26.3039 55.5287H26.3036ZM58.907 73.7527C77.6689 64.0035 68.9941 54.6346 62.9394 55.897C61.4552 56.2057 60.7936 56.4732 60.7936 56.4732C60.7936 56.4732 61.3446 55.6104 62.3968 55.2369C74.3748 51.0256 83.587 67.6571 58.53 74.2441C58.53 74.2444 58.8204 73.9849 58.907 73.7527Z'
        fill='#5382A1'
      />
      <path
        d='M47.5958 0.485397C47.5958 0.485397 57.9864 10.8797 37.7409 26.8624C21.5063 39.6836 34.0389 46.9938 37.7342 55.346C28.2577 46.796 21.3031 39.2692 25.9689 32.2642C32.8167 21.9808 51.788 16.9953 47.5958 0.485107'
        fill='#E76F00'
      />
      <path
        d='M28.1474 100.171C46.1562 101.324 73.8107 99.5311 74.4656 91.0099C74.4656 91.0099 73.2067 94.2405 59.5823 96.8058C44.2114 99.6982 25.2538 99.3606 14.0096 97.5067C14.0099 97.5067 16.3115 99.4119 28.1477 100.171'
        fill='#5382A1'
      />
    </svg>
  )
}

export default JavaSvg
