import { IconType } from '../../types/icons.type'

const WarningIcon = ({ size = '24', fillColor = 'var(--purple-color)' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 7.75V13M21.08 8.58V15.42C21.08 16.54 20.48 17.58 19.51 18.15L13.57 21.58C12.6 22.14 11.4 22.14 10.42 21.58L4.48001 18.15C4.00178 17.8727 3.60494 17.4745 3.32936 16.9953C3.05377 16.5161 2.90915 15.9728 2.91001 15.42V8.58C2.91001 7.46 3.51001 6.42 4.48001 5.85L10.42 2.42C11.39 1.86 12.59 1.86 13.57 2.42L19.51 5.85C20.48 6.42 21.08 7.45 21.08 8.58Z'
        stroke={fillColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16.2V16.3'
        stroke={fillColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default WarningIcon
