import axios from 'axios'

import { backend } from '../../lib/hooks/axios-instance.hook'
import { ApiResponse } from '../../types/api.type'
import { LoginDataType, RegisterUserDataType, VerifyEmailType } from '../../types/auth.type'
import { User, UserResponse } from '../../types/user.type'
import { apiRoutes } from './routes'
import { SanctumService } from './sanctum.service'

const sanctumService = new SanctumService()

export async function loginUser(data: LoginDataType) {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return await backend.post<{
      data: {
        user: User
        token: string
      }
      success: string
    }>(apiRoutes.auth.login, data)
  })
}

export async function verifyUserEmail(payload: VerifyEmailType) {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return backend.post<{ data: { message: string } }>(apiRoutes.auth.verifyEmail, payload)
  })
}

export async function logoutUser() {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return axios.post<ApiResponse<UserResponse>>(`${process.env.REACT_APP_BARE_SERVER_URL}/logout`)
  })
}

export async function registerWithGoogle(credential: string) {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return backend.post<ApiResponse<UserResponse>>(apiRoutes.auth.googleRegister, { credential })
  })
}

export async function loginWithGoogle(credential: string) {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return backend.post<ApiResponse<UserResponse>>(apiRoutes.auth.googleLogin, { credential })
  })
}

export async function registerUser(payload: RegisterUserDataType) {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return backend.post<ApiResponse<UserResponse>>(apiRoutes.auth.register, payload)
  })
}

export async function getUserInfo() {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return backend.get<ApiResponse<UserResponse>>(apiRoutes.user.base)
  })
}
