export enum IconDirection {
  RIGHT,
  LEFT,
}

export enum ButtonTypes {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export enum ButtonLooks {
  OUTLINED,
  SOLID,
}
