import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './dashboard.module.css'

import { ImageAssets } from '../../../app/assets'
import {
  NotificationTitleType,
  showNotification,
} from '../../../app/features/notification/notification.slice'
import { logoutThunk } from '../../../app/features/user'
import { RootState } from '../../../app/store/store'
import { appConstants } from '../../../configs/constants.config'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import UseOnClickOutside from '../../../lib/hooks/detect-clickoutside.hook'
import UseWindowSize from '../../../lib/hooks/get-window-size.hook'
import { IconRepository } from '../../../repository/icons.repository'
import { ApiRequestStatus } from '../../../types/api.type'
import { GenderTypes } from '../../../types/profile-setup.type'
import SidebarOrganism from '../components/organisms/sidebar/Sidebar.organism'

const DashboardPage = () => {
  const { width } = UseWindowSize()
  const [sideBarActive, setSideBarActive] = useState(width > 830 ? true : false)
  const [profileDropActive, setProfileDropActive] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userInfo = useAppSelector((state) => state.user.payload)
  const authState = useAppSelector((state: RootState) => state.loginSlice)
  const logoutState = useAppSelector((state) => state.logoutUserSlice)

  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    setProfileDropActive(() => {
      return false
    })
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  useEffect(() => {
    if (
      authState.requestResponse.data === null &&
      authState.requestResponse?.data?.user === undefined
    ) {
      navigate(appConstants.routes.auth.login)
    }
  }, [authState])

  useEffect(() => {
    if (width > 830) {
      setSideBarActive(true)
    } else {
      setSideBarActive(false)
    }
  }, [width])

  useEffect(() => {
    if (logoutState.requestResponse.status === ApiRequestStatus.REJECTED) {
      dispatch(
        showNotification({
          message: 'An error occured logging you out. Please try again.',
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [logoutState])

  return (
    <div className={styles.dashboard__page}>
      <div
        className={`${globals['full-width']} ${!sideBarActive ? styles.close : ''} ${
          styles.sidebar
        }`}
      >
        <SidebarOrganism setSideBarActive={setSideBarActive} />
      </div>
      <div>
        <nav
          className={`${styles.navbar} ${globals.flex} ${globals['gap-24']} ${globals['a-center']} ${globals['s-b']} ${globals['full-width']}`}
          role='navigation'
          aria-label='secondary'
        >
          <div
            onClick={() => {
              setSideBarActive(!sideBarActive)
            }}
            title={`${sideBarActive ? 'Close side panel' : 'Open side panel'}`}
            className={`${styles.toggler} ${globals.flex} ${globals['a-center']} ${globals['gap-16']}`}
          >
            <IconRepository.BurgerIcon fillColor='var(--dark-color)' size='26' />
            {width > 500 ? (
              <h4>Welcome 👋, {userInfo?.profile && <span>{userInfo.profile.firstName}</span>}</h4>
            ) : (
              ''
            )}
          </div>
          <div
            className={`${styles.items} ${globals.flex} ${globals['a-center']} ${globals['gap-16']}`}
          >
            <div>
              <Link to='/'>
                <IconRepository.SkillBadgeIcon size='26' fillColor='var(--dark-color)' />
              </Link>
            </div>
            <div>
              <Link to='/'>
                <IconRepository.NotificationIcon size='25' fillColor='var(--dark-color)' />
              </Link>
            </div>
            <div ref={ref} className={styles.more}>
              <div
                onClick={() => {
                  setProfileDropActive(!profileDropActive)
                }}
                className={`${styles.profile} ${globals.flex} ${globals['center-items']}`}
              >
                {userInfo?.profile?.picture ? (
                  <img
                    src={userInfo.profile.picture}
                    width={100}
                    height={100}
                    alt={userInfo.profile.firstName}
                  />
                ) : (
                  <img
                    src={
                      userInfo?.profile?.gender === GenderTypes.MALE
                        ? ImageAssets.MaleAvatar
                        : ImageAssets.FemaleAvatar
                    }
                    width={100}
                    height={100}
                    alt={'avatar'}
                  />
                )}
              </div>
              {profileDropActive ? (
                <div
                  onClick={async () => {
                    await dispatch(logoutThunk())
                  }}
                  className={styles.logout}
                >
                  <button
                    disabled={logoutState.requestResponse.status === ApiRequestStatus.PENDING}
                  >
                    {logoutState.requestResponse.status === ApiRequestStatus.PENDING
                      ? 'Please wait...'
                      : 'Logout'}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </nav>
        <div
          className={`${styles.content} ${!sideBarActive ? styles.full : ''} ${
            globals['p-desktop']
          }`}
        >
          <div className={`${globals['full-width']} ${styles.content__data}`}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
