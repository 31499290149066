import { Link } from 'react-router-dom'

import globals from '../../../../../global.module.css'
import viewsStyles from '../templates.module.css'
import styles from './trainings.module.css'

import SectionHeadingMolecule from '../../../../../components/molecules/section-heading/SectionHeading.molecule'
import { certifications } from '../../../../../repository/certifications.repository'

const DashboardTrainingsTemplate = () => {
  return (
    <div className={`${globals['full-width']} ${viewsStyles.container}`}>
      <SectionHeadingMolecule title='Explore and Get Certified in Over 10+ Languages and Frameworks' />

      <div className={`${globals['full-width']} ${styles.list}`}>
        {certifications &&
          certifications.length > 0 &&
          certifications.map((certification, index) => {
            return (
              <Link key={index} to={certification.link}>
                <div>
                  <div className={styles.holder}>
                    <certification.image />
                  </div>
                  <h3>{certification.name}</h3>
                  <p>{certification.certificationsAvailable}+ Certifications</p>
                </div>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default DashboardTrainingsTemplate
