import {
  ExtractedPhoneNumberType,
  PhoneNumberType,
} from '../../app/features/phone-number/thunks/phone-number.thunk'

export const extractPhoneFields = (data: PhoneNumberType[]): ExtractedPhoneNumberType[] => {
  const extractedData = data.map((country: PhoneNumberType) => {
    return {
      name: country.name,
      alpha3Code: country.alpha3Code,
      callingCode: country.callingCodes[0],
      flag: country.flags.png,
    }
  })

  return extractedData
}
