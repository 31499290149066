/* eslint-disable */

export const teamData = [
  {
    image: '/images/team/nouks.webp',
    name: "N'nouka A'Issah Stephen",
    skill: 'Senior Software Engineer',
    linkedin: 'https://www.linkedin.com/in/n-nouka-stephen-issah/',
    github: 'https://github.com/Nnouka',
    more: [
      'Stephen studied physics and computer science in his home country, Cameroon. Being bilingual, he was able to work for companies both in Cameroon and the United States before moving to Paris. He has <b>6 years</b> of relevant and <b>verifiable work experience</b>. Stephen is very interested in learning and advancing his career in web development, he has passed and obtained various certifications in <b>React.JS</b>, <b>Java</b> and <b>AWS</b>. He is also interested in <b>cloud devops with GCP</b>.',
      "Stephen is currently a student of computer science at the university of london, with focus on mobile web development. He wants to model developer career success through <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a> and allow other young <b>talents</b> to certify their success and work history, by earning industry <b>trusted certifications</b> and working with <b>clear professional objectives</b>.",
    ],
  },
  {
    image: '/images/team/emma.webp',
    name: 'Nchia Emmanuela Ekei',
    skill: 'Full Stack Engineer',
    linkedin: 'https://www.linkedin.com/in/nchia-emmanuela-854199230/',
    github: 'https://github.com/Nchia-Emmanuela',
    more: [
      'Nchia Emmanuela, a full-stack developer with <b>3 years of experience</b> working for both local and remote companies, stands before you with a mission close to her heart - <b>the empowerment of women in the field of technology.</b>',
      'Recently, we have witnessed remarkable progress in bridging the gender gap in various industries. However, the tech industry still lags behind. It is disheartening to see the <b>underrepresentation of women</b> in this field, as their <b>unique perspectives</b> is essential for <b>driving innovation </b> and <b>shaping the future</b>.',
      "Through <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a>, she actively <b>encourages young ladies</b> to explore careers relating to technology and engineering. By providing them with the necessary resources, mentorship, and role models, she strongly believes it will <b>ignite their passion</b> and <b>confidence</b> in pursuing tech careers.",
    ],
  },
  {
    image: '/images/team/prince.webp',
    name: 'Njoh Noh Prince Junior',
    skill: 'Full Stack Engineer',
    linkedin: 'https://linkedin.com/in/njoh-noh-prince-junior-b93347201/',
    github: 'https://github.com/NjohPrince',
    more: [
      'In the ever-evolving world of technology, passionate programmers play a crucial role in shaping the future. Prince Junior, a full-stack developer with <b>4 years of experience</b> and a lover of JavaScript/TypeScript tech stacks, stands out for his commitment to writing <b>robust</b>, <b>scalable</b>, and <b>maintainable</b> code. However, his impact extends beyond his coding prowess. He possesses a <b>deep desire</b> to <b>mentor and uplift</b> younger generations, helping them upskill in their respective domains or fields of interest.',
      "Through <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a>, he inspires and motivates aspiring talents to certify their successes, work experience, and also earn badges of excellence. These badges are often awarded for exceptional performance or contributions, serving as a visual representation of their achievements.",
    ],
  },
  {
    image: '/images/team/nobert.webp',
    name: 'Nobert Ndip Etta',
    skill: 'Backend Web Engineer',
    linkedin: 'http://www.linkedin.com/in/nobert-etta',
    github: 'https://github.com/Suprememajor',
    more: [
      "Nobert studied mathematics and physics at an advanced level. Later, he pursued and obtained a bachelor's degree in Electrical and Electronic Engineering, specializing in Telecommunications, from the University of Buea. As a <b>tech enthusiast</b>, he dedicated time to self-study and building a career in <b>web development</b> by taking courses outside of the university environment. He has accumulated over <b>2 years of experience</b> working as a <b>professional web developer</b> for companies both in Cameroon and Germany.",
      "Nobert is <b>enthusiastic</b> and <b>passionate</b> about using technology to provide solutions to everyday problems. Consequently, he is <b>committed</b> to continuously improving his skills and staying updated with relevant technologies. With <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a> he is excited about <b>mentoring</b> and <b>collaborating</b> with young talents to help them launch their careers in the tech industry.",
    ],
  },
]
