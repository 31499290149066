import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true

export const InternalServerError = {
  message: 'Internal error occured during the request.',
  code: -500,
}

export const onFulfilledRequest = (response: AxiosResponse) => response

// eslint-disable-next-line
export const onRejectedRequest = (error: any) => Promise.reject(InternalServerError)

export const publicApiRequest = (url?: string) => {
  return axios.create({
    baseURL: url !== undefined ? url : `${process.env.REACT_APP_SERVER_URL}`,
  })
}

publicApiRequest().interceptors.response.use(onFulfilledRequest, onRejectedRequest)

export const backend = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

export const backendFormData = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

export const publicEndpoint = axios.create({
  baseURL: process.env.REACT_APP_BARE_SERVER_URL,
})

backend.interceptors.request.use((config: AxiosRequestConfig) => ({
  ...config,
  headers: {
    ...(config?.headers ?? {}),
    Accept: 'application/json',
  } as unknown as AxiosRequestHeaders,
}))
