import FemaleAvatar from './avatar_female.webp'
import MaleAvatar from './avatar_male.webp'
import Google from './google.webp'
import HeroPic from './hero.webp'
import ProfileSetup from './profilesetup.webp'

export const ImageAssets = {
  Google,
  FemaleAvatar,
  MaleAvatar,
  ProfileSetup,
  HeroPic,
}
