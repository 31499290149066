import { AiOutlineArrowRight, AiOutlineSafetyCertificate } from 'react-icons/ai'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

import globals from '../../../../../../global.module.css'
import styles from './hero.module.css'

import { ImageAssets } from '../../../../../../app/assets'
import ButtonAtom from '../../../../../../components/atoms/button/Button.atom'
import {
  ButtonLooks,
  ButtonTypes,
  IconDirection,
} from '../../../../../../components/atoms/button/enums'
import { appConstants } from '../../../../../../configs/constants.config'
import { scrollToId } from '../../../../../../lib/utils/dom-helpers'

const CertificationHeroTemplate = () => {
  return (
    <main
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals.flex} ${globals['gap-24']} ${styles.hero}`}
    >
      <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
        <h1>Build your skills, build your future.</h1>
        <p>
          Welcome to a transformative experience, where you unlock new career opportunities by
          certifying your work experience with our seamless and trusted verification process.
        </p>
        <div
          className={`${globals.flex} ${styles.button__group} ${globals['a-center']} ${globals['gap-16']}`}
        >
          <div>
            <Link
              onClick={() => {
                scrollToId('#certifications')
              }}
              style={{
                textDecoration: 'none',
              }}
              to={'/#certifications'}
            >
              <ButtonAtom
                buttonLook={ButtonLooks.OUTLINED}
                icon={<AiOutlineSafetyCertificate className={styles.purple} />}
                iconDir={IconDirection.RIGHT}
                buttonType={ButtonTypes.PRIMARY}
                label='Explore Certifications'
                className={styles.btn__right}
              />
            </Link>
          </div>
          <div>
            <Link
              style={{
                textDecoration: 'none',
              }}
              to={appConstants.routes.auth.signup}
            >
              <ButtonAtom
                buttonType={ButtonTypes.PRIMARY}
                icon={<AiOutlineArrowRight className={styles.white} />}
                iconDir={IconDirection.RIGHT}
                label='Get Certified'
                className={styles.btn__left}
              />
            </Link>
          </div>
        </div>
      </div>

      <div className={`${styles.illustration}`}>
        <LazyLoadImage
          effect='blur'
          src={ImageAssets.HeroPic}
          alt='illustration'
          width={400}
          height={500}
        />
      </div>
    </main>
  )
}

export default CertificationHeroTemplate
