import { backend } from '../../lib/hooks/axios-instance.hook'
import { ApiResponse } from '../../types/api.type'
import { RequiredTypeRef } from '../../types/profile-setup.type'
import { UserResponse } from '../../types/user.type'
import { apiRoutes } from './routes'
import { SanctumService } from './sanctum.service'

const sanctumService = new SanctumService()

export type UpdatePersonalInfoType = Pick<
  RequiredTypeRef,
  'phoneNumber' | 'address' | 'address2' | 'gender'
>

export type UpdateAboutInfoType = Pick<
  RequiredTypeRef,
  'about' | 'jobTitle' | 'companyLocation' | 'company'
>

export const updateProfilePicture = async (media: File) => {
  const data = new FormData()
  data.append('picture', media)

  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    return await backend.post<ApiResponse<UserResponse>>(apiRoutes.user.profile.picture, data)
  })
}

export const updatePersonalInformation = async (data: UpdatePersonalInfoType) => {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    const body = {
      phoneNumber: data.phoneNumber,
      primaryPlace: data.address,
      secondaryPlace: data.address2,
      gender: data.gender,
    }
    return await backend.post<ApiResponse<UserResponse>>(apiRoutes.user.profile.personal, body)
  })
}

export const updateAboutInformation = async (data: UpdateAboutInfoType) => {
  return await sanctumService.laravelSanctumCRSFTokenHandler().then(async () => {
    const body = {
      about: data.about,
      jobTitle: data.jobTitle,
      companyName: data.company,
      companyPlace: data.companyLocation,
    }
    return await backend.post<ApiResponse<UserResponse>>(apiRoutes.user.profile.about, body)
  })
}
