import { IconType } from '../../types/icons.type'

const CssSvg = ({ width = '69', height = '80' }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 89 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M88.1919 0L80.159 89.9895L44.0505 100L8.04148 90.0036L0.0170288 0H88.1919Z'
        fill='#264DE4'
      />
      <path d='M73.2819 84.2593L80.1466 7.35913H44.1045V92.3484L73.2819 84.2593Z' fill='#2965F1' />
      <path d='M18.4129 40.7402L19.4023 51.7787H44.1045V40.7402H18.4129Z' fill='#EBEBEB' />
      <path d='M44.1045 18.3977H44.0663H16.425L17.4284 29.4364H44.1045V18.3977Z' fill='#EBEBEB' />
      <path
        d='M44.1045 80.8931V69.4083L44.0561 69.4212L31.7624 66.1016L30.9765 57.2979H25.0027H19.8955L21.442 74.63L44.0537 80.9072L44.1045 80.8931Z'
        fill='#EBEBEB'
      />
      <path
        d='M57.6591 51.7785L56.3778 66.0947L44.0663 69.4177V80.9019L66.696 74.6301L66.8621 72.7652L69.456 43.7042L69.7253 40.7401L71.7181 18.3977H44.0663V29.4364H59.6208L58.6164 40.7401H44.0663V51.7785H57.6591Z'
        fill='white'
      />
    </svg>
  )
}

export default CssSvg
