import globals from '../../../../../global.module.css'
import viewsStyles from '../templates.module.css'

const DashboardSettingsTemplate = () => {
  return (
    <div className={`${globals['full-width']} ${viewsStyles.container}`}>
      <div className={`${viewsStyles.head}`}>
        <h2>Settings</h2>
      </div>
    </div>
  )
}

export default DashboardSettingsTemplate
