import { useEffect, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './timedate.module.css'

const TimerAndDateAtom = ({ reducedVersion }: { reducedVersion?: boolean }) => {
  const [dateState, setDateState] = useState(new Date())
  const [amPM, setAmPM] = useState('')

  function formatAMPM(date: Date) {
    const hours = date.getHours()
    const ampm = hours >= 12 ? 'pm' : 'am'
    const strTime = ampm
    return strTime
  }

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date())
      setAmPM(formatAMPM(new Date()))
    }, 1000)
  }, [])

  useEffect(() => {
    setAmPM(formatAMPM(new Date()))
  }, [])

  return (
    <div
      className={`${styles.timer__date} ${reducedVersion ? styles.reduced : ''} ${
        globals['center-items']
      } ${globals.flex} ${globals['flex-column']}`}
    >
      <div className={`${styles.head}`}>
        <h2>
          {dateState.toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </h2>
      </div>

      <div className={`${styles.time} ${globals.flex}`}>
        <div>
          <h2>{dateState.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 })}</h2>
        </div>
        <h2
          style={{
            marginTop: '-2px',
          }}
        >
          :
        </h2>
        <div>
          <h2>{dateState.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 })}</h2>
        </div>
        {reducedVersion ? (
          <>
            <h2
              style={{
                marginTop: '-2px',
              }}
            >
              :
            </h2>
            <div>
              <h2>
                {dateState.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 })}
                <span>{amPM.toUpperCase()}</span>
              </h2>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default TimerAndDateAtom
