import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'

/**
 *
 * @param value
 * @param code
 * @returns
 */
export const validatePhoneNumber = (value: string, code: CountryCode) => {
  if (isValidPhoneNumber(value.trim(), code)) {
    return true
  } else {
    return false
  }
}
