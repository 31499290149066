import { useNavigate } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './badgesstatus.module.css'

import { appConstants } from '../../../configs/constants.config'
import ButtonAtom from '../button/Button.atom'
import { ButtonTypes } from '../button/enums'

const BadgesStatusCardAtom = () => {
  const navigate = useNavigate()

  return (
    <article
      className={`${styles.badges__status} ${globals.flex} ${globals['gap-24']} ${globals['s-b']} ${globals['full-width']}`}
    >
      <div
        className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']} ${globals['s-b']}`}
      >
        <div className={`${styles.main}`}>
          <h2>Total Available Badges</h2>
          <h3>300</h3>
        </div>
        <div>
          <h4 className={styles.ref}>Earn more professional badges with CertiUp</h4>
          <div>
            <ButtonAtom
              onClick={() => {
                ;(async () => {
                  navigate(appConstants.routes.dashboardModules.trainings)
                })()
              }}
              label={'Start Now'}
              buttonType={ButtonTypes.PRIMARY}
            />
          </div>
        </div>
      </div>
      <div className={styles.badge}></div>
    </article>
  )
}

export default BadgesStatusCardAtom
