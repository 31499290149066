import { Place } from '.'

export enum GenderTypes {
  MALE = 'Male',
  FEMALE = 'Female',
}

export type ProfileSetupTypes = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  gender: GenderTypes | ''
  address: Partial<Place> | undefined
  address2: Partial<Place> | undefined
  country: string
  about?: string
  jobTitle?: string
  company?: string
  companyLocation: Partial<Place> | undefined
  workExperience: WorkExperienceType[]
  educationalBackground: EducationalBackgroundType[]
}

export type RequiredTypeRef = Required<ProfileSetupTypes>

export type DateType = {
  year: string
  month: string
}

export type WorkExperienceType = {
  company: string
  jobTitle: string
  roleDescription: string
  employmentType: string
  skillsAndTools: string[]
  location: string
  startDate: DateType
  endDate?: DateType
  currentlyActive: boolean
}

export type EducationalBackgroundType = {
  school: string
  qualification: string
  location: string
  fieldOfStudy: string
  startDate: DateType
  endDate: DateType
  currentlyActive: boolean
  extraNotes: string
}
