import { LoginDataType } from '../../../types/auth.type'
import { Validations } from '../../hooks/use-form'
import { emailValidation } from './regex.validation'

export const loginValidations: Validations<LoginDataType> = {
  email: {
    pattern: {
      value: emailValidation,
      message: 'Invalid email address.',
    },
  },
  password: {
    custom: {
      isValid: (value: string) => value.length >= 8,
      message: 'Please check the validity of your credentials.',
    },
  },
}
