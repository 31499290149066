import { AiOutlineArrowRight } from 'react-icons/ai'

import globals from '../../../../../global.module.css'
import styles from '../landing/certification/hero.module.css'

import ButtonAtom from '../../../../../components/atoms/button/Button.atom'
import { ButtonTypes, IconDirection } from '../../../../../components/atoms/button/enums'

import { IconRepository } from '../../../../../repository/icons.repository'

const ContributeHeroTemplate = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+237650999316'
    const defaultMessage = 'Hey There! I would like to contribute to the community'
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${defaultMessage}`
    window.open(whatsappUrl, '_blank')
  }

  return (
    <main
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals.flex} ${globals['gap-24']} ${styles.hero}`}
    >
      <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
        <h1
          style={{
            maxWidth: '600px',
          }}
        >
          Contribute to the community
        </h1>
        <p>
          Welcome to Certiup! 🌟 We invite you to be a part of our thriving community by
          contributing your unique talents and ideas. Click the "Contribute" button below to start
          making a difference. Your input is invaluable, and together, we can build something
          incredible. Join us on this exciting journey!
        </p>
        <div
          className={`${globals.flex} ${styles.button__group} ${globals['a-center']} ${globals['gap-16']}`}
        >
          <div>
            <ButtonAtom
              buttonType={ButtonTypes.PRIMARY}
              icon={<AiOutlineArrowRight className={styles.white} />}
              iconDir={IconDirection.RIGHT}
              label='Contribute Now'
              className={styles.btn__left}
              onClick={handleWhatsAppClick}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: '-30px',
        }}
        className={`${styles.community}`}
      >
        <IconRepository.CommunityIcon size='450' fillColor='var(--purple-color)' />
      </div>
    </main>
  )
}

export default ContributeHeroTemplate
