import { useEffect, useState } from 'react'

import { verifyEmailThunk } from '../../app/features/auth/thunks/auth.thunk'
import {
  NotificationTitleType,
  showNotification,
} from '../../app/features/notification/notification.slice'
import { RootState } from '../../app/store/store'
import { ApiRequestStatus, StoredErrorResponseType } from '../../types/api.type'
import { VerifyEmailType } from '../../types/auth.type'
import { useAppDispatch, useAppSelector } from '../hooks'

const VerifyEmailRepository = (data: VerifyEmailType) => {
  const [verifyEmailError, setVerifyEmailError] = useState<StoredErrorResponseType>()

  const dispatch = useAppDispatch()
  const { requestResponse } = useAppSelector((state: RootState) => state.verifyEmailSlice)

  const launchRequest = async () => {
    setVerifyEmailError(undefined)
    await dispatch(verifyEmailThunk(data))
  }

  useEffect(() => {
    if (requestResponse.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        showNotification({
          message: 'Your account has successfully been verified.',
          title: NotificationTitleType.SUCCESS,
        }),
      )
    }

    if (requestResponse.status === ApiRequestStatus.REJECTED) {
      setVerifyEmailError(requestResponse.error)

      dispatch(
        showNotification({
          message: requestResponse.error?.message as string,
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [requestResponse])

  return {
    launchRequest,
    verifyEmailError,
    success: requestResponse.status === ApiRequestStatus.FULFILLED,
    loading: requestResponse.status === ApiRequestStatus.PENDING,
  }
}

export default VerifyEmailRepository
