import styles from './loadingstate.module.css'

type Props = {
  loaderColor?: string
  size?: string
}

const ComponentLoadingAtom: React.FC<Props> = ({ loaderColor, size }) => {
  return (
    <div
      style={{
        height: size,
      }}
      className={styles.loader}
    >
      {Array(4)
        .fill(0)
        .map((_v, index) => {
          return (
            <div
              style={{
                background: loaderColor,
              }}
              key={index}
            ></div>
          )
        })}
    </div>
  )
}

export default ComponentLoadingAtom
