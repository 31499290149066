import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from '../auth.module.css'

import { ImageAssets } from '../../../../app/assets'
import { updateLoginStateFromGoogleAuth } from '../../../../app/features/auth/slices/login.slice'
import {
  NotificationTitleType,
  showNotification,
} from '../../../../app/features/notification/notification.slice'
import { userActions } from '../../../../app/features/user'
import { RootState } from '../../../../app/store/store'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { ButtonLooks, ButtonTypes } from '../../../../components/atoms/button/enums'
import InputMolecule from '../../../../components/molecules/input/Input.molecule'
import { appConstants } from '../../../../configs/constants.config'
import AuthLayout from '../../../../layout/Auth.layout'
import LoginRepository from '../../../../lib/api/login.repository'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import UseForm from '../../../../lib/hooks/use-form'
import { googleAuthScriptId, googleBtnId, initGoogle } from '../../../../lib/utils/google'
import { loginValidations } from '../../../../lib/utils/validations/login.validation'
import { IconRepository } from '../../../../repository/icons.repository'
import { ApiRequestStatus } from '../../../../types/api.type'
import { LoginDataType } from '../../../../types/auth.type'
import { UserResponse } from '../../../../types/user.type'

const AuthLoginPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userStateSelector = useAppSelector((state: RootState) => state.user)

  const scripts = useAppSelector((state) => state.scripts)

  const onSubmit = async () => {
    await launchRequest()
  }

  useEffect(() => {
    if (scripts[googleAuthScriptId]) {
      initGoogle({
        config: { width: 530 },
        onGoogleAuth: (auth) => {
          dispatch(userActions.loginWithGoogle(auth.credential))
            .unwrap()
            .then(() => {
              dispatch(userActions.resetProfileManagementState())
              navigate(appConstants.routes.dashboard)
            })
            .catch((e) => {
              console.log(e)

              dispatch(
                showNotification({
                  message: 'An error occured signing you in, Please try again...',
                  title: NotificationTitleType.ERROR,
                }),
              )
            })
        },
        googleBtnId: googleBtnId,
      })
    }
  }, [scripts])

  const { form, updateForm, handleSubmit } = UseForm<LoginDataType>(
    {
      email: '',
      password: '',
    },
    trigger,
    setTrigger,
    onSubmit,
    loginValidations,
  )

  const { email, password } = form

  const { launchRequest, loading } = LoginRepository(form)

  useEffect(() => {
    if (userStateSelector.status === ApiRequestStatus.FULFILLED) {
      dispatch(
        showNotification({
          message: 'Login success, Welcome back!',
          title: NotificationTitleType.SUCCESS,
        }),
      )
      dispatch(updateLoginStateFromGoogleAuth({ data: userStateSelector.payload as UserResponse }))
    }
  }, [userStateSelector])

  return (
    <AuthLayout>
      <div
        className={`${styles.auth} ${globals['flex-column']} ${globals['p-desktop']} ${globals['center-items']} ${globals.flex} ${globals['full-width']}`}
      >
        <Link className={styles.logo} to='/'>
          <span>
            <IconRepository.LogoIcon width='180' />
          </span>
        </Link>
        <div className={`${styles.auth__container} ${globals.flex}`}>
          <div
            className={`${styles.banner}  ${globals.flex} ${globals['center-items']} ${globals['flex-column']} ${globals['gap-24']}`}
          >
            <span
              style={{
                fontSize: '10rem',
                marginBottom: '-20px',
                marginTop: '-40px',
              }}
            >
              🎉
            </span>
            <h2>You can also create an account today!</h2>
            <Link
              className={`${globals.flex} ${globals['gap-12']} ${globals['a-center']}`}
              to='/auth/signup'
            >
              Sign Up <IconRepository.ArrowIcon fillColor='#fff' />
            </Link>
          </div>
          <div
            className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
          >
            <h2>Welcome Back</h2>

            <div className={styles.third__party}>
              <button id={googleBtnId} className={styles.google} />
              <img width={50} height={50} src={ImageAssets.Google} alt='google sign in' />
              Sign in with google
            </div>

            <div
              className={`${styles.lines} ${globals['full-width']} ${globals.flex} ${globals['gap-16']} ${globals['center-items']}`}
            >
              <div></div>
              <h4>OR</h4>
              <div></div>
            </div>

            <div
              className={`${styles.form__wrapper} ${globals['a-center']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              <InputMolecule
                type='text'
                name='email'
                onChange={(e) => updateForm('email', e.target.value)}
                placeholder='chillytechhub@gmail.com'
                value={email}
                label={'Email Address'}
                iconLeft={<IconRepository.MailIcon size='22' />}
              />
              <InputMolecule
                label={'Password'}
                type={showPassword === true ? 'text' : 'password'}
                name='password'
                onChange={(e) => updateForm('password', e.target.value)}
                placeholder='********'
                value={password}
                iconLeft={<IconRepository.LockIcon size='22' />}
                iconRight={<IconRepository.EyeClosedIcon size='22' />}
                switchableIcon={<IconRepository.EyeDefaultIcon size='22' />}
                switchable={showPassword === true}
                extraAction={() => {
                  setShowPassword(!showPassword)
                }}
              />

              <div
                className={`${styles.link} ${globals.flex} ${globals['a-center']} ${globals['s-b']}`}
              >
                <div></div>
                <Link to='/auth/forgot-password'>Forgot Password ?</Link>
              </div>

              <ButtonAtom
                onClick={() => {
                  handleSubmit()
                }}
                buttonType={ButtonTypes.PRIMARY}
                buttonLook={ButtonLooks.SOLID}
                label={'Login'}
                loading={loading || userStateSelector.loading}
              />

              <div className={styles.ref__link}>
                <h4>
                  You can also create an account today! ? <Link to='/auth/signup'>Sign Up</Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default AuthLoginPage
