import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from '../auth.module.css'
import classes from './emailverification.module.css'

import {
  NotificationTitleType,
  showNotification,
} from '../../../../app/features/notification/notification.slice'
import ComponentLoadingAtom from '../../../../components/atoms/component-loading-state/ComponentLoading.atom'
import { appConstants } from '../../../../configs/constants.config'
import AuthLayout from '../../../../layout/Auth.layout'
import VerifyEmailRepository from '../../../../lib/api/verify-email.repository'
import { useAppDispatch } from '../../../../lib/hooks'

const AuthEmailVerificationPage = () => {
  const [params] = useSearchParams()
  const [token, setToken] = useState<string | null>('')
  const [email, setEmail] = useState<string | null>('')
  const dispatch = useAppDispatch()

  const { launchRequest, loading, verifyEmailError, success } = VerifyEmailRepository({
    email: email as string,
    token: token as string,
  })

  const submitForVerification = async () => {
    await launchRequest()
  }

  const validateUrlSearchParams = (email: string | null, token: string | null) => {
    if (
      token === null ||
      email === null ||
      token === undefined ||
      email === undefined ||
      email.length === 0 ||
      token.length === 0
    ) {
      return false
    }

    return true
  }

  useEffect(() => {
    setEmail(params.get('email'))
    setToken(params.get('token'))
  }, [window.location])

  useEffect(() => {
    if (token === null || email === null || token === undefined || email === undefined) {
      dispatch(
        showNotification({
          message: 'Something is not right with your verification link. Please request a new one.',
          title: NotificationTitleType.ERROR,
        }),
      )
    }

    if (validateUrlSearchParams(email, token) === true) {
      submitForVerification()
    }
  }, [email, token])

  return (
    <AuthLayout>
      <div
        className={`${styles.auth} ${classes.auth__ref} ${globals['flex-column']} ${globals['p-desktop']} ${globals.flex} ${globals['full-width']}`}
      >
        <h2>Certiup email verification</h2>

        {verifyEmailError !== undefined && verifyEmailError.message.length > 0 ? (
          <h3>
            Opps, An error occured. Please if the problem persists request a new verification link.
          </h3>
        ) : success === true ? (
          <h3>Yay!, Successful verification.</h3>
        ) : (
          ''
        )}

        {loading ? <ComponentLoadingAtom loaderColor='var(--purple-color)' /> : ''}
        {success === true ? (
          <div>
            <Link to={appConstants.routes.auth.login}>Please Login</Link>
          </div>
        ) : (
          ''
        )}
      </div>
    </AuthLayout>
  )
}

export default AuthEmailVerificationPage
