import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import globals from '../../../../global.module.css'
import styles from '../auth.module.css'

import { ImageAssets } from '../../../../app/assets'
import { updateLoginStateFromGoogleAuth } from '../../../../app/features/auth/slices/login.slice'
import {
  NotificationTitleType,
  showNotification,
} from '../../../../app/features/notification/notification.slice'
import { userActions } from '../../../../app/features/user'
import { RootState } from '../../../../app/store/store'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../components/atoms/button/enums'
import InputMolecule from '../../../../components/molecules/input/Input.molecule'
import { appConstants } from '../../../../configs/constants.config'
import AuthLayout from '../../../../layout/Auth.layout'
import RegisterRepository from '../../../../lib/api/register.repository'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import UseForm from '../../../../lib/hooks/use-form'
import { googleAuthScriptId, googleBtnId, initGoogle } from '../../../../lib/utils/google'
import {
  min8DigitRegex,
  oneNumericCharacterRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../../../../lib/utils/validations/regex.validation'
import { registerValidations } from '../../../../lib/utils/validations/register.validation'
import { IconRepository } from '../../../../repository/icons.repository'
import { ApiRequestStatus } from '../../../../types/api.type'
import { RegisterDataType } from '../../../../types/auth.type'
import { UserResponse } from '../../../../types/user.type'

const AuthRegisterPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const effectRef = useRef(true)

  const userStateSelector = useAppSelector((state: RootState) => state.user)
  const scripts = useAppSelector((state) => state.scripts)

  const onSubmit = async () => {
    await launchRequest()
  }

  useEffect(() => {
    if (scripts[googleAuthScriptId]) {
      initGoogle({
        config: { width: 530 },
        onGoogleAuth: (auth) => {
          dispatch(userActions.registerWithGoogle(auth.credential))
            .unwrap()
            .then(() => {
              dispatch(
                showNotification({
                  message: 'Welcome and we are so happy to have you onboard.',
                  title: NotificationTitleType.SUCCESS,
                }),
              )
              dispatch(userActions.resetProfileManagementState())
              navigate(appConstants.routes.dashboard)
            })
            .catch((e) => {
              console.log(e)

              dispatch(
                showNotification({
                  message: 'An error occured signing you up, Please try again...',
                  title: NotificationTitleType.ERROR,
                }),
              )
            })
        },
        googleBtnId: googleBtnId,
      })
    }

    effectRef.current = false
  }, [scripts])

  const { form, updateForm, handleSubmit } = UseForm<RegisterDataType>(
    {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    trigger,
    setTrigger,
    onSubmit,
    registerValidations,
  )

  const { firstName, lastName, email, password, confirmPassword } = form

  const { launchRequest, loading } = RegisterRepository({
    ...form,
    password_confirmation: form.confirmPassword,
    accountStatus: 0,
  })

  useEffect(() => {
    if (userStateSelector.status === ApiRequestStatus.FULFILLED) {
      dispatch(updateLoginStateFromGoogleAuth({ data: userStateSelector.payload as UserResponse }))
    }
  }, [userStateSelector])

  return (
    <AuthLayout>
      <div
        className={`${styles.auth} ${globals['flex-column']} ${globals['p-desktop']} ${globals['center-items']} ${globals.flex} ${globals['full-width']}`}
      >
        <Link className={styles.logo} to='/'>
          <span>
            <IconRepository.LogoIcon width='180' />
          </span>
        </Link>
        <div className={`${styles.auth__container} ${globals.flex}`}>
          <div
            className={`${styles.banner}  ${globals.flex} ${globals['flex-column']} ${globals['gap-24']}`}
            style={{
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '10rem',
                marginBottom: '-20px',
                marginTop: '30%',
              }}
            >
              🏗️
            </span>
            <h2>Already a member of CertiUp</h2>
            <Link
              className={`${globals.flex} ${globals['gap-12']} ${globals['a-center']}`}
              to='/auth/login'
            >
              Login
              <IconRepository.ArrowIcon fillColor='#fff' />
            </Link>
          </div>
          <div
            className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
          >
            <h2> Create An Account</h2>

            <div className={styles.third__party}>
              <button id={googleBtnId} className={styles.google} />
              <img width={50} height={50} src={ImageAssets.Google} alt='google sign up' />
              Sign up with google
            </div>

            <div
              className={`${styles.lines} ${globals['full-width']} ${globals.flex} ${globals['gap-16']} ${globals['center-items']}`}
            >
              <div></div>
              <h4>OR</h4>
              <div></div>
            </div>

            <div
              className={`${styles.form__wrapper} ${globals['a-center']} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              <InputMolecule
                type='text'
                name='firstName'
                onChange={(e) => updateForm('firstName', e.target.value)}
                placeholder='John Doe'
                value={firstName}
                label={'First Name'}
                iconLeft={<IconRepository.UserIcon size='22' />}
              />
              <InputMolecule
                type='text'
                name='lastName'
                onChange={(e) => updateForm('lastName', e.target.value)}
                placeholder='Williams'
                value={lastName}
                label={'Last Name'}
                iconLeft={<IconRepository.UserIcon size='22' />}
              />
              <InputMolecule
                type='text'
                name='email'
                onChange={(e) => updateForm('email', e.target.value)}
                placeholder='chillytechhub@gmail.com'
                value={email}
                label={'Email Address'}
                iconLeft={<IconRepository.MailIcon size='22' />}
              />
              <InputMolecule
                label={'Password'}
                type={showPassword === true ? 'text' : 'password'}
                name='password'
                onChange={(e) => updateForm('password', e.target.value)}
                placeholder='********'
                value={password}
                iconLeft={<IconRepository.LockIcon size='22' />}
                iconRight={<IconRepository.EyeClosedIcon size='22' />}
                switchableIcon={<IconRepository.EyeDefaultIcon size='22' />}
                switchable={showPassword === true}
                extraAction={() => {
                  setShowPassword(!showPassword)
                }}
              />
              <ul className={`${styles.validation__errors} ${globals['full-width']}`}>
                {!specialCharacterRegex.test(password) ? <li>One special character</li> : ''}
                {!min8DigitRegex.test(password) ? <li>Min 8 characters</li> : ''}
                {!oneNumericCharacterRegex.test(password) ? (
                  <li>One number (2 are recommended)</li>
                ) : (
                  ''
                )}
                {!upperCaseRegex.test(password) ? <li>{'One capital letter'}</li> : ''}
              </ul>
              <InputMolecule
                label={'Confirm Password'}
                type={showConfirmPassword === true ? 'text' : 'password'}
                name='confirmPassword'
                onChange={(e) => updateForm('confirmPassword', e.target.value)}
                placeholder='********'
                value={confirmPassword}
                iconLeft={<IconRepository.LockIcon size='22' />}
                iconRight={<IconRepository.EyeClosedIcon size='22' />}
                switchableIcon={<IconRepository.EyeDefaultIcon size='22' />}
                switchable={showConfirmPassword === true}
                extraAction={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}
              />

              <ButtonAtom
                onClick={() => {
                  handleSubmit()
                }}
                loading={loading || userStateSelector.loading}
                buttonType={ButtonTypes.PRIMARY}
                label={'Create Account'}
              />

              <div className={styles.ref__link}>
                <h4>
                  Already a member of CertiUp
                  <Link to='/auth/login'>Login</Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default AuthRegisterPage
