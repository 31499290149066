import { useCallback, useRef, useState } from 'react'

import globals from '../../../global.module.css'
import styles from './select.module.css'

import UseOnClickOutside from '../../../lib/hooks/detect-clickoutside.hook'
import { IconRepository } from '../../../repository/icons.repository'

type SelectProps = {
  onSelect: (data: string, index?: number) => void
  list: string[]
  selected: string
  height?: string
}

const SelectMolecule = ({ onSelect, height, list, selected }: SelectProps) => {
  const [dropActive, setDropActive] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    setDropActive(() => {
      return false
    })
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  return (
    <div className={`${styles.select__wrapper} ${globals['full-width']}`}>
      <div
        onClick={() => {
          setDropActive(!dropActive)
        }}
        className={styles.select}
        ref={ref}
        id='select'
      >
        <h4>{selected}</h4>
        <IconRepository.CaretDownIcon />
        <div
          style={{
            display: dropActive ? 'flex' : 'none',
            top: document.getElementById('select')?.scrollHeight,
            height: height,
            width: '100%',
          }}
          className={styles.options}
        >
          {list.map((data, index) => {
            return (
              <h4
                onClick={(e) => {
                  e.stopPropagation()
                  onSelect(data, index)
                  setDropActive(false)
                }}
                key={index}
              >
                {data.length === 0 ? list[0] : data}
              </h4>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SelectMolecule
