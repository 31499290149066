import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import globals from '../../../global.module.css'
import styles from './navbar.module.css'

import { ImageAssets } from '../../../app/assets'
import { RootState } from '../../../app/store/store'
import { appConstants } from '../../../configs/constants.config'
import { useAppSelector } from '../../../lib/hooks'
import { UseScrollPosition } from '../../../lib/hooks/get-scroll-position.hook'
import UseWindowSize from '../../../lib/hooks/get-window-size.hook'
import { IconRepository } from '../../../repository/icons.repository'
import { GenderTypes } from '../../../types/profile-setup.type'
import ButtonAtom from '../../atoms/button/Button.atom'
import { ButtonLooks, ButtonTypes } from '../../atoms/button/enums'

const NavbarOrganism = () => {
  const location = useLocation()
  const position = UseScrollPosition()
  const [mobileActive, setMobileActive] = useState(false)
  const { width } = UseWindowSize()

  const userInfo = useAppSelector((state) => state.user.payload)
  const authState = useAppSelector((state: RootState) => state.loginSlice)

  // const scrollToSection = (sectionId: string) => {
  //   setMobileActive(false)
  //   try {
  //     const element = document.querySelector(sectionId)
  //     if (element !== null) {
  //       const y = element.getBoundingClientRect().top + window.scrollY
  //       window.scroll({
  //         top: y - 90,
  //         behavior: 'smooth',
  //       })
  //     }
  //   } catch (e) {
  //     console.log('error scrolling document: ', e)
  //   }
  // }

  return (
    <nav
      role='navigation'
      aria-label='main'
      className={`${styles.navbar} ${
        position > 70 || location.pathname.includes('/app/courses') ? styles.shadow : ''
      } ${globals['p-desktop']} ${globals['a-center']} ${globals.flex} ${globals['s-b']}`}
    >
      <div
        onClick={() => {
          setMobileActive(!mobileActive)
        }}
        className={styles.burger}
      >
        <IconRepository.BurgerIcon size='32' fillColor='var(--purple-color)' />
      </div>
      <div className={`${globals.flex} ${globals['a-center']} ${globals['gap-48']}`}>
        <Link to={'/'}>
          <span className={styles.logo}>
            <IconRepository.LogoIcon width='180' />
          </span>
        </Link>
        <ul
          className={`${styles.menu} ${mobileActive ? styles.show : ''} ${globals.flex} ${
            globals['a-center']
          } ${globals['gap-24']}`}
        >
          <li>
            <Link
              className={`${location.pathname === '/trending-event' ? styles.active : ''}`}
              to={'/trending-event'}
            >
              Trending Event
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname === '/the-team' ? styles.active : ''}`}
              to={'/the-team'}
            >
              The Team
            </Link>
          </li>
          <li>
            <Link className={''} to={'/'}>
              Our Community
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname === '/contribute' ? styles.active : ''}`}
              to={'/contribute'}
            >
              Contribute
            </Link>
          </li>
        </ul>
      </div>

      {authState.requestResponse.data !== null &&
      authState.requestResponse?.data?.user !== undefined ? (
        <Link to={appConstants.routes.dashboard}>
          <div
            style={{
              width: '55px',
              height: '55px',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '3px solid var(--purple-color)',
            }}
          >
            {userInfo?.profile?.picture ? (
              <img
                src={userInfo.profile.picture}
                width='100%'
                height='100%'
                alt={userInfo.profile.firstName}
              />
            ) : (
              <img
                src={
                  userInfo?.profile?.gender === GenderTypes.MALE
                    ? ImageAssets.MaleAvatar
                    : ImageAssets.FemaleAvatar
                }
                width='100%'
                height='100%'
                alt={'avatar'}
              />
            )}
          </div>
        </Link>
      ) : (
        <div
          className={`${styles.button__group} ${globals.flex} ${globals['center-items']} ${globals['gap-24']}`}
        >
          <Link className={styles.hide} to='/auth/login'>
            Login
          </Link>
          <Link to='/auth/signup'>
            <ButtonAtom
              label={'Sign Up'}
              buttonType={ButtonTypes.PRIMARY}
              padding={width < 800 ? '10px 20px' : '0 20px'}
              fontSize={width < 800 ? '1.6rem' : '1.8rem'}
              buttonLook={ButtonLooks.OUTLINED}
            />
          </Link>
        </div>
      )}
    </nav>
  )
}

export default NavbarOrganism
