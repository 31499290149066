import { IconType } from '../../types/icons.type'

const JavascriptSvg = ({ width = '69' }: IconType) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox='0 0 101 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M100.552 0.594238H0.552246V100.594H100.552V0.594238Z' fill='#F7DF1E' />
      <path
        d='M67.7268 78.7197C69.7411 82.0086 72.3618 84.426 76.9967 84.426C80.8903 84.426 83.3776 82.48 83.3776 79.7911C83.3776 76.5689 80.8221 75.4276 76.5364 73.553L74.1871 72.5451C67.4062 69.6562 62.9014 66.0371 62.9014 58.3863C62.9014 51.3387 68.2713 45.9736 76.6633 45.9736C82.6379 45.9736 86.9332 48.053 90.0284 53.4974L82.711 58.1959C81.0998 55.307 79.3618 54.1689 76.6633 54.1689C73.911 54.1689 72.1665 55.9149 72.1665 58.1959C72.1665 61.0149 73.9125 62.1562 77.9443 63.9022L80.2935 64.9086C88.2776 68.3324 92.7856 71.8228 92.7856 79.6705C92.7856 88.1308 86.1395 92.7657 77.2141 92.7657C68.4871 92.7657 62.8491 88.607 60.0903 83.1562L67.7268 78.7197ZM34.5316 79.534C36.0078 82.153 37.3506 84.3673 40.5792 84.3673C43.6665 84.3673 45.6141 83.1593 45.6141 78.4625V46.5101H55.011V78.5895C55.011 88.3197 49.3062 92.7482 40.9792 92.7482C33.4554 92.7482 29.0983 88.8546 26.8824 84.1649L34.5316 79.534Z'
        fill='black'
      />
    </svg>
  )
}

export default JavascriptSvg
