import { createSlice } from '@reduxjs/toolkit'

import {
  getStoredPhoneCodes,
  storePhoneCodes,
} from '../../../../services/storage/local-storage.service'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api.type'
import { PhoneNumberType, getPhoneNumbersThunk } from '../thunks/phone-number.thunk'

export type PhoneNumberState = {
  requestResponse: ApiRequestDataType<PhoneNumberType[]>
}

export const initialState: PhoneNumberState = {
  requestResponse: {
    status:
      getStoredPhoneCodes() !== null && getStoredPhoneCodes() !== undefined
        ? ApiRequestStatus.FULFILLED
        : ApiRequestStatus.IDLE,
    data: getStoredPhoneCodes(),
  },
}

const phoneNumberSlice = createSlice({
  name: 'phoneNumberSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPhoneNumbersThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
      })
      .addCase(getPhoneNumbersThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload

        storePhoneCodes(action.payload)
      })
      .addCase(getPhoneNumbersThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as unknown as StoredErrorResponseType
      })
  },
})

export default phoneNumberSlice.reducer
