export type GoogleAuthCallback = (auth: { credential: string; [key: string]: unknown }) => void

export interface PlaceConfig {
  onLoad: () => void
  onError?: (e: unknown) => void
}

declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (props: { client_id: string; callback: GoogleAuthCallback }) => void
          renderButton: (el: HTMLElement | null, config: unknown) => void
          prompt: () => void
        }
      }
    }
    noopFunc: () => void
  }
}

export type GoogleConfig = {
  config?: { width: number }
  onGoogleAuth: GoogleAuthCallback
  googleBtnId: string
}

export const initGoogle = ({ config, onGoogleAuth, googleBtnId }: GoogleConfig) => {
  window?.google.accounts.id.initialize({
    client_id: String(process.env.REACT_APP_GOOGLE_CLIENT_ID),
    callback: onGoogleAuth,
  })
  window?.google.accounts.id.renderButton(document.getElementById(googleBtnId), {
    theme: 'outline',
    logo_alignment: 'left',
    size: 'large',
    shape: 'rectangular',
    width: config?.width ?? 240,
    type: 'standard',
  })
  window?.google.accounts.id.prompt()
}
window.noopFunc = () => null
export const placesScriptSrc = `${String(process.env.REACT_APP_GOOGLE_PLACES_URL)}?key=${String(
  process.env.REACT_APP_GOOGLE_PLACES_KEY,
)}&libraries=places&callback=noopFunc`

export const palcesScriptId = 'google-places-script-21-02-23'

export const googleAuthScriptId = 'google-gsi-btn-script-5221'

export const googleAuthScriptSrc = String(process.env.REACT_APP_GOOGLE_LIB_URL)

export const googleBtnId = 'google_btn_id_098'
