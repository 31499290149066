import { IconType } from '../../types/icons.type'

const CommunityIcon = ({ size = '600', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      height={size}
      width={size}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 502.648 502.648'
    >
      <g>
        <g>
          <g>
            <circle fill={fillColor} cx='250.399' cy='91.549' r='58.694' />
            <path
              fill={fillColor}
              d='M455.861,253.028l-54.703-11.411c-18.637-3.904-37.037,4.638-46.765,19.824
				c-9.448-4.853-19.608-9.038-30.415-12.511v-32.529c0.022-24.612-20.126-44.738-44.651-44.738h-55.933
				c-24.655,0-44.716,20.126-44.716,44.738v32.701c-10.699,3.408-20.751,7.593-30.264,12.468
				c-9.728-15.251-28.15-23.857-46.809-19.953l-54.747,11.411c-24.03,5.026-39.626,28.862-34.6,52.978l13.741,65.64
				c4.983,24.051,28.84,39.647,52.892,34.621l17.321-3.624c8.671,12.813,20.665,24.569,36.023,34.621
				c31.989,20.967,74.247,32.529,119.092,32.529c68.617,0,127.721-27.589,154.943-67.215l17.602,3.689
				c24.03,5.004,47.887-10.57,52.87-34.621l13.762-65.64C495.508,281.89,479.912,258.054,455.861,253.028z M251.305,447.381
				c-40.51,0-78.475-10.203-106.797-28.862c-9.707-6.342-17.753-13.395-24.202-20.945l13.266-2.783
				c24.073-5.004,39.669-28.84,34.643-52.913l-12.317-59.018c7.183-3.861,14.733-7.248,22.757-10.138v10.764
				c0,24.569,20.104,44.695,44.716,44.695h55.933c24.548,0,44.652-20.147,44.652-44.695v-11.325
				c8.175,2.912,15.854,6.256,22.973,10.052L334.439,341.9c-4.983,24.073,10.591,47.909,34.664,52.913l13.395,2.804
				C357.52,427.191,308.101,447.381,251.305,447.381z'
            />
            <circle fill={fillColor} cx='443.954' cy='168.708' r='58.694' />
            <path
              fill={fillColor}
              d='M70.736,226.172c31.752-6.644,52.029-37.77,45.471-69.501
				c-6.687-31.709-37.749-52.072-69.523-45.428c-31.709,6.622-52.072,37.727-45.428,69.458
				C7.879,212.453,38.984,232.795,70.736,226.172z'
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </svg>
  )
}

export default CommunityIcon
