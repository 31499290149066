import globals from '../../../../../../global.module.css'
import styles from './quote.module.css'

import QuoteCardMolecule from '../../../../../../components/molecules/quote-card/QuoteCard.molecule'

const CertificationQuoteTemplate = () => {
  return (
    <section
      className={`${styles.quote} ${globals.flex} ${globals['center-items']} ${globals['full-width']} ${globals['p-desktop']}`}
    >
      <QuoteCardMolecule />
    </section>
  )
}

export default CertificationQuoteTemplate
