import { IconType } from '../../types/icons.type'

const SpringSvg = ({ width = '69' }: IconType) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox='0 0 100 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M91.0588 6.23187C89.6221 9.7246 87.7643 12.821 85.7083 15.5954C76.642 6.35572 63.9592 0.484955 49.9635 0.484955C22.4675 0.484955 0 22.8286 0 50.4236C0 64.8405 6.16802 77.8206 15.9526 86.9859L17.8105 88.6456C26.4556 95.9531 37.7017 100.387 49.9387 100.387C76.1961 100.387 97.8214 79.9013 99.6792 54.0402C101.165 41.4813 97.3507 25.4048 91.0588 6.23187ZM23.1858 87.407C21.7491 89.2649 19.0738 89.463 17.216 88.0263C15.3581 86.5896 15.16 83.9143 16.5967 82.0564C18.0334 80.1986 20.7087 80.0004 22.5665 81.4372C24.3253 82.8739 24.6225 85.5492 23.1858 87.407ZM90.7616 72.47C78.4999 88.8437 52.1433 83.295 35.3485 84.1124C35.3485 84.1124 32.3512 84.3106 29.3786 84.7317C29.3786 84.7317 30.5181 84.2115 31.9548 83.6913C43.7954 79.5793 49.369 78.7371 56.5774 75.0462C70.0777 68.135 83.5532 52.9999 86.2532 37.3445C81.1008 52.3806 65.4454 65.3607 51.2268 70.6122C41.4422 74.2287 23.8299 77.7215 23.8299 77.7215L23.1115 77.3004C11.1718 71.4296 10.7507 45.4694 32.5989 37.1215C42.1853 33.4058 51.2516 35.4619 61.6555 33.0095C72.6786 30.4333 85.4606 22.1845 90.5882 11.3843C96.3103 28.7984 103.321 55.7742 90.7616 72.47Z'
        fill='#6CB52D'
      />
      <path
        d='M23.1858 87.407C21.7491 89.2648 19.0738 89.463 17.216 88.0263C15.3581 86.5896 15.16 83.9143 16.5967 82.0564C18.0334 80.1986 20.7087 80.0004 22.5666 81.4372C24.3253 82.8739 24.6226 85.5492 23.1858 87.407Z'
        fill='#6CB52D'
      />
    </svg>
  )
}

export default SpringSvg
