import { PhoneNumberType } from '../../app/features/phone-number/thunks/phone-number.thunk'
import { publicApiRequest } from '../../lib/hooks/axios-instance.hook'

export class PhoneNumberService {
  public async getAllPhoneNumbers() {
    const response = await publicApiRequest('https://restcountries.com/v2').get<PhoneNumberType[]>(
      '/all',
    )
    return response
  }
}
